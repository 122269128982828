import React from "react";
import Modal from "../components/modal";
import { useState } from "react";
import Login from "../auth/login";
import config from "../config";
import { Link } from "react-router-dom";
export default function LoginPage() {
	const [loginModal, setLoginModal] = useState(true);
	return (
		<div className="bg-blue-600 w-full min-h-screen flex flex-col items-center justify-center">
			<Modal condition={loginModal} setCondition={setLoginModal} openButtonValue="LOGIN" openButtonClasses="font-bold text-blue-600 dark:text-blue-400" boxClasses="px-2 w-full md:w-10/12 lg:w-6/12 xl:w-4/12 md:mx-auto mt-12 lg:mt-36 mb-16">
				<Login reload={() => window.location.assign(config.base_url)} />
			</Modal>
			<img className="w-10/12 max-w-xs" src={config.base_url + "/images/HermesWhite.svg"} alt="" />
			<Link to={"/"} className="text-white/70 transition-colors hover:text-white font-normal mt-4 text-xl italic">
				Terug naar home
			</Link>
		</div>
	);
}
