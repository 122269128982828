// @ts-ignore
import PaneelNavigatie from "../../../components/Paneel/paneelNavigatie";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { _ } from "../../../modules/functions";
import React from "react";
import { Helmet } from "react-helmet";

type StudentType = {
    naam: string;
    nummer?: string;
};

export default function Paneel_Cursusverkoop() {
    const [studenten, setStudenten] = useState([{ naam: "Rein Vanbelleghem", nummer: "02002921" }, { naam: "Bram Van Den Eeckhout" }, { naam: "Robrecht Vandersteen" }]);
    const [gefilterdeStudenten, setGefilterdeStudenten] = useState<StudentType[]>();
    const [naamFilter, setNaamFilter] = useState("");

    useEffect(() => {
        let filtered: StudentType[] = studenten;
        filtered = filtered.filter((student) => _.STRING_CONTAINS(student.naam, naamFilter));
        // filters op toepassen

        setGefilterdeStudenten(filtered);
    }, [studenten, naamFilter]);

    return (
        <>
            <PaneelNavigatie />
            <Helmet>
                <title>Paneel - Shop - Hermes Gent</title>
            </Helmet>
            <div className="lg:w-8/12 w-11/12 pt-20 pb-40 mx-auto">
                <div className="flex">
                    <h1 className="font-bold text-4xl uppercase text-zinc-800  dark:text-zinc-300 flex-grow">Cursusverkoop</h1>

                    <Link to="/paneel/shop/beheer" className="flex group items-center px-4 py-2 font-semibold uppercase rounded-md border-2 border-blue-700 text-blue-700 dark:border-blue-300 dark:text-blue-300 gap-2 w-fit">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 transition-transform group-hover:rotate-180" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                        </svg>
                        Beheer
                    </Link>
                </div>

                <div className="text-zinc-800 dark:text-zinc-300 mt-8 shadow p-4 lg:p-8 rounded-lg dark:bg-black/70">
                    <label htmlFor="naam" className="text-lg font-light">
                        Naam van student
                    </label>
                    <input value={naamFilter} onChange={(e) => setNaamFilter(e.target.value)} type="text" id="naam" placeholder="Naam van student" className="mt-2 mb-4 w-full border border-zinc-300 focus:outline-none focus:ring-1 focus:ring-blue-600 bg-transparent dark:border-zinc-700 px-3 py-2 dark:text-zinc-400 rounded-md" />

                    <div>
                        {gefilterdeStudenten?.map((student) => {
                            return (
                                <Link to="/paneel/shop/bestellingen/1" className="mt-2 shadow py-4 px-4 lg:px-6 rounded-md dark:bg-zinc-900 flex gap-4 items-center">
                                    <div className="w-7 h-7 rounded-md bg-blue-200 text-blue-700 font-bold dark:bg-blue-600 dark:text-zinc-200 flex items-center justify-center">{student.naam.toUpperCase().slice(0, 1)}</div>
                                    <div>{student.naam}</div>
                                    <div className="italic text-zinc-400 dark:text-zinc-500">{student.nummer ? `(${student.nummer})` : ""}</div>
                                </Link>
                            );
                        })}
                    </div>
                </div>
            </div>
        </>
    );
}
