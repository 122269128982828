import Navigation from "../../components/navigation";

import { useEffect, useState } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import config from "../../config";
import axios from "axios";
import { Helmet } from "react-helmet";
import { PRAESIDIUM_FUNCTIES } from "../../components/Choices";
function Praesidium() {
    useEffect(() => {
        Aos.init();
    });

    const [praesidium, setPraesidium] = useState([]);
    useEffect(() => {
        axios.get(config.api_url + "/praesidia/?active=true").then((response) => {
            let data = response.data.data[0].praesidium;
            setPraesidium(data);
        });
    }, []);

    return (
        <div className="dark:bg-zinc-900">
            <Navigation />
            <Helmet>
                <title>Praesidium - Hermes Gent</title>
                <meta name="description" content="Hermes Gent Cursusverkoop" />
            </Helmet>
            <div className="lg:w-8/12 w-11/12 pt-20 pb-40 mx-auto flex flex-col items-center md:grid md:grid-cols-2 xl:grid-cols-3 gap-20">
                {praesidium.map((lid, index) => {
                    return (
                        <>
                            {lid.visible && (
                                <div key={index} data-aos="fade-up" data-aos-duration="1000" data-aos-delay={(index % 3) * 150} className="flex flex-col max-w-md">
                                    <img className="w-full h-96 object-cover rounded-xl shadow-lg" src={lid.photo} alt="" />
                                    <h1 className="w-full text-center mt-5 text-gray-500 dark:text-gray-300 text-xl">
                                        {lid.voornaam} {lid.achternaam}
                                    </h1>
                                    <h2 className="w-full text-center text-blue-600 dark:text-blue-400 font-extrabold leading-5 text-lg uppercase">
                                        {lid.prefix} {PRAESIDIUM_FUNCTIES[lid.functie]}
                                    </h2>
                                    <div className="mx-auto flex flex-col justify-between gap-3 mt-3">
                                        <a href={"mailto:" + lid.mail} className="text-gray-400 flex items-center gap-4 text-md transition-colors hover:text-blue-600 dark:hover:text-blue-400">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                                            </svg>
                                            {lid.mail}
                                        </a>

                                        {lid.telephone_number ? (
                                            <a href={"tel:" + lid.telephone_number} className="text-gray-400 flex items-center gap-4 text-md transition-colors hover:text-blue-600 dark:hover:text-blue-400">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth={2}
                                                        d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                                                    />
                                                </svg>
                                                {lid.telephone_number}
                                            </a>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                </div>
                            )}
                        </>
                    );
                })}
            </div>
        </div>
    );
}

export default Praesidium;
