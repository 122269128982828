import React from "react";
import Navigation from "../components/navigation";

import "aos/dist/aos.css";

import { Helmet } from "react-helmet";
import { check_login } from "../auth/auth";
import { useEffect } from "react";
import { useState } from "react";
import QRCode from "react-qr-code";

function MijnGegevens() {
    const [claims, setClaims] = useState<any>(null);
    useEffect(() => {
        let claims: any = JSON.parse(check_login(false));
        setClaims(claims);
        console.log(claims);
    }, []);

    return (
        <>
            {!!check_login(true) ? (
                <div className="dark:bg-zinc-900">
                    <Navigation />
                    <Helmet>
                        <title>Mijn Gegevens - Hermes Gent</title>
                        <meta name="description" content={"Hermes Gent - mijn gegevens"} />
                    </Helmet>
                    <div className="lg:w-8/12 w-11/12 pt-20 pb-40 mx-auto text-zinc-700 dark:text-zinc-300">
                        <div className="flex flex-col items-center gap-8">
                            <div className="flex flex-col items-center gap-1">
                                <div className="flex flex-col sm:flex-row items-center gap-4">
                                    <div className="w-14 h-14 rounded-md text-3xl bg-blue-200 text-blue-700 font-bold dark:bg-blue-600 dark:text-zinc-200 flex items-center justify-center">{claims?.first_name?.toUpperCase().slice(0, 1)}</div>
                                    <h1 className="text-4xl font-bold text-center">
                                        {claims?.first_name} {claims?.last_name}
                                    </h1>
                                </div>
                                {claims?.rol === "sch" && <div className="text-sm px-2 py-1 bg-orange-500 text-white rounded font-semibold w-fit">Schacht</div>}
                                {claims?.functie ? <div className="text-sm px-2 py-1 bg-purple-500 capitalize text-white rounded font-semibold w-fit">{claims?.functie}</div> : claims?.rol === "com" && <div className="text-sm px-2 py-1 bg-blue-500 text-white rounded font-semibold w-fit">Commi</div>}
                            </div>

                            {claims?.user_id ? (
                                <div className="flex items-center justify-center w-fit p-4 rounded-3xl bg-gradient-to-tr from-blue-500 to-purple-500">
                                    <div className="bg-white p-4 rounded-xl">
                                        <QRCode value={claims?.user_id} />
                                    </div>
                                </div>
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                </div>
            ) : (
                ""
            )}
        </>
    );
}

export default MijnGegevens;
