import PaneelNavigatie from "../../components/Paneel/paneelNavigatie";

import { useEffect, useState } from "react";
import config from "../../config";
import axios from "axios";
import { Disclosure } from "@headlessui/react";

import DateFormat from "../../components/dateFormater";
import EventToevoegen from "../../components/Paneel/Events/eventToevoegen";
import EventBewerken from "../../components/Paneel/Events/eventBewerken";
//import EventTypes from "../../components/Paneel/Events/eventTypes";
import Modal from "../../components/modal";
import { Helmet } from "react-helmet";
import { EVENT_TYPES } from "../../components/Choices";

import { check_login } from "../../auth/auth";
import { check_permission } from "../../auth/auth";
import Loading from "../../components/Loading";

function Paneel_Events() {
	const [claims] = useState(check_login(true));
	const [allowed] = useState(check_permission("praesidium", true));
	let now = new Date();

	const [eventToevoegenModal, setEventToevoegenModal] = useState(false);
	const [eventBewerkenModal, setEventBewerkenModal] = useState([]);

	let [loading, setLoading] = useState(true);

	const [spEvents, setSpEvents] = useState([]);
	const [endedEvents, setEndedEvents] = useState([]);
	const [notAcceptedEvents, setNotAcceptedEvents] = useState([]);
	const [events, setEvents] = useState([]);

	useEffect(() => {
		Render();
	}, []);

	function Render() {
		let ended = [];
		let sp = [];
		let ev = [];
		let na = [];
		axios
			.get(config.api_url + "/events/?sort=start")
			.then((response) => {
				let data = response.data.data;
				for (let i = 0; i < data.length; i++) {
					let e = data[i];
					let e_end = new Date(e.end);
					if (!e.approved) {
						na.push(e);
					} else if (e.spotlight) {
						sp.push(e);
					} else if (e_end < now) {
						ended.push(e);
					} else {
						ev.push(e);
					}
				}
				setSpEvents(sp);
				setEndedEvents(ended);
				setEvents(ev);
				setNotAcceptedEvents(na);
				setLoading(false);
			})
			.catch(() => {
				setLoading(false);
			});
	}

	return (
		<>
			{claims && allowed && (
				<div className="dark:bg-zinc-900">
					<PaneelNavigatie />
					<Helmet>
						<title>Paneel - Events - Hermes Gent</title>
					</Helmet>
					<div className="lg:w-8/12 w-11/12 pt-10 pb-40 mx-auto ">
						<div className="flex flex-col lg:flex-row items-center mb-8 border-b-2 border-zinc-200 dark:border-zinc-800 p-6 pl-0 pb-2">
							<h1 className="font-bold text-4xl uppercase text-zinc-800  dark:text-zinc-300">EVENTS</h1>

							<div className="flex flex-col md:flex-row items-center">
								<Modal
									condition={eventToevoegenModal}
									setCondition={setEventToevoegenModal}
									boxClasses="w-full md:w-8/12 xl:w-7/12 md:mx-auto mt-12 lg:mt-36 mb-16"
									openButtonClasses="flex items-center h-14 px-6 transition-colors text-zinc-500 hover:text-zinc-800 dark:text-zinc-400 dark:hover:text-zinc-300"
									openButtonValue={
										<>
											<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
												<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={3} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
											</svg>
											<span className="pl-2">EVENT TOEVOEGEN</span>
										</>
									}>
									<EventToevoegen reRender={() => Render()} />
								</Modal>

								{/*<Modal
									condition={false} setCondition={}
									boxClasses="w-full md:w-8/12 xl:w-7/12 md:mx-auto mt-12 lg:mt-36 mb-16"
									openButtonClasses="flex items-center hidden h-14 px-6 transition-colors text-zinc-500 hover:text-zinc-800 dark:text-zinc-400 dark:hover:text-zinc-300"
									openButtonValue={
										<>
											<svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
												<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
											</svg>
											<span className="pl-2">EVENT TYPES</span>
										</>
									}>
									<EventTypes reRender={() => Render()} />
								</Modal>*/}
							</div>
						</div>

						{loading && (
							<div className="flex items-center justify-center">
								<Loading size={8} />
							</div>
						)}

						{!loading && (
							<>
								<Disclosure defaultOpen={true}>
									{({ open }) => (
										<div className="mt-8 mb-2">
											<Disclosure.Button className="py-2 border border-zinc-200 dark:border-zinc-800 w-full flex items-center p-4 rounded-lg">
												<div className="flex-grow text-left text-zinc-700 dark:text-zinc-200">
													<h2 className="text-gray-500 dark:text-gray-300 text-xl md:text-2xl font-bold uppercase">Evenementen in de kijker</h2>
												</div>
												<div>
													<svg xmlns="http://www.w3.org/2000/svg" className={`h-5 w-5 transition-transform text-zinc-400 ${open ? "transform rotate-180" : ""}`} viewBox="0 0 20 20" fill="currentColor">
														<path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
													</svg>
												</div>
											</Disclosure.Button>
											<Disclosure.Panel className="py-6">
												{!loading && spEvents.length === 0 && <div className="text-zinc-400 dark:text-zinc-500">Geen evenementen gevonden in deze categorie</div>}
												{spEvents.map((event, index) => {
													return (
														<div key={index} className="flex flex-row items-center relative shadow w-100 p-3 rounded-xl mb-2 dark:bg-black/20">
															<div className="flex-grow flex items-center pl-2">
																<h4 className="flex text-gray-300 dark:text-gray-500 mr-2">
																	<div
																		style={{
																			backgroundColor: !EVENT_TYPES[event.type].no_back ? EVENT_TYPES[event.type].background_color : "",
																			color: !EVENT_TYPES[event.type].no_back ? EVENT_TYPES[event.type].text_color : "",
																		}}
																		className={"w-6 h-6 flex items-center justify-center " + (!EVENT_TYPES[event.type].no_back ? "rounded text-xs font-bold p-1" : "text-lg")}>
																		{EVENT_TYPES[event.type].icon}
																	</div>
																</h4>
																<h1 className="text-gray-500 dark:text-gray-300 text-sm md:text-xl">{event.title}</h1>
																<h2 className="pl-2 flex text-gray-300 dark:text-gray-500">
																	/ <DateFormat date={event.start} format="$d$ $M$" trySpecial={true} styles="ml-2 text-blue-600 dark:text-blue-400 capitalize" />
																</h2>
																<h3 className="pl-2 flex text-gray-300 dark:text-gray-500">
																	{new Date(event.end) < now ? (
																		<span className="flex items-center">
																			/ <div className="w-4 h-4 ml-2 rounded-full bg-red-400"></div>
																		</span>
																	) : (
																		""
																	)}
																</h3>
															</div>
															<Modal
																condition={eventBewerkenModal}
																setCondition={setEventBewerkenModal}
																uuid={event.id}
																boxClasses="w-full md:w-8/12 xl:w-7/12 md:mx-auto mt-12 lg:mt-36 mb-16"
																openButtonClasses="dark:text-zinc-400 p-2 rounded-full text-zinc-500 text-md transition-colors hover:text-blue-600 dark:hover:text-blue-400 mr-3"
																openButtonValue={
																	<svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
																		<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
																	</svg>
																}>
																<EventBewerken close={() => setEventBewerkenModal(eventBewerkenModal.filter((x) => x !== event.id))} eventID={event.id} reRender={() => Render()} />
															</Modal>
														</div>
													);
												})}
											</Disclosure.Panel>
										</div>
									)}
								</Disclosure>

								<Disclosure defaultOpen={true}>
									{({ open }) => (
										<div className="mt-8 mb-2">
											<Disclosure.Button className="py-2 border border-zinc-200 dark:border-zinc-800 w-full flex items-center p-4 rounded-lg">
												<div className="flex-grow text-left text-zinc-700 dark:text-zinc-200">
													<h2 className="text-gray-500 dark:text-gray-300 text-xl md:text-2xl font-bold uppercase">Lopende Evenementen</h2>
												</div>
												<div>
													<svg xmlns="http://www.w3.org/2000/svg" className={`h-5 w-5 transition-transform text-zinc-400 ${open ? "transform rotate-180" : ""}`} viewBox="0 0 20 20" fill="currentColor">
														<path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
													</svg>
												</div>
											</Disclosure.Button>
											<Disclosure.Panel className="py-6">
												{!loading && events.length === 0 && <div className="text-zinc-400 dark:text-zinc-500">Geen evenementen gevonden in deze categorie</div>}
												{events.map((event, index) => {
													return (
														<div key={index} className="flex flex-row items-center relative shadow w-100 p-3 rounded-xl mb-2 dark:bg-black/20">
															<div className="flex-grow flex items-center pl-2">
																<h4 className="flex text-gray-300 dark:text-gray-500 mr-2">
																	<div
																		style={{
																			backgroundColor: !EVENT_TYPES[event.type].no_back ? EVENT_TYPES[event.type].background_color : "",
																			color: !EVENT_TYPES[event.type].no_back ? EVENT_TYPES[event.type].text_color : "",
																		}}
																		className={"w-6 h-6 flex items-center justify-center " + (!EVENT_TYPES[event.type].no_back ? "rounded text-xs font-bold p-1" : "text-lg")}>
																		{EVENT_TYPES[event.type].icon}
																	</div>
																</h4>
																<h1 className="text-gray-500 dark:text-gray-300 text-sm md:text-xl">{event.title}</h1>
																<h2 className="pl-2 flex text-gray-300 dark:text-gray-500">
																	/ <DateFormat date={event.start} format="$d$ $M$" trySpecial={true} styles="ml-2 text-blue-600 dark:text-blue-400 capitalize" />
																</h2>
																<h3 className="pl-2 flex text-gray-300 dark:text-gray-500">
																	{new Date(event.end) < now ? (
																		<span className="flex items-center">
																			/ <div className="w-4 h-4 ml-2 rounded-full bg-red-400"></div>
																		</span>
																	) : (
																		""
																	)}
																</h3>
															</div>
															<Modal
																condition={eventBewerkenModal}
																setCondition={setEventBewerkenModal}
																uuid={event.id}
																boxClasses="w-full md:w-8/12 xl:w-7/12 md:mx-auto mt-12 lg:mt-36 mb-16"
																openButtonClasses="dark:text-zinc-400 p-2 rounded-full text-zinc-500 text-md transition-colors hover:text-blue-600 dark:hover:text-blue-400 mr-3"
																openButtonValue={
																	<svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
																		<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
																	</svg>
																}>
																<EventBewerken close={() => setEventBewerkenModal(eventBewerkenModal.filter((x) => x !== event.id))} eventID={event.id} reRender={() => Render()} />
															</Modal>
														</div>
													);
												})}
											</Disclosure.Panel>
										</div>
									)}
								</Disclosure>

								<Disclosure defaultOpen={true}>
									{({ open }) => (
										<div className="mt-8 mb-2">
											<Disclosure.Button className="py-2 border border-zinc-200 dark:border-zinc-800 w-full flex items-center p-4 rounded-lg">
												<div className="flex-grow text-left text-zinc-700 dark:text-zinc-200">
													<h2 className="text-gray-500 dark:text-gray-300 text-xl md:text-2xl font-bold uppercase">Niet geaccepteerde evenementen</h2>
												</div>
												<div>
													<svg xmlns="http://www.w3.org/2000/svg" className={`h-5 w-5 transition-transform text-zinc-400 ${open ? "transform rotate-180" : ""}`} viewBox="0 0 20 20" fill="currentColor">
														<path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
													</svg>
												</div>
											</Disclosure.Button>
											<Disclosure.Panel className="py-6">
												{!loading && notAcceptedEvents.length === 0 && <div className="text-zinc-400 dark:text-zinc-500">Geen evenementen gevonden in deze categorie</div>}
												{notAcceptedEvents.map((event, index) => {
													return (
														<div key={index} className="flex flex-row items-center relative shadow w-100 p-3 rounded-xl mb-2 dark:bg-black/20">
															<div className="flex-grow flex items-center pl-2">
																<h4 className="flex text-gray-300 dark:text-gray-500 mr-2">
																	<div
																		style={{
																			backgroundColor: !EVENT_TYPES[event.type].no_back ? EVENT_TYPES[event.type].background_color : "",
																			color: !EVENT_TYPES[event.type].no_back ? EVENT_TYPES[event.type].text_color : "",
																		}}
																		className={"w-6 h-6 flex items-center justify-center " + (!EVENT_TYPES[event.type].no_back ? "rounded text-xs font-bold p-1" : "text-lg")}>
																		{EVENT_TYPES[event.type].icon}
																	</div>
																</h4>
																<h1 className="text-gray-500 dark:text-gray-300 text-sm md:text-xl">{event.title}</h1>
																<h2 className="pl-2 flex text-gray-300 dark:text-gray-500">
																	/ <DateFormat date={event.start} format="$d$ $M$" trySpecial={true} styles="ml-2 text-blue-600 dark:text-blue-400 capitalize" />
																</h2>
																<h3 className="pl-2 flex text-gray-300 dark:text-gray-500">
																	{new Date(event.end) < now ? (
																		<span className="flex items-center">
																			/ <div className="w-4 h-4 ml-2 rounded-full bg-red-400"></div>
																		</span>
																	) : (
																		""
																	)}
																</h3>
															</div>
															<Modal
																condition={eventBewerkenModal}
																setCondition={setEventBewerkenModal}
																uuid={event.id}
																boxClasses="w-full md:w-8/12 xl:w-7/12 md:mx-auto mt-12 lg:mt-36 mb-16"
																openButtonClasses="dark:text-zinc-400 p-2 rounded-full text-zinc-500 text-md transition-colors hover:text-blue-600 dark:hover:text-blue-400 mr-3"
																openButtonValue={
																	<svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
																		<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
																	</svg>
																}>
																<EventBewerken close={() => setEventBewerkenModal(eventBewerkenModal.filter((x) => x !== event.id))} eventID={event.id} reRender={() => Render()} />
															</Modal>
														</div>
													);
												})}
											</Disclosure.Panel>
										</div>
									)}
								</Disclosure>

								<Disclosure>
									{({ open }) => (
										<div className="mt-8 mb-2">
											<Disclosure.Button className="py-2 border border-zinc-200 dark:border-zinc-800 w-full flex items-center p-4 rounded-lg">
												<div className="flex-grow text-left text-zinc-700 dark:text-zinc-200">
													<h2 className="text-gray-500 dark:text-gray-300 text-xl md:text-2xl font-bold uppercase">Afgelopen Evenementen</h2>
												</div>
												<div>
													<svg xmlns="http://www.w3.org/2000/svg" className={`h-5 w-5 transition-transform text-zinc-400 ${open ? "transform rotate-180" : ""}`} viewBox="0 0 20 20" fill="currentColor">
														<path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
													</svg>
												</div>
											</Disclosure.Button>
											<Disclosure.Panel className="py-6">
												{!loading && endedEvents.length === 0 && <div className="text-zinc-400 dark:text-zinc-500">Geen evenementen gevonden in deze categorie</div>}
												{endedEvents
													.slice(0)
													.reverse()
													.map((event, index) => {
														return (
															<div key={index} className="flex flex-row items-center relative shadow w-100 p-3 rounded-xl mb-2 dark:bg-black/20">
																<div className="flex-grow flex items-center pl-2">
																	<h4 className="flex text-gray-300 dark:text-gray-500 mr-2">
																		<div
																			style={{
																				backgroundColor: !EVENT_TYPES[event.type].no_back ? EVENT_TYPES[event.type].background_color : "",
																				color: !EVENT_TYPES[event.type].no_back ? EVENT_TYPES[event.type].text_color : "",
																			}}
																			className={"w-6 h-6 flex items-center justify-center " + (!EVENT_TYPES[event.type].no_back ? "rounded text-xs font-bold p-1" : "text-lg")}>
																			{EVENT_TYPES[event.type].icon}
																		</div>
																	</h4>
																	<h1 className="text-gray-500 dark:text-gray-300 text-sm md:text-xl">{event.title}</h1>
																	<h2 className="pl-2 flex text-gray-300 dark:text-gray-500">
																		/ <DateFormat date={event.start} format="$d$ $M$" trySpecial={true} styles="ml-2 text-blue-600 dark:text-blue-400 capitalize" />
																	</h2>
																	<h3 className="pl-2 flex text-gray-300 dark:text-gray-500">
																		{new Date(event.end) < now ? (
																			<span className="flex items-center">
																				/ <div className="w-4 h-4 ml-2 rounded-full bg-red-400"></div>
																			</span>
																		) : (
																			""
																		)}
																	</h3>
																</div>
																<Modal
																	condition={eventBewerkenModal}
																	setCondition={setEventBewerkenModal}
																	uuid={event.id}
																	boxClasses="w-full md:w-8/12 xl:w-7/12 md:mx-auto mt-12 lg:mt-36 mb-16"
																	openButtonClasses="dark:text-zinc-400 p-2 rounded-full text-zinc-500 text-md transition-colors hover:text-blue-600 dark:hover:text-blue-400 mr-3"
																	openButtonValue={
																		<svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
																			<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
																		</svg>
																	}>
																	<EventBewerken close={() => setEventBewerkenModal(eventBewerkenModal.filter((x) => x !== event.id))} eventID={event.id} reRender={() => Render()} />
																</Modal>
															</div>
														);
													})}
											</Disclosure.Panel>
										</div>
									)}
								</Disclosure>
							</>
						)}
					</div>
				</div>
			)}
		</>
	);
}

export default Paneel_Events;
