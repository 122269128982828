import PaneelNavigatie from "../../components/Paneel/paneelNavigatie";

import { useState } from "react";
import config from "../../config";
import { useEffect } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import { check_login } from "../../auth/auth";
import { check_permission } from "../../auth/auth";
import { Link } from "react-router-dom";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import { Bar } from "react-chartjs-2";
import React from "react";
import { Event, Gebruiker } from "../../modules/types";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const labels = ["Januari", "Februari", "Maart", "April", "Mei", "Juni", "Juli", "Augustus", "September", "Oktober", "November", "December"];

function Paneel_Home() {
    const [claims] = useState(check_login(true));
    const [allowed] = useState(check_permission("praesidium", true));
    const [users, setUsers] = useState<Gebruiker[]>([]);
    const [events, setEvents] = useState<Event[]>([]);
    const [chartData, SetChartData] = useState<{ labels: string[]; datasets: any }>({
        labels,
        datasets: []
    });
    const [chartOptions, setChartOptions] = useState({
        responsive: true,
        plugins: {
            legend: {
                position: "top" as const
            },
            title: {
                display: true,
                text: `Statistieken ${new Date().getFullYear()}`
            }
        }
    });
    const [statsYear, setStatsYear] = useState(new Date().getFullYear());

    const [counters, setCounters] = useState({
        pages: 0,
        events: 0
    });

    useEffect(() => {
        let data = { pages: 0, events: 0 };
        axios.get(config.api_url + "/pages/").then((response) => {
            data = { ...data, pages: response.data.meta.size };
            setCounters(data);
        });
        axios.get(config.api_url + "/events/").then((response) => {
            data = { ...data, events: response.data.meta.size };
            setCounters(data);
            setEvents(response.data.data);
        });
        if (check_permission("ict", false)) {
            axios.get(config.api_url + "/users/").then((response) => {
                setUsers(response.data.data);
            });
        }
    }, []);

    useEffect(() => {
        const usersPerMonth: { [key: string]: number } = {
            Januari: 0,
            Februari: 0,
            Maart: 0,
            April: 0,
            Mei: 0,
            Juni: 0,
            Juli: 0,
            Augustus: 0,
            September: 0,
            Oktober: 0,
            November: 0,
            December: 0
        };

        users.forEach((user) => {
            const dateJoined = new Date(user.date_joined);
            const month = dateJoined.getMonth();
            const monthLabel = labels[month];
            if (dateJoined.getFullYear() === statsYear) usersPerMonth[monthLabel]++;
        });

        const eventsPerMonth: { [key: string]: number } = {
            Januari: 0,
            Februari: 0,
            Maart: 0,
            April: 0,
            Mei: 0,
            Juni: 0,
            Juli: 0,
            Augustus: 0,
            September: 0,
            Oktober: 0,
            November: 0,
            December: 0
        };

        events.forEach((e) => {
            const dateJoined = new Date(e.start);
            const month = dateJoined.getMonth();
            const monthLabel = labels[month];
            if (dateJoined.getFullYear() === statsYear) eventsPerMonth[monthLabel]++;
        });

        const datasets = [
            {
                label: "Gebruikers",
                data: labels.map((month) => usersPerMonth[month]),
                backgroundColor: "rgba(39, 86, 245, 0.55)"
            },
            {
                label: "Activiteiten",
                data: labels.map((month) => eventsPerMonth[month]),
                backgroundColor: "rgba(245, 241, 39, 0.57)"
            }
        ];

        SetChartData((prev) => {
            return {
                labels: prev.labels,
                datasets: datasets
            };
        });

        setChartOptions({
            responsive: true,
            plugins: {
                legend: {
                    position: "top" as const
                },
                title: {
                    display: true,
                    text: `Statistieken ${statsYear}`
                }
            }
        });
    }, [users, events, statsYear]);

    return (
        <>
            {claims && allowed && (
                <>
                    <div className="dark:bg-zinc-900">
                        <PaneelNavigatie />
                        <Helmet>
                            <title>Paneel - Home - Hermes Gent</title>
                        </Helmet>
                        <div className="lg:w-8/12 w-11/12 pt-20 pb-40 mx-auto">
                            <h1 className="font-extrabold text-2xl md:text-3xl xl:text-4xl text-gray-700 dark:text-gray-300 mb-10">PRAESIDIUM PANEEL</h1>
                            {check_permission("ict", false) && (
                                <div className="mb-8 flex">
                                    <button className="text-zinc-800 dark:text-zinc-200 hover:text-zinc-400 hover:dark:text-zinc-700 transition-colors" onClick={() => setStatsYear((prev) => prev - 1)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                                        </svg>
                                    </button>
                                    <Bar options={chartOptions} data={chartData} />
                                    <button className="text-zinc-800 dark:text-zinc-200 hover:text-zinc-400 hover:dark:text-zinc-700 transition-colors" onClick={() => setStatsYear((prev) => prev + 1)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                                        </svg>
                                    </button>
                                </div>
                            )}

                            <div className="grid gap-8 md:grid-cols-2">
                                <Link to={"/paneel/events"} className="shadow-md border border-zinc-200 p-12 rounded-lg dark:bg-black/40 dark:border-zinc-800 transition-transform hover:scale-110">
                                    <h1 className="font-extrabold text-2xl md:text-3xl xl:text-4xl text-gray-700 dark:text-gray-300 mb-10 text-center">
                                        EVENTS
                                        <p className="mt-8 text-8xl">{counters.events}</p>
                                    </h1>
                                </Link>
                                <Link to={"/paneel/pages"} className="shadow-md border border-zinc-200 p-12 rounded-lg dark:bg-black/40 dark:border-zinc-800 transition-transform hover:scale-110">
                                    <h1 className="font-extrabold text-2xl md:text-3xl xl:text-4xl text-gray-700 dark:text-gray-300 mb-10 text-center">
                                        PAGINA'S
                                        <p className="mt-8 text-8xl">{counters.pages}</p>
                                    </h1>
                                </Link>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
}

export default Paneel_Home;
