import { Transition } from "@headlessui/react";
import { useState, useEffect, useRef } from "react";
import { UserNavigation } from "../navigation";

import Modal from "../modal";
import Login from "../../auth/login";
import config from "../../config";
import { check_login } from "../../auth/auth";
import { check_permission } from "../../auth/auth";
import { Link, useLocation } from "react-router-dom";
function PaneelNavigatie() {
    const [showSidebar, setShowSidebar] = useState(false);

    const [account, setAccount] = useState(null);

    const [loginModal, setLoginModal] = useState(false);

    function updateDarkMode() {
        if (!("theme" in localStorage)) {
            localStorage.theme = "light";
        }
        if (localStorage.theme === "dark" || !("theme" in localStorage)) {
            document.documentElement.classList.add("dark");
        } else {
            document.documentElement.classList.remove("dark");
        }
    }

    useEffect(() => {
        updateDarkMode();
    });

    //closing nav on ref
    const navRef = useRef();

    function checkLogin() {
        const logged_in = check_login();
        if (!!logged_in) {
            let claims = JSON.parse(logged_in);
            //console.log(claims);
            setAccount({ gebruikersnaam: claims?.first_name, praesidium: claims?.permissions?.praesidium });
        } else {
            setAccount(null);
        }
    }

    useEffect(() => {
        updateDarkMode();
    }, []);

    useEffect(() => {
        checkLogin();
    }, []);

    useEffect(() => {
        function handler(event) {
            if (!navRef.current?.contains(event.target)) {
                setShowSidebar(false);
            }
        }
        window.addEventListener("click", handler);
        return () => window.removeEventListener("click", handler);
    }, []);

    let location = useLocation();
    useEffect(() => {
        setShowSidebar(false);

        updateDarkMode();
    }, [location]);

    return (
        <div ref={navRef} className="md:mb-28 mb-24">
            <div className="fixed top-0 w-full z-20 h-24 md:h-28 bg-white dark:bg-zinc-900 flex items-center justify-center shadow">
                <div className="lg:w-8/12 w-11/12 text-zinc-700 dark:text-zinc-400 flex">
                    <div className="flex items-center gap-6 flex-grow">
                        <Link to={"/"} className="col-span-2 md:col-span-2 flex md:justify-center">
                            <img className="w-[4.5rem] p-2 md:p-0" src={config.base_url + "/images/Hermes.svg"} alt="Hermes" />
                        </Link>
                    </div>

                    <div className="flex items-center gap-7">
                        {check_permission("ict", false) && (
                            <Link to={"/paneel/gebruikers"} className="hidden xl:block uppercase text-zinc-500 font-semibold dark:text-zinc-400 dark:hover:text-blue-300 transition-colors hover:text-blue-600">
                                GEBRUIKERS
                            </Link>
                        )}
                        {check_permission("ict", false) && (
                            <Link to={"/paneel/stem-module"} className="hidden xl:block uppercase text-zinc-500 font-semibold dark:text-zinc-400 dark:hover:text-blue-300 transition-colors hover:text-blue-600">
                                STEM MODULE
                            </Link>
                        )}
                        <Link to={"/paneel/pages"} className="hidden sm:block uppercase text-zinc-500 font-semibold dark:text-zinc-400 dark:hover:text-blue-300 transition-colors hover:text-blue-600">
                            PAGINA'S
                        </Link>
                        <Link to={"/paneel/events"} className="hidden xs:block uppercase text-zinc-500 font-semibold dark:text-zinc-400 dark:hover:text-blue-300 transition-colors hover:text-blue-600">
                            EVENEMENTEN
                        </Link>
                        <Link to={"/paneel/lidkaarten"} className="hidden xs:block uppercase text-zinc-500 font-semibold dark:text-zinc-400 dark:hover:text-blue-300 transition-colors hover:text-blue-600">
                            LIDKAARTEN
                        </Link>

                        <button className="2xl:hidden" onClick={() => setShowSidebar(!showSidebar)}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd" />
                            </svg>
                        </button>
                        {!account ? (
                            <Modal condition={loginModal} setCondition={setLoginModal} openButtonValue="LOGIN" openButtonClasses="font-bold text-blue-600 dark:text-blue-400" boxClasses="w-full px-2 md:w-10/12 lg:w-6/12 xl:w-4/12 md:mx-auto mt-12 lg:mt-36 mb-16">
                                <Login reload={() => checkLogin()} />
                            </Modal>
                        ) : (
                            <UserNavigation account={account} setAccount={setAccount} />
                        )}
                    </div>
                </div>
            </div>

            <div className="fixed top-0 z-30 right-0">
                <Transition show={showSidebar} enter="transition-transform duration-200" enterFrom="translate-x-[32em]" enterTo="translate-x-0" leave="transition-all duration-200" leaveFrom="translate-x-0" leaveTo="translate-x-[32em]">
                    <div className="w-screen md:w-96 h-screen bg-white dark:bg-zinc-900 shadow-lg dark:border-r dark:border-zinc-700 relative">
                        <button className="absolute right-5 top-5 justify-end text-zinc-400 dark:text-zinc-600 transition-colors p-2 rounded-full hover:bg-zinc-100 dark:hover:bg-zinc-700 dark:hover:text-zinc-500" onClick={() => setShowSidebar(false)}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                            </svg>
                        </button>

                        <div className="h-full flex flex-col gap-1 md:gap-3 lg:gap-6 overflow-y-auto py-20 pr-16">
                            {check_permission("ict", false) && (
                                <div className="xl:hidden uppercase font-bold text-lg text-zinc-600 dark:text-zinc-400">
                                    <Link to={"/paneel/gebruikers"} className="2xl:hidden flex justify-end gap-1 cursor-pointer py-4 transition-colors hover:text-blue-400">
                                        Gebruikers
                                    </Link>
                                </div>
                            )}
                            {check_permission("ict", false) && (
                                <div className="xs:hidden uppercase font-bold text-lg text-zinc-600 dark:text-zinc-400">
                                    <Link to={"/paneel/lidkaarten"} className="2xl:hidden flex justify-end gap-1 cursor-pointer py-4 transition-colors hover:text-blue-400">
                                        Lidkaarten
                                    </Link>
                                </div>
                            )}
                            <div className="xs:hidden uppercase font-bold text-lg text-zinc-600 dark:text-zinc-400">
                                <Link to={"/paneel/events"} className="flex justify-end gap-1 cursor-pointer py-4 transition-colors hover:text-blue-400">
                                    Evenementen
                                </Link>
                            </div>
                            <div className="sm:hidden uppercase font-bold text-lg text-zinc-600 dark:text-zinc-400">
                                <Link to={"/paneel/pages"} className="flex justify-end gap-1 cursor-pointer py-4 transition-colors hover:text-blue-400">
                                    Pagina's
                                </Link>
                            </div>
                            <div className="xs:hidden uppercase font-bold text-lg text-zinc-600 dark:text-zinc-400">
                                <Link to={"/paneel/shop"} className="flex justify-end gap-1 cursor-pointer py-4 transition-colors hover:text-blue-400">
                                    Cursusverkoop
                                </Link>
                            </div>
                            {check_permission("ict", false) && (
                                <div className="xl:hidden uppercase font-bold text-lg text-zinc-600 dark:text-zinc-400">
                                    <Link to={"/paneel/stem-module"} className="flex justify-end gap-1 cursor-pointer py-4 transition-colors hover:text-blue-400">
                                        Stem module
                                    </Link>
                                </div>
                            )}
                        </div>
                    </div>
                </Transition>
            </div>
        </div>
    );
}

export default PaneelNavigatie;
