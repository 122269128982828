import React from "react";
import Navigation from "../components/navigation";
import "aos/dist/aos.css";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import Aos from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import Notification from "../components/Notification";
import Loading from "../components/Loading";
import { Sponsor } from "../modules/types";
import { RESULT_STATE } from "../modules/enums";
import { _ } from "../modules/functions";
import { useFetch } from "../hooks/useFetch";

const MAX_LENGHT_TEXT = 280;
function Sponsors() {
    useEffect(() => {
        Aos.init();
    });
    const [sponsors, setSponsors] = useState<Sponsor[]>([]);
    const [loading, setLoading] = useState(true);

    const { state: baseState, data: baseSponsors, error: baseError } = useFetch<Sponsor[]>("https://hrbackend.hermesgent.be/companies?company_role=base");
    const { state: recrState, data: recrSponsors, error: recrError } = useFetch<Sponsor[]>("https://hrbackend.hermesgent.be/companies?company_role=recr");
    const { state: headState, data: headSponsors, error: headError } = useFetch<Sponsor[]>("https://hrbackend.hermesgent.be/companies?company_role=head");

    useEffect(
        () => {
            let x: Sponsor[] = [];
            if (headSponsors) x = [...x, ..._.SHUFFEL_ARRAY(headSponsors.filter(sponsor => sponsor.active))];
            if (recrSponsors) x = [...x, ..._.SHUFFEL_ARRAY(recrSponsors.filter(sponsor => sponsor.active))];
            if (baseSponsors) x = [...x, ..._.SHUFFEL_ARRAY(baseSponsors.filter(sponsor => sponsor.active))];
            setSponsors(x);
            setLoading(baseState === RESULT_STATE.LOADING && recrState === RESULT_STATE.LOADING && headState === RESULT_STATE.LOADING);
        },
        [baseSponsors, recrSponsors, headSponsors]
    );

    return (
        <div className="dark:bg-zinc-900">
            <Navigation />
            <Helmet>
                <title>Sponsors - Hermes Gent</title>
                <meta name="description" content={"Hermes Gent - Sponsors"} />
            </Helmet>
            <div className="lg:w-8/12 w-11/12 pt-20 pb-40 mx-auto">
                {loading &&
                    <div className="flex items-center justify-center">
                        <Loading size={8} />
                    </div>}
                {!loading &&
                    <div className="grid md:grid-cols-2 xl:grid-cols-3 gap-4">
                        {sponsors.map(sponsor => {
                            return (
                                <div data-aos="fade-up" aos-data-duration="800">
                                    <Link to={"/sponsor/" + sponsor.company_name.replace(/\s+/g, "-").toLowerCase() + "/" + sponsor.id} className="group flex flex-col items-center justify-center h-64 shadow mb-4 rounded-md transition-transform md:hover:scale-105 bg-white">
                                        <div className="group-hover:hidden transition-all">
                                            <img alt={sponsor.company_name} className="max-h-48 px-8" src={sponsor.company_logo} />
                                        </div>
                                        <div className="text-zinc-700 p-8 w-fit h-fit transition-all hidden group-hover:block">
                                            <p>
                                                {sponsor.company_short_desc.length > MAX_LENGHT_TEXT ? sponsor.company_short_desc.slice(0, MAX_LENGHT_TEXT) + "..." : sponsor.company_short_desc}
                                            </p>
                                        </div>
                                    </Link>
                                    {/*<a href={config.base_url + "/sponsor/" + sponsor.company_name.replace(/\s+/g, "-").toLowerCase() + "/" + sponsor.id} className="grid grid-cols-12 shadow mb-4 p-8 rounded-md gap-4 transition-transform hover:scale-105 bg-white">
									<div className="col-span-12 lg:col-span-3 flex items-center justify-center">
										<img alt={sponsor.company_name} className="w-full max-w-xs" src={sponsor.company_logo} />
									</div>
									<div className="col-span-12 lg:col-span-9 lg:pl-16 text-zinc-700">
										<h2 className="mb-4 font-bold text-xl">{sponsor.company_name}</h2>
										<p>{sponsor.company_short_desc}</p>
									</div>
								</a>*/}
                                </div>
                            );
                        })}
                    </div>}
                {(baseState === RESULT_STATE.ERROR || recrState === RESULT_STATE.ERROR || headState === RESULT_STATE.ERROR) && <div>Sponsors ophalen mislukt.</div>}
            </div>
        </div>
    );
}

export default Sponsors;
