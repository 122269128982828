import React, { useState } from "react";
//import { check_login } from "./auth";
import config from "../config";
import axios from "axios";
import Notification from "../components/Notification";
import toast from "react-hot-toast";
import Modal from "../components/modal";
import { Link } from "react-router-dom";
function Login(props) {
	const [showPass, setShowPass] = useState(false);
	const [mail, setMail] = useState("");
	const [pass, setPass] = useState("");
	const [logginIn, setLoggingIn] = useState(false)

	const [notApprovedModal, setNotApprovedModal] = useState(false);

	function handleLogin(e) {
		e.preventDefault();
		if(logginIn) return;
		setLoggingIn(true);
		let fd = new FormData();
		fd.append("email", mail);
		fd.append("password", pass);

		const loginPromise = axios
			.post(config.api_url + "/auth/token/", fd)
			.catch((error) => {
				setLoggingIn(false)
				if (error?.response?.data?.code === "user_unverified") {
					setNotApprovedModal(true);
					throw Error("Inloggen mislukt, account nog niet geverifeerd");
				} else {
					throw Error("Inloggen mislukt, controleer uw gegevens");
				}
			})
			.then((res) => {
				setLoggingIn(false)
				if (res?.status === 200) {
					let token = res.data.access;
					let refresh = res.data.refresh;
					localStorage.setItem("token", token);
					localStorage.setItem("refresh", refresh);
					let claim = token.split(".")[1];
					let atobed = atob(claim);// Niet deprecated
					let parsed = JSON.parse(atobed);
					delete parsed.permissions;
					localStorage.setItem("claims", JSON.stringify(parsed)); 
					axios.defaults.headers.common["Authorization"] = "Bearer " + token;

					props.reload();
					return parsed.first_name;
				}
			});

		toast.promise(
			loginPromise,

				{
					loading: (t) => <Notification toast={t}>Bezig met inloggen...</Notification>,
					success: (data) => (t) => <Notification toast={t}>Welkom terug {data}!</Notification>,
					error: (err) => (t) => <Notification toast={t}>{err.message}</Notification>,
				},
				{
					duration: 4000,
					position: "top-right",
				}
			
		);
	}

	function resendMail() {
		let fd = new FormData();
		fd.append("email", mail);
		axios
			.patch(config.api_url + "/auth/verificate/resend/", fd)
			.then(() => {
				toast.success(
					(t) => (
						<Notification toast={t}>
							<p>Mail om uw account te verifiëren is opnieuw verzonden!</p>
						</Notification>
					),
					{
						duration: 4000,
						position: "top-right",
					}
				);
			})
			.catch((error) => {
				toast.error(
					(t) => (
						<Notification toast={t}>
							<p>
								Mail om uw account te verifiëren verzonden mislukt! <span className="font-bold">error: </span>
								{error?.message}
							</p>
						</Notification>
					),
					{
						duration: 4000,
						position: "top-right",
					}
				);
			});
		setNotApprovedModal(false);
	}

	function _handleKeyDown(e) {
		if (e.key === "Enter") {
			handleLogin(e);
		}
	}
	return (
		<>
			<Modal condition={notApprovedModal} setCondition={setNotApprovedModal} openButtonValue="LOGIN" openButtonClasses="hidden" boxClasses="w-full md:w-11/12 lg:w-7/12 xl:w-5/12 md:mx-auto mt-8 lg:mt-24 mb-16">
				<div className="p-4 lg:p-8 text-zinc-800 dark:text-zinc-200">
					Het lijkt erop dat uw account nog niet geverifeerd is. Als uw vorige mail niet meer geldig is (24u), kan u hier een nieuwe mail sturen naar: <span className="font-bold">{mail}</span> <br />
					<button onClick={() => resendMail()} className="bg-blue-700 dark:bg-blue-500 text-center font-bold py-2 w-full text-white rounded-md mt-3 focus:outline-none focus:ring-1 focus:ring-blue-600 dark:focus:ring-blue-400">
						Verstuur mail opnieuw
					</button>
				</div>
			</Modal>

			<h2 className="text-center text-4xl font-bold py-6">
				LOGIN<span className="text-blue-700 dark:text-blue-300">.</span>
			</h2>
			<form className="px-2 lg:px-10 mb-10">
				<label htmlFor="Mail">E-mailadres</label>
				<input autoFocus onKeyDown={_handleKeyDown} onChange={(e) => setMail(e.target.value)} id="Mail" name="Mail" placeholder="E-mailadres" type="mail" className="border border-zinc-300 focus:outline-none focus:ring-1 focus:ring-blue-600 dark:focus:ring-blue-400 bg-transparent dark:border-zinc-700 w-full rounded-md px-3 py-2 mb-4 autofill:bg-none" />

				<label htmlFor="Wachtwoord">Wachtwoord</label>
				<div className="relative mb-4 flex items-center">
					<input onKeyDown={_handleKeyDown} onChange={(e) => setPass(e.target.value)} id="Wachtwoord" name="Wachtwoord" placeholder="Wachtwoord" type={showPass ? "text" : "password"} className="border border-zinc-300 focus:outline-none focus:ring-1 focus:ring-blue-600 dark:focus:ring-blue-400 w-full bg-transparent dark:border-zinc-700 rounded-md px-3 py-2 autofill:bg-none" />
					<button
						onClick={(e) => {
							e.preventDefault();
							setShowPass(!showPass);
						}}
						className="absolute text-zinc-400 transition-colors hover:text-zinc-500 dark:text-zinc-600 dark:hover:text-zinc-500 right-3">
						{showPass ? (
							<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
								<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21" />
							</svg>
						) : (
							<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
								<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
								<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
							</svg>
						)}
					</button>
				</div>

				<div className="flex items-center">
					<button disabled={logginIn} onClick={(e) => handleLogin(e)} className="bg-blue-700 w-fit py-2 px-8 dark:bg-blue-500 text-center font-bold text-white rounded-md mt-3 focus:outline-none focus:ring-1 focus:ring-blue-600 dark:focus:ring-blue-400 hover:bg-blue-200 dark:hover:bg-blue-200 hover:text-blue-800 transition-colors">
						LOGIN
					</button>
					<p className="mt-2 flex-grow text-right">
						Nog geen account?{" "}
						<Link className="text-blue-700 dark:text-blue-400 italic" to={"/registreer"}>
							Registreer hier
						</Link>
					</p>
				</div>
				<p className="mt-8 italic">
					Wachtwoord vergeten? klik{" "}
					<a href={"/wachtwoord-vergeten"} className="text-blue-700 dark:text-blue-400 italic">
						hier
					</a>
				</p>
			</form>
		</>
	);
}

export default Login;
