import React from "react";
import Navigation from "../components/navigation";
import Modal from "../components/modal";
import { Helmet } from "react-helmet";
import { useState } from "react";
import config from "../config";
import toast from "react-hot-toast";
import Notification from "../components/Notification";
import axios from "axios";
import { Link } from "react-router-dom";
function Registreer() {
	const [mail, setMail] = useState("");
	const [voornaam, setVoornaam] = useState("");
	const [achternaam, setAchternaam] = useState("");
	const [pass1, setPass1] = useState("");
	const [pass2, setPass2] = useState("");
	const [showPass, setShowPass] = useState(false);
	const [pp, setPP] = useState(false);

	const [registeredModal, setRegisteredModal] = useState(false);

	function error(message: string | JSX.Element) {
		toast.error((t) => <Notification toast={t}>{message}</Notification>, {
			duration: 4000,
			position: "top-right",
		});
	}

	function _handleKeyDown(e: any) {
		if (e.key === "Enter") {
			handleRegisteer();
		}
	}

	function handleRegisteer() {
		if (!mail) {
			error(<p>E-mailadres is niet ingevuld</p>);
			return;
		}
		if (!voornaam) {
			error(<p>Voornaam is niet ingevuld</p>);
			return;
		}
		if (!achternaam) {
			error(<p>Achternaam is niet ingevuld</p>);
			return;
		}
		if (!pass1) {
			error(<p>Wachtwoord is niet ingevuld</p>);
			return;
		}
		if (!pass2) {
			error(<p>Wachtwoord herhalen is niet ingevuld</p>);
			return;
		}
		if (pass1 !== pass2) {
			error(<p>Wachtwoorden komen niet overeen</p>);
			return;
		}
		if (pass1.length < 8 || pass2.length < 8) {
			error(
				<p>
					Wachtwoord is te kort <span className="italic">(minimaal 8 characters)</span>
				</p>
			);
			return;
		}
		const regex_mail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		if (!regex_mail.test(String(mail).toLowerCase())) {
			error(<p>E-mailadres is van de foute vorm</p>);
			return;
		}
		if (!pp) {
			error(<p>U moet akkoord gaan met de privacy policy</p>);
			return;
		}

		let fd = new FormData();
		fd.append("email", mail);
		fd.append("password", pass1);
		fd.append("first_name", voornaam);
		fd.append("last_name", achternaam);
		fd.append("privacy_policy", pp.toString());

		axios
			.post(config.api_url + "/auth/register/", fd)
			.then(() => {
				toast.success(
					(t) => (
						<Notification toast={t}>
							<p>Account succesvol aangemaakt!</p>
						</Notification>
					),
					{
						duration: 4000,
						position: "top-right",
					}
				);

				setRegisteredModal(true);
			})
			.catch((err) => {
				error(
					<p>
						Aanmaken account mislukt!
						<span className="italic text-zinc-400"> ({err.message})</span>
					</p>
				);
			});
	}
	return (
		<div className="dark:bg-zinc-900">
			<Navigation />
			<Modal condition={registeredModal} setCondition={setRegisteredModal} openButtonValue="LOGIN" openButtonClasses="hidden" boxClasses="w-full md:w-10/12 lg:w-6/12 xl:w-4/12 md:mx-auto mt-12 lg:mt-36 mb-16">
				<div className="p-4 lg:p-8 text-zinc-800 dark:text-zinc-200">Account succesvol aangemaakt, er is een mail verstuurd naar het opgegeven e-mailadres om je account te verifiëren. <br/><Link to={"/"} className="text-blue-600 font-bold dark:text-blue-400 mt-4 block">Terug naar de homepage</Link></div>
			</Modal>
			<Helmet>
				<title>Registreer - Hermes Gent</title>
				<meta name="description" content="Hermes Gent - Registreer" />
			</Helmet>
			<div className="xl:w-8/12 w-11/12 pt-20 pb-40 mx-auto text-zinc-800 dark:text-zinc-200">
				<div className="grid md:grid-cols-12">
					<div className="hidden md:block md:col-span-3 lg:col-span-4 xl:col-span-6">
						<img className="w-fit image" src={config.base_url + "/images/registreer.svg"} alt="" />
					</div>
					<div className="md:col-span-9 lg:col-span-8 xl:col-span-6 w-full md:pl-24 lg:pl-28 xl:pl-32">
						<h1 className="font-bold text-4xl uppercase text-zinc-800  dark:text-zinc-300 mb-8">REGISTREER</h1>

						<label htmlFor="Mail">E-mailadres</label>
						<input autoComplete="off" autoFocus onKeyDown={_handleKeyDown} onChange={(e) => setMail(e.target.value)} id="Mail" name="Mail" placeholder="E-mailadres" type="mail" className="border border-zinc-300 focus:outline-none focus:ring-1 focus:ring-blue-600 dark:focus:ring-blue-400 bg-transparent dark:border-zinc-700 w-full rounded-md px-3 py-2 mb-4 autofill:bg-none mt-2" />

						<label htmlFor="Voornaam">Voornaam</label>
						<input autoComplete="off" onKeyDown={_handleKeyDown} onChange={(e) => setVoornaam(e.target.value)} id="Voornaam" name="Voornaam" placeholder="Voornaam" type="text" className="border border-zinc-300 focus:outline-none focus:ring-1 focus:ring-blue-600 dark:focus:ring-blue-400 bg-transparent dark:border-zinc-700 w-full rounded-md px-3 py-2 mb-4 autofill:bg-none mt-2" />

						<label htmlFor="Achternaam">Achternaam</label>
						<input autoComplete="off" onKeyDown={_handleKeyDown} onChange={(e) => setAchternaam(e.target.value)} id="Achternaam" name="Achternaam" placeholder="Achternaam" type="text" className="border border-zinc-300 focus:outline-none focus:ring-1 focus:ring-blue-600 dark:focus:ring-blue-400 bg-transparent dark:border-zinc-700 w-full rounded-md px-3 py-2 mb-4 autofill:bg-none mt-2" />

						<label htmlFor="Wachtwoord">
							Wachtwoord <span className="italic text-zinc-400 dark:text-zinc-500">(minimaal 8 characters)</span>
						</label>
						<div className="relative">
							<input autoComplete="off" onKeyDown={_handleKeyDown} onChange={(e) => setPass1(e.target.value)} id="Wachtwoord" name="Wachtwoord" placeholder="Wachtwoord" type={showPass ? "text" : "password"} className="border border-zinc-300 focus:outline-none focus:ring-1 focus:ring-blue-600 dark:focus:ring-blue-400 w-full bg-transparent dark:border-zinc-700 rounded-md px-3 py-2 mb-4 autofill:bg-none mt-2" />
							<button
								onClick={(e) => {
									e.preventDefault();
									setShowPass(!showPass);
								}}
								className="absolute text-zinc-400 transition-colors hover:text-zinc-500 dark:text-zinc-600 dark:hover:text-zinc-500 top-4 right-2">
								{showPass ? (
									<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
										<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21" />
									</svg>
								) : (
									<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
										<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
										<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
									</svg>
								)}
							</button>
						</div>

						<label htmlFor="Wachtwoord">Wachtwoord opnieuw</label>
						<div className="relative">
							<input autoComplete="off" onKeyDown={_handleKeyDown} onChange={(e) => setPass2(e.target.value)} id="Wachtwoord" name="Wachtwoord" placeholder="Wachtwoord opnieuw" type={showPass ? "text" : "password"} className="border border-zinc-300 focus:outline-none focus:ring-1 focus:ring-blue-600 dark:focus:ring-blue-400 w-full bg-transparent dark:border-zinc-700 rounded-md px-3 py-2 mb-4 autofill:bg-none mt-2" />
							<button
								onClick={(e) => {
									e.preventDefault();
									setShowPass(!showPass);
								}}
								className="absolute text-zinc-400 transition-colors hover:text-zinc-500 dark:text-zinc-600 dark:hover:text-zinc-500 top-4 right-2">
								{showPass ? (
									<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
										<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21" />
									</svg>
								) : (
									<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
										<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
										<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
									</svg>
								)}
							</button>
						</div>
						<div className="mt-2 flex items-center">
							<input autoComplete="off" onClick={() => setPP(!pp)} className="form-check-input accent-blue-600 w-4 h-4 mr-2" type="checkbox" value="" id="Policy" />
							<label className="form-check-label inline-block" htmlFor="Policy">
								Ik ga akkoord met de{" "}
								<Link className="text-blue-600 dark:text-blue-400" to={"/policy"}>
									Privacy Policy
								</Link>
							</label>
						</div>
						<button onClick={() => handleRegisteer()} className="bg-blue-700 dark:bg-blue-500 hover:bg-blue-200 hover:text-blue-800 transition-colors text-center font-bold py-2 w-full text-white rounded-md mt-3 focus:outline-none focus:ring-1 focus:ring-blue-600 dark:focus:ring-blue-400 dark:hover:bg-blue-300 dark:hover:text-blue-800">
							REGISTREER
						</button>
					</div>
				</div>
			</div>
			
		</div>
	);
}

export default Registreer;
