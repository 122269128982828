import React from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import "./index.css";

//Main
import Home from "./pages/Home";
import Custom from "./pages/Custom";
import Registreer from "./pages/Registreer";
import Sponsors from "./pages/Sponsors";
import LoginPage from "./pages/Login";
import MijnGegevens from "./pages/mijnGegevens";

//Over-ons
import Praesidium from "./pages/over-ons/Praesidium";

// Evenementen
import Events from "./pages/events/Events";
import Event from "./pages/events/Event";

//PANEEL
import Paneel_Home from "./pages/paneel/Home";
import Paneel_Events from "./pages/paneel/Events";
import Paneel_Pages from "./pages/paneel/Pages";
import Paneel_Stem_Module from "./pages/paneel/Stem-module";
import Paneel_Cursusverkoop from "./pages/paneel/Cursusverkoop/main";
import Paneel_Cursusverkoop_Beheer from "./pages/paneel/Cursusverkoop/beheer";
import Paneel_Bestellingen from "./pages/paneel/Cursusverkoop/bestellingen";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// Kies Systeem
import Verkiezingen from "./pages/stemmen/Verkiezingen";
import Verkiezing from "./pages/stemmen/Verkiezing";

import config from "./config";
import Logout from "./auth/auth";

import { Toaster } from "react-hot-toast";
import Valideer from "./auth/valideer";
import Sponsor from "./pages/Sponsor";
import Footer from "./components/footer";
import Paneel_Verkiezing from "./pages/paneel/Verkiezing";
import Rondes from "./pages/stemmen/Rondes";
import ScrollToTop from "./components/ScrollToTop";
import CursusShop from "./pages/cursusverkoop/shop";
import Sport from "./pages/Sport";
import Kalender from "./pages/Kalender";
import Paneel_Lidkaarten from "./pages/paneel/Lidkaarten/Home";

import EventContextProvider from "./context/eventContext";
import Paneel_Gebruikers from "./pages/paneel/Gebruikers";
import SkiPunten from "./pages/SkiPunten";

if (axios.defaults.headers.common["Authorization"] === undefined) {
    if (!!localStorage.getItem("token")) axios.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
}

if (!!localStorage.getItem("refresh")) {
    let atobRefresh = atob(localStorage.getItem("refresh").split(".")[1]);
    let jsonRefresh = JSON.parse(atobRefresh);
    let now = new Date();
    let nowEpoch = Math.round(now.getTime() / 1000);
    if (nowEpoch >= jsonRefresh.exp) window.location.assign(config.base_url + "/logout");
}

axios.interceptors.response.use(
    (res) => {
        return res;
    },
    (error) => {
        /*		if(error?.message){
			console.log(error?.response)
		}*/
        if (
            //! only the access token expired should be passed through to refresh.
            error?.response?.status !== 401 ||
            (error?.response?.status === 401 && error?.response?.data?.code !== "token_not_valid") ||
            (error?.response?.status === 401 && error?.response?.data?.code === "token_not_valid" && error?.response?.data?.messages[0]?.token_type !== "access")
        ) {
            //console.log("hey")
            return new Promise((resolve, reject) => {
                reject(error);
            });
        }

        // The client thinks he is logged in
        if (localStorage.getItem("claims") !== null) {
            // Token has failed to refresh
            if (error.config.url === config.api_url + "/auth/token/refresh/") {
                //console.log('Clearing');
                localStorage.clear();
                //window.location = "/login";

                return new Promise((resolve, reject) => {
                    reject(error);
                });
            }

            // Refresh the token
            let fd = new FormData();
            fd.append("refresh", localStorage.getItem("refresh"));
            return axios
                .post(config.api_url + "/auth/token/refresh/", fd)
                .then((res) => {
                    // New request with new token
                    const config = error.config;

                    if (res.status === 200) {
                        let token = res.data.access;
                        localStorage.setItem("token", token);
                        let claim = token.split(".")[1];
                        let claims = atob(claim); //! Niet deprecated
                        delete claims.permissions;
                        localStorage.setItem("claims", claims);
                        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
                        config.headers["Authorization"] = "Bearer " + token;
                        //config = config
                        //config?.headers?.Authorization = "Bearer " + token;
                    }

                    return new Promise((resolve, reject) => {
                        axios
                            .request(config)

                            .then((response) => {
                                resolve(response);
                            })
                            .catch((error) => {
                                reject(error);
                            });
                    });
                })
                .catch((error) => {
                    Promise.reject(error);
                });
        }
    }
);

ReactDOM.render(
    <>
        <Toaster />
        <EventContextProvider>
            <Router>
                <ScrollToTop>
                    <Routes>
                        <Route exact path="/" element={<Home />} />

                        <Route exact path="/logout" element={<Logout />} />
                        <Route exact path="/registreer" element={<Registreer />} />
                        <Route exact path="/gevalideerd" element={<Home validated />} />
                        <Route exact path="/reeds-gevalideerd" element={<Home already_validated />} />
                        <Route exact path="/valideer/:code" element={<Valideer />} />
                        <Route exact path="/wachtwoord-vergeten" element={<Home forgot_pass />} />
                        <Route exact path="/reset-wachtwoord/:code" element={<Home change_pass />} />

                        <Route exact path="/praesidium" element={<Praesidium />} />
                        <Route exact path="/kalender" element={<Kalender />} />
                        <Route exact path="/kalender/:type" element={<Kalender />} />

                        <Route exact path="/algemeen" element={<Custom title="Algemeen" />} />
                        <Route exact path="/propraesidia" element={<Custom title="Propraesidia" extraClasses="prose-table:table-fixed" />} />
                        <Route exact path="/policy" element={<Custom title="Policy" />} />
                        <Route exact path="/kringlied" element={<Custom title="Kringlied" extraClasses="prose-p:leading-3" />} />
                        <Route exact path="/cursusverkoop" element={<Custom title="Cursusverkoop" />} />
                        <Route exact path="/contact" element={<Custom title="Contact" />} />
                        <Route exact path="/leden-voordeel" element={<Custom title="Leden voordeel" />} />
                        <Route exact path="/iaeste" element={<Custom title="Iaeste" />} />
                        <Route exact path="/engage" element={<Custom title="Engage" />} />
                        <Route exact path="/fris" element={<Custom title="Fris" />} />
                        <Route exact path="/studie" element={<Custom title="Studie" />} />
                        <Route exact path="/sport" element={<Sport />} />
                        <Route exact path="/skipunten" element={<SkiPunten />} />
                        <Route exact path="/statuten" element={<Custom title="Statuten" />} />
                        <Route exact path="/onthaaldag" element={<Custom title="Onthaaldag" />} />
                        <Route exact path="/toekomstige-indies" element={<Custom title="Toekomstige indies" />} />

                        <Route exact path="/events" element={<Events />} />
                        <Route exact path="/events/:type" element={<Events />} />
                        <Route exact path="/events/:type/:datum" element={<Events />} />
                        <Route exact path="/event/:naam/:id" element={<Event />} />

                        <Route exact path="/sponsors" element={<Sponsors />} />
                        <Route exact path="/sponsor/:naam/:id" element={<Sponsor />} />

                        <Route exact path="/mijn-gegevens/" element={<MijnGegevens />} />
                        <Route exact path="/login/" element={<LoginPage />} />

                        <Route exact path="/stem-module" element={<Verkiezingen />} />
                        <Route exact path="/stem-module/:naam/:id" element={<Verkiezing />} />
                        <Route exact path="/stem-module/rondes/:verkiezing/:id" element={<Rondes />} />

                        <Route exact path="/shop" element={<CursusShop />} />

                        {/* PANEEL */}
                        <Route exact path="/paneel" element={<Paneel_Home />} />
                        <Route exact path="/paneel/events" element={<Paneel_Events />} />
                        <Route exact path="/paneel/pages" element={<Paneel_Pages />} />
                        <Route exact path="/paneel/stem-module" element={<Paneel_Stem_Module />} />
                        <Route exact path="/paneel/stem-module/:naam/:id" element={<Paneel_Verkiezing />} />
                        <Route exact path="/paneel/shop" element={<Paneel_Cursusverkoop />} />
                        <Route exact path="/paneel/shop/beheer" element={<Paneel_Cursusverkoop_Beheer />} />
                        <Route exact path="/paneel/shop/bestellingen/:id" element={<Paneel_Bestellingen />} />
                        <Route exact path="/paneel/lidkaarten" element={<Paneel_Lidkaarten />} />
                        <Route exact path="/paneel/gebruikers" element={<Paneel_Gebruikers />} />
                    </Routes>
                    <Footer />
                </ScrollToTop>
            </Router>
        </EventContextProvider>
    </>,
    document.getElementById("root")
);
