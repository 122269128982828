import { Helmet } from "react-helmet";
import config from "../../config";
import Modal from "../../components/modal";
import Ronde from "../../components/Verkiezingen/Ronde";
import Results from "../../components/Verkiezingen/Results";
import { useEffect, useState } from "react";
import axios from "axios";
import { check_login } from "../../auth/auth";
import { useParams } from "react-router-dom";
import Navigation from "../../components/navigation";
import { useNavigate } from "react-router-dom";
import QRCode from "react-qr-code";
export default function Rondes(props) {
	const [rondeModal, setRondeModal] = useState([]);
	const [resultsModal, setResultsModal] = useState([]);
	const [qrModal, setQrModal] = useState(false);

	const [data, setData] = useState([]);
	const [claims, setClaims] = useState(null);
	let navigate = useNavigate();

	let id = useParams()?.id;
	let verkiezing = useParams()?.verkiezing;

	useEffect(() => {
		if (!!check_login(false)) {
			load();
		}
		let claims = JSON.parse(check_login(false));
		setClaims(claims);
	}, []);

	function load() {
		axios
			.get(config.api_url + "/electionmodule/rounds/?election=" + id + "&sort=-created")
			.then((response) => {
				setData(response.data.data);
			})
			.catch((err) => console.error(err));
	}

	return (
		<>
			{!!check_login(true) ? (
				<div className="dark:bg-zinc-900 py-8">
					<Helmet>
						<title>Stem Module - Hermes Gent</title>
						<meta name="description" content="Hermes Gent - Stem Module" />
					</Helmet>
					<Navigation />
					<div className="lg:w-8/12 w-11/12 pt-10 pb-40 mx-auto ">
						<div className="flex items-center">
							<div className="flex-grow flex gap-4">
								<button className="text-zinc-500 hover:text-zinc-900 transition-colors dark:text-zinc-300 dark:hover:text-zinc-600" onClick={() => navigate(-1)}>
									<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
										<path strokeLinecap="round" strokeLinejoin="round" d="M11 17l-5-5m0 0l5-5m-5 5h12" />
									</svg>
								</button>

								<h1 className="font-bold text-2xl lg:text-4xl uppercase text-zinc-800  dark:text-zinc-300">{verkiezing.replace("-", " ")}</h1>
							</div>
							<button onClick={() => load()} className="text-zinc-500 p-2 rounded-full flex items-center justify-center bg-zinc-200 dark:bg-black transition-colors hover:text-blue-500 ">
								<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
									<path strokeLinecap="round" strokeLinejoin="round" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
								</svg>
							</button>
						</div>
						<div>
							{data.length > 0 && !data[0]?.can_vote ? (
								<div className="bg-red-500 text-white px-4 py-4 rounded mt-4">
									U heeft momenteel geen stemrecht, contacteer de ICT en toon uw{" "}
									<Modal condition={qrModal} setCondition={setQrModal} boxClasses="w-full px-2 md:w-7/12 xl:w-5/12 md:mx-auto mt-8 lg:mt-32 mb-16" openButtonClasses="font-bold underline underline-offset-2" openButtonValue="QR code">
										<div className="my-12 flex items-center justify-center">
											{claims?.user_id ? (
												<div className="dark:bg-white dark:p-8 dark:rounded-lg">
													<QRCode value={claims?.user_id} />
												</div>
											) : (
												<></>
											)}
										</div>
									</Modal>
								</div>
							) : (
								<></>
							)}
						</div>
						<div className="grid gap-5 mt-8">
							{data.map((module) => {
								return (
									<>
										{module?.visible ? (
											<div className="shadow-md border border-zinc-200 p-4 lg:p-8 rounded-lg dark:bg-black/40 dark:border-zinc-800 flex flex-col md:flex-row md:justify-center text-gray-700 dark:text-gray-300">
												<div className="flex-grow">
													<h1 className="font-bold text-xl md:text-2xl xl:text-3xl">{module.title}</h1>
												</div>
												<div className="flex justify-end">
													{module.voting_open ? (
														<Modal uuid={module.id} condition={rondeModal} setCondition={setRondeModal} boxClasses="w-full px-2 md:w-7/12 xl:w-5/12 md:mx-auto mt-8 lg:mt-32 mb-16" openButtonClasses="bg-blue-700 py-2 px-6 text-white font-bold rounded-md hover:bg-blue-200 hover:text-blue-800 transition-colors w-fit" openButtonValue="Stem">
															<Ronde id={module?.id} reload={() => load()} verkiezing={props.verkiezing} rondeNaam={module.title} close={() => setRondeModal(rondeModal.filter((x) => x !== module.id))} />
														</Modal>
													) : (
														""
													)}
													{module.results_open ? (
														<Modal uuid={module.id} condition={rondeModal} setCondition={setRondeModal} boxClasses="w-full px-2 md:w-7/12 xl:w-5/12 md:mx-auto mt-8 lg:mt-32 mb-16" openButtonClasses="bg-black py-2 px-6 text-white font-bold rounded-md hover:bg-zinc-200 hover:text-zinc-800 transition-colors w-fit" openButtonValue="Bekijk resultaat">
															<Results id={module?.id} verkiezing={props.verkiezing} rondeNaam={module.title} close={() => setResultsModal(resultsModal.filter((x) => x !== module.id))} />
														</Modal>
													) : (
														""
													)}
													{!module.voting_open && !module.results_open ? <p className="text-zinc-400 dark:text-zinc-500 font-bold italic">Ronde gesloten</p> : ""}
												</div>
											</div>
										) : (
											""
										)}
									</>
								);
							})}
						</div>
					</div>
				</div>
			) : (
				""
			)}
		</>
	);
}
