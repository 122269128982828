import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { EVENT_TYPES } from "./Choices";
import { Link } from "react-router-dom";
function WeekKalender(props) {
    let now = new Date();
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);
    //console.log(now)

    let maandag = new Date();

    if (maandag.getDay() === 0) maandag.setDate(maandag.getDate() - 6);
    else maandag.setDate(maandag.getDate() - maandag.getDay() + 1);

    maandag.setHours(0);
    maandag.setMinutes(0);
    maandag.setSeconds(0);

    let zondag = new Date(maandag);
    zondag.setDate(zondag.getDate() + 7);

    const [min] = useState(maandag);
    const [max] = useState(zondag);
    const [week, setWeek] = useState(0);

    const DAYS = ["ZONDAG", "MAANDAG", "DINSDAG", "WOENSDAG", "DONDERDAG", "VRIJDAG", "ZATERDAG"];
    const NICE_WEEK = {
        "-1": "Vorige week",
        0: "Deze week",
        1: "Volgende week"
    };

    const [blocks, setBlocks] = useState([]);

    useEffect(() => {}, [props.events]);

    useEffect(() => {
        Filter();
    }, [week]);

    function buildBlocks(filtered) {
        //console.log(filtered);
        let minDate = new Date(min);
        //console.log(minDate);

        let buildBlocks = [];

        for (let i = 0; i < 7; i++) {
            let events_this_day = filtered.filter((event) => {
                return new Date(event.start).getDate() === minDate.getDate();
            });
            buildBlocks.push(
                <Link to={"/events/alle/" + minDate.getDate() + "-" + (minDate.getMonth() + 1) + "-" + minDate.getFullYear()} className={"flex flex-col items-center group text-white text-xs xl:text-base font-bold border border-blue-400 dark:border-blue-600 rounded-lg py-5 transition-all hover:bg-blue-400 dark:hover:bg-blue-800 lg:hover:scale-110 cursor-pointer px-1 " + (minDate.getFullYear() === now.getFullYear() && minDate.getMonth() === now.getMonth() && minDate.getDate() === now.getDate() ? "ring ring-blue-200 dark:ring-blue-400" : "")}>
                    <div className="text-blue-100">{DAYS[minDate.getDay()]}</div>
                    <div className="text-blue-200">{minDate.getDate() + "/" + (minDate.getMonth() + 1)}</div>
                    <div className="mt-5 text-center w-full px-12 xl:px-0 xl:w-fit">
                        {events_this_day.length === 0 ? (
                            ""
                        ) : (
                            <div>
                                {events_this_day.map((x) => {
                                    return (
                                        <div className="relative py-2 px-4 hover:z-30 bg-blue-400 transition-colors group-hover:ring-1 group-hover:ring-blue-200 dark:group-hover:ring-blue-700 dark:bg-blue-600 mt-2 rounded-md">
                                            <div style={{ backgroundColor: !EVENT_TYPES[x.type].no_back ? EVENT_TYPES[x.type].background_color : "", color: !EVENT_TYPES[x.type].no_back ? EVENT_TYPES[x.type].text_color : "" }} className="absolute -top-3 -right-3 h-8 w-8 flex items-center justify-center bg-blue-400 group-hover:ring-1 group-hover:ring-blue-200 rounded-full">
                                                {EVENT_TYPES[x.type].icon}
                                            </div>
                                            <span className="">{x?.title}</span>
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                </Link>
            );
            //console.log(buildBlocks)
            minDate.setDate(minDate.getDate() + 1);
        }
        setBlocks(buildBlocks);
    }

    function Filter() {
        let events = props.events;
        let filtered = [];
        let MIN = min;
        let MAX = max;

        for (let event of events) {
            let start = new Date(event.start);
            //console.log(event.title + ": " + start)
            if (start > MIN && start < MAX) filtered.push(event);
        }

        buildBlocks(filtered);
    }
    useEffect(() => {
        Filter();
    }, [props.events]);

    function nextWeek() {
        min.setDate(min.getDate() + 7);
        max.setDate(max.getDate() + 7);
        setWeek(week + 1);
    }
    function prevWeek() {
        min.setDate(min.getDate() - 7);
        max.setDate(max.getDate() - 7);

        setWeek(week - 1);
    }
    return (
        <div>
            <h1 className="font-extrabold text-4xl text-white mb-5 lg:mb-10 text-center uppercase">{NICE_WEEK[week] ? NICE_WEEK[week] : week < 0 ? Math.abs(week) + " weken geleden" : "binnen " + week + " weken"}</h1>
            <div className="flex flex-col lg:flex-row items-center justify-center w-full">
                <div className="mb-5 lg:mb-0">
                    <button onClick={() => prevWeek()} className="text-white transition-colors hover:text-blue-200">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
                        </svg>
                    </button>
                    <button onClick={() => nextWeek()} className="text-white lg:hidden transition-colors hover:text-blue-200">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                        </svg>
                    </button>
                </div>
                <div className="grid xl:grid-cols-7 gap-3 xl:flex-grow w-full">
                    {blocks.map((block) => {
                        return block;
                    })}
                </div>
                <button onClick={() => nextWeek()} className="text-white hidden lg:block transition-colors hover:text-blue-200">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                    </svg>
                </button>
            </div>
        </div>
    );
}

export default WeekKalender;
