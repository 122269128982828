import { Helmet } from "react-helmet";
import { useState, useEffect } from "react";
import config from "../../config";
import toast from "react-hot-toast";
import Notification from "../Notification";
import axios from "axios";
import { check_login } from "../../auth/auth";
export default function Ronde(props) {
	const [voted] = useState(false);
	const [data, setData] = useState([]);
	/*let data = [
		{
			naam: "Julien Fouquet",
			id: 1,
		},
		{
			naam: "Seppe Vermeulen",
			id: 2,
		},
		{
			naam: "Pieter Dewulf",
			id: 3,
		},
		{
			naam: "Blanco",
			id: 4,
		},
	];*/

	useEffect(() => {
		if (!!check_login(false)) {
			axios
				.get(config.api_url + "/electionmodule/choices/?round=" + props.id)
				.then((response) => {
					
					setData(response.data.data.sort((x, y) => x.weight > y.weight));
				})
				.catch((err) => console.error(err));
		}
	}, []);

	function error_noti(message) {
		toast.error(
			(t) => (
				<Notification toast={t}>
					<p>{message}</p>
				</Notification>
			),
			{
				duration: 4000,
				position: "top-right",
			}
		);
	}

	function sendVote() {
		let fd = new FormData();
		fd.append("round", choice?.round?.id);
		fd.append("choice", choice?.id);
		axios
			.patch(config.api_url + "/electionmodule/vote/", fd)
			.then(() => {
				toast.success(
					(t) => (
						<Notification toast={t}>
							<p>
								Succesvol gestemd op <span className="font-bold">{choice?.name}</span> voor {props.verkiezing} <span className="italic text-zinc-400">({props.rondeNaam})</span>
							</p>
						</Notification>
					),
					{
						duration: 4000,
						position: "top-right",
					}
				);
				props.reload()
			})
			.catch((err) => {
				let status = err?.response?.status;
				if (status === 406) {
					error_noti("U heeft reeds al gestemd voor deze ronde");
				}
				if (status === 403) {
					error_noti("U heeft geen stemrecht, contacteer de ICT");
				}
				if (status === 400) {
					error_noti("Deze ronde is niet open om te stemmen");
				}
				console.error(err);
			});
		props.close();
	}

	const [choice, setChoice] = useState(null);

	return (
		<>
			{!!check_login(true) ? (
				<div className="dark:bg-zinc-900 py-8 md:p-8">
					<Helmet>
						<title>Stem Module - Hermes Gent</title>
						<meta name="description" content="Hermes Gent - Stem Module" />
					</Helmet>

					<h1 className="font-bold text-2xl lg:text-4xl uppercase text-zinc-800  dark:text-zinc-300">{props.rondeNaam}</h1>
					{!voted ? (
						<div className="grid gap-5 mt-8">
							{data.map((module) => {
								return (
									<div className={"shadow-md border border-zinc-200 p-4 rounded-lg dark:bg-black/40 dark:border-zinc-800 flex flex-row " + (module.id === choice?.id ? "ring-1 ring-blue-600" : "")}>
										<h1 className="font-bold text-xl md:text-2xl xl:text-3xl text-gray-700 dark:text-gray-300 flex-grow">{module.name}</h1>
										{module.id !== choice?.id && (
											<button onClick={() => setChoice(module)} className="bg-blue-700 py-2 px-6 text-white font-bold rounded-md hover:bg-blue-200 hover:text-blue-800 transition-colors w-fit">
												Kies
											</button>
										)}
									</div>
								);
							})}

							{choice ? (
								<div className="text-zinc-700 dark:text-zinc-300">
									U selecteerde: <span className="font-bold">{choice.name}</span>
									<button onClick={() => sendVote()} className="mt-8 bg-blue-700 py-2 px-6 text-white font-bold rounded-md hover:bg-blue-200 hover:text-blue-800 transition-colors w-fit block">
										Stem bevestigen
									</button>
								</div>
							) : (
								""
							)}
						</div>
					) : (
						<div className="text-zinc-700 dark:text-zinc-300">U stem werd goed genoteerd</div>
					)}
				</div>
			) : (
				""
			)}
		</>
	);
}
