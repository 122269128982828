//import axios from "axios";
import React, { useEffect, useState } from "react";
//import config from "../config";
import { EVENT_TYPES } from "./Choices";
import { v4 as uuidv4 } from "uuid";
import { Link } from "react-router-dom";
function Calendar(props) {
    let days = ["MA", "DI", "WOE", "DO", "VR", "ZA", "ZO"];
    let months = ["JANUARI", "FEBRUARI", "MAART", "APRIL", "MEI", "JUNI", "JULI", "AUGUSTUS", "SEPTEMBER", "OKTOBER", "NOVEMBER", "DECEMBER"];

    const [date, setDate] = useState(new Date(new Date().setDate(1)));
    //const [today, setToday] = useState(new Date());

    const [events, setEvents] = useState({});

    useEffect(() => {
        let getData = props.events;
        let pushData = {};
        for (let i = 0; i < getData.length; i++) {
            let showDate = new Date(getData[i].start);
            showDate.setHours(0);
            showDate.setMinutes(0);
            showDate.setSeconds(0);
            if (pushData[showDate] === undefined) {
                pushData[showDate] = [];
            }
            pushData[showDate] = [...pushData[showDate], getData[i]];
        }
        setEvents(pushData);
    }, [props.events]);

    useEffect(() => {
        renderDays();
    }, [events]);

    const [thisMonth, setThisMonth] = useState({
        lastDay: 31,
        month: 0,
        year: 2022,
        offset: 3
    });

    useEffect(() => {
        let lastDayDate = new Date(date);
        lastDayDate.setMonth(date.getMonth() + 1);
        lastDayDate.setDate(0);
        let lastDay = lastDayDate.getDate();

        let eventDate = new Date("2022-01-06");

        setThisMonth({
            month: date.getMonth(),
            year: date.getFullYear(),
            lastDay: lastDay,
            offset: date.getDay() === 0 ? 7 : date.getDay()
        });
    }, [date]);

    useEffect(() => {
        renderDays();
    }, [thisMonth]);

    function setNext() {
        let newDate = new Date(date);
        newDate.setMonth(date.getMonth() + 1);
        setDate(newDate);
    }

    function setPrev() {
        let newDate = new Date(date);
        newDate.setMonth(date.getMonth() - 1);
        setDate(newDate);
    }

    const [dagen, setDagen] = useState([]);
    function renderDays() {
        let days = [];
        let Today = new Date();
        Today.setHours(0);
        Today.setMinutes(0);
        Today.setSeconds(0);

        for (let i = 1; i < thisMonth.offset; i++) {
            days.push(<div className="h-14"></div>);
        }
        for (let i = 1; i <= thisMonth.lastDay; i++) {
            let thisDate = new Date(date);
            thisDate.setDate(i);
            thisDate.setHours(0);
            thisDate.setMinutes(0);
            thisDate.setSeconds(0);

            if (events[thisDate]) {
                let thisDateEvents = events[thisDate];
                days.push(
                    <Link to={"/events/alle/" + thisDate.getDate() + "-" + (thisDate.getMonth() + 1) + "-" + thisDate.getFullYear()} className={"h-16 flex flex-col justify-center items-center text-white text-xs xl:text-base font-bold border border-blue-400 dark:border-blue-600 rounded-lg transition-transform hover:bg-blue-400 dark:hover:bg-blue-800 lg:hover:scale-110 px-1 " + (thisDate.toDateString() === Today.toDateString() ? "border-blue-200 dark:border-blue-300" : "")}>
                        <div className="text-white font-bold">{i}</div>
                        <div className="flex flex-row gap-1">
                            <Link style={{ backgroundColor: !EVENT_TYPES[thisDateEvents[0].type].no_back ? EVENT_TYPES[thisDateEvents[0].type].background_color : "", color: !EVENT_TYPES[thisDateEvents[0].type].no_back ? EVENT_TYPES[thisDateEvents[0].type].text_color : "" }} to={"/events/alle/" + thisDate.getDate() + "-" + (thisDate.getMonth() + 1) + "-" + thisDate.getFullYear()} className={"w-6 h-6 group flex items-center justify-center " + (!EVENT_TYPES[thisDateEvents[0].type].no_back ? "rounded text-xs p-1" : "text-lg")}>
                                {EVENT_TYPES[thisDateEvents[0].type].icon}
                                <div className="hidden md:group-hover:flex absolute z-50 top-16 bg-zinc-800 rounded text-white w-36 items-center justify-center p-2">
                                    <h2 className="text-center text-xs font-bold">{thisDateEvents[0].title}</h2>
                                    <div className="w-3 h-3 bg-zinc-800 rounded absolute -top-1 rotate-45"></div>
                                </div>
                            </Link>
                            {thisDateEvents.length > 1 ? (
                                <Link style={{ backgroundColor: !EVENT_TYPES[thisDateEvents[1].type].no_back ? EVENT_TYPES[thisDateEvents[1].type].background_color : "", color: !EVENT_TYPES[thisDateEvents[1].type].no_back ? EVENT_TYPES[thisDateEvents[1].type].text_color : "" }} to={"/events/alle/" + thisDate.getDate() + "-" + (thisDate.getMonth() + 1) + "-" + thisDate.getFullYear()} className={"w-6 h-6 group hidden md:flex items-center justify-center " + (!EVENT_TYPES[thisDateEvents[1].type].no_back ? "rounded text-xs p-1" : "text-lg")}>
                                    {EVENT_TYPES[thisDateEvents[1].type].icon}
                                    <div className="hidden md:group-hover:flex absolute z-50 top-16 bg-zinc-800 rounded text-white w-36 items-center justify-center p-2">
                                        <h2 className="text-center text-xs font-bold">{thisDateEvents[1].title}</h2>
                                        <div className="w-3 h-3 bg-zinc-800 rounded absolute -top-1 rotate-45"></div>
                                    </div>
                                </Link>
                            ) : null}
                            {thisDateEvents.length > 2 ? (
                                <Link style={{ backgroundColor: !EVENT_TYPES[thisDateEvents[2].type].no_back ? EVENT_TYPES[thisDateEvents[2].type].background_color : "", color: !EVENT_TYPES[thisDateEvents[2].type].no_back ? EVENT_TYPES[thisDateEvents[2].type].text_color : "" }} to={"/events/alle/" + thisDate.getDate() + "-" + (thisDate.getMonth() + 1) + "-" + thisDate.getFullYear()} className={"w-6 h-6 group hidden lg:flex items-center justify-center " + (!EVENT_TYPES[thisDateEvents[2].type].no_back ? "rounded text-xs p-1" : "text-lg")}>
                                    {EVENT_TYPES[thisDateEvents[2].type].icon}
                                    <div className="hidden md:group-hover:flex absolute z-50 top-16 bg-zinc-800 rounded text-white w-36 items-center justify-center p-2">
                                        <h2 className="text-center text-xs font-bold">{thisDateEvents[2].title}</h2>
                                        <div className="w-3 h-3 bg-zinc-800 rounded absolute -top-1 rotate-45"></div>
                                    </div>
                                </Link>
                            ) : null}
                            {thisDateEvents.length > 3 ? (
                                <Link style={{ backgroundColor: !EVENT_TYPES[thisDateEvents[3].type].no_back ? EVENT_TYPES[thisDateEvents[3].type].background_color : "", color: !EVENT_TYPES[thisDateEvents[3].type].no_back ? EVENT_TYPES[thisDateEvents[3].type].text_color : "" }} to={"/events/alle/" + thisDate.getDate() + "-" + (thisDate.getMonth() + 1) + "-" + thisDate.getFullYear()} className={"w-6 h-6 group hidden xl:flex items-center justify-center " + (!EVENT_TYPES[thisDateEvents[3].type].no_back ? "rounded text-xs p-1" : "text-lg")}>
                                    {EVENT_TYPES[thisDateEvents[3].type].icon}
                                    <div className="hidden md:group-hover:flex absolute z-50 top-16 bg-zinc-800 rounded text-white w-36 items-center justify-center p-2">
                                        <h2 className="text-center text-xs font-bold">{thisDateEvents[3].title}</h2>
                                        <div className="w-3 h-3 bg-zinc-800 rounded absolute -top-1 rotate-45"></div>
                                    </div>
                                </Link>
                            ) : null}
                        </div>
                    </Link>
                );
            } else {
                days.push(
                    <div className={"h-16 flex flex-col justify-center items-center text-white text-xs xl:text-base font-bold border border-blue-400 dark:border-blue-600 rounded-lg transition-transform hover:bg-blue-400 dark:hover:bg-blue-800 lg:hover:scale-110 px-1 " + (thisDate.toDateString() === Today.toDateString() ? "border-blue-200 dark:border-blue-300" : "")}>
                        <div>{i}</div>
                    </div>
                );
            }
        }

        setDagen(days);
    }

    return (
        <div className="mt-5">
            <div className="flex border border-blue-400 dark:border-blue-600 py-3 rounded-lg place-items-center">
                <button onClick={setPrev} className="text-white transition-colors hover:text-blue-200 ml-5 font-bold text-xl flex justify-center items-start">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
                    </svg>
                </button>
                <div className="text-center text-white font-bold text-xl flex-grow">
                    {months[date.getMonth()]} - {[date.getFullYear()]}
                </div>
                <button onClick={setNext} className="text-center mr-5 text-white transition-colors hover:text-blue-200 font-bold text-xl  flex justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                    </svg>
                </button>
            </div>
            <div className="grid grid-cols-7 gap-1 md:gap-2 mt-3">
                {days.map((day) => {
                    let uuid = uuidv4();
                    return (
                        <div key={uuid} className="text-white flex items-center justify-center font-bold mb-1">
                            {day}
                        </div>
                    );
                })}
                {dagen.map((dag) => {
                    let uuid = uuidv4();
                    return <div key={uuid}>{dag}</div>;
                })}
            </div>
            <div>
                <h2 className="text-white text-xl uppercase font-bold mt-4">Legende:</h2>
                <div className="grid grid-cols-2 sm:flex mt-2 gap-2 sm:flex-row flex-wrap">
                    {Object.values(EVENT_TYPES).map((type) => {
                        return (
                            <div key={type.id} className="flex items-center mr-6 ">
                                <div
                                    style={{
                                        backgroundColor: !type.no_back ? type.background_color : null,
                                        color: !type.no_back ? type.text_color : null
                                    }}
                                    className={"w-6 h-6 flex items-center justify-center " + (!type.no_back ? "rounded text-xs font-bold p-1" : "text-lg")}
                                >
                                    {type.icon}
                                </div>
                                <div className="ml-2 text-white uppercase font-bold">{type.name}</div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}

export default Calendar;
