import React from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { STYLE } from "../modules/styles";
interface Props {
    className?: string;
    aos_type?: string;
    aos_duration?: string;
    aos_delay?: number;
    foto?: string;
    titel?: string;
    type?: string;
    onderschrift?: React.ReactNode;
    text?: string;
    knop?: React.ReactNode;
    link: string;
}

function Card({ className, aos_type, aos_duration, aos_delay, foto, titel, type, onderschrift, text, knop, link }: Props) {
    useEffect(() => {
        Aos.init();
    });
    return (
        <Link to={link} data-aos={aos_type} data-aos-duration={aos_duration} data-aos-delay={aos_delay} className={"shadow flex flex-col rounded-lg overflow-hidden relative bg-white dark:bg-black/40 " + className}>
            <img className="object-cover h-44 w-full" src={foto} alt="" />
            <div className="p-6 flex-grow flex flex-col relative">
                {type ? <div className="absolute -top-4 right-4 bg-blue-700 text-white rounded-full text-xs px-4 py-2 font-bold ring-4 ring-white dark:ring-zinc-900">{type}</div> : ""}
                <div className="text-blue-600 font-bold dark:text-blue-400">{titel}</div>
                <div className="text-zinc-500 italic mb-3 dark:text-zinc-500">{onderschrift}</div>
                <p className="text-gray-700 dark:text-gray-300 flex-grow">{text}</p>
                <div className="w-full flex justify-end mt-8">
                    <div className={`${STYLE.DEFAULT_BUTTON}`}>{knop}</div>
                </div>
            </div>
        </Link>
    );
}

export default Card;
