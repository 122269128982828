import Aos from "aos";
import "aos/dist/aos.css";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
// @ts-ignore
import Calendar from "../components/calendar";
// @ts-ignore
import Card from "../components/Card";
// @ts-ignore
import DateFormat from "../components/dateFormater";
// @ts-ignore
import Navigation from "../components/navigation";
// @ts-ignore
import WeekKalender from "../components/weekKalender";
// @ts-ignore
import config from "../config";
// @ts-ignore
import { EVENT_TYPES } from "../components/Choices";

import Notification from "../components/Notification";
import toast from "react-hot-toast";
//import Logout from "../auth/auth";
import Modal from "../components/modal";
import { Link, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import React from "react";
import { Event } from "../modules/types";
import { eventContextData } from "../context/eventContext";
import Loading from "../components/Loading";

type Props = {
    change_pass: boolean;
    forgot_pass: boolean;
    validated: boolean;
    already_validated: boolean;
};
function Home({ change_pass, forgot_pass, validated, already_validated }: Props) {
    useEffect(() => {
        Aos.init();
    });

    const eventContext = useContext(eventContextData);

    let now = new Date();
    const [allEvents, setAllEvents] = useState<Event[]>([]);
    const [events, setEvents] = useState<Event[]>();
    const [spotlightLoading, setSpotlightLoading] = useState(true);

    const [forgotPassModal, setForgotPassModal] = useState(!!forgot_pass ? true : false);
    const [forgotPassMail, setForgotPassMail] = useState("");

    const [changePassModal, setChangePassModal] = useState(!!change_pass ? true : false);
    const [changePass1, setChangePass1] = useState("");
    const [changePass2, setChangePass2] = useState("");
    const [changePassView, setChangePassView] = useState(false);

    useEffect(() => {
        let data: Event[] = eventContext.data;
        let pushData: Event[] = [];

        data.forEach((thisEvent) => {
            if (new Date(thisEvent.end) > now && thisEvent.spotlight) {
                pushData.push(thisEvent);
            }
        });

        /*for (let i = 0; i < data.length; i++) {
            let thisEvent = data[i];

            if (new Date(thisEvent.end) > now && thisEvent.spotlight) {
                pushData.push(thisEvent);
            }
        }*/
        setEvents(pushData);
        setAllEvents(data);
        setSpotlightLoading(eventContext.loading);
    }, [eventContext]);

    useEffect(() => {
        if (validated) {
            toast.success(
                (t) => (
                    <Notification toast={t}>
                        <p>Accounts succesvol geverifieerd, u kan nu inloggen!</p>
                    </Notification>
                ),
                {
                    duration: 4000,
                    position: "top-right"
                }
            );
        }
        if (already_validated) {
            toast(
                (t) => (
                    <Notification toast={t}>
                        <p>Accounts was reeds al geverifieerd!</p>
                    </Notification>
                ),
                {
                    duration: 4000,
                    position: "top-right",
                    icon: "🔔"
                }
            );
        }
    }, []);

    function sendPassResetMail() {
        let fd = new FormData();
        fd.append("email", forgotPassMail);
        axios
            .post(config.api_url + "/auth/reset-password/request/", fd)
            .then(() => {
                toast.success(
                    (t) => (
                        <Notification toast={t}>
                            <p>
                                Mail om uw wachtwoord te wijzigen is verzonden naar <span className="font-bold">{forgotPassMail}</span>
                            </p>
                        </Notification>
                    ),
                    {
                        duration: 4000,
                        position: "top-right"
                    }
                );
                setForgotPassModal(false);
            })
            .catch((error) => {
                toast.error(
                    (t) => (
                        <Notification toast={t}>
                            <p>Verzenden van mail mislukt, controleer uw ingevoerd E-mailadres.</p>
                        </Notification>
                    ),
                    {
                        duration: 4000,
                        position: "top-right"
                    }
                );
            });
        //setForgotPassModal(false);
    }
    let code = useParams().code || "";
    function updatePass() {
        if (!changePass1) {
            error(<p>Nieuw wachtwoord is niet ingevuld</p>);
            return;
        }
        if (!changePass2) {
            error(<p>Nieuw wachtwoord herhalen is niet ingevuld</p>);
            return;
        }
        if (changePass1 !== changePass2) {
            error(<p>Wachtwoorden komen niet overeen</p>);
            return;
        }
        if (changePass1.length < 8 || changePass2.length < 8) {
            error(
                <p>
                    Wachtwoord is te kort <span className="italic">(minimaal 8 characters)</span>
                </p>
            );
            return;
        }
        let fd = new FormData();
        fd.append("token", code);
        fd.append("password", changePass1);
        fd.append("password2", changePass2);
        axios
            .patch(config.api_url + "/auth/reset-password/", fd)
            .then(() => {
                toast.success(
                    (t) => (
                        <Notification toast={t}>
                            <p>Wachtwoord succesvol aangepast, u kan nu inloggen met het nieuwe wachtwoord!</p>
                        </Notification>
                    ),
                    {
                        duration: 4000,
                        position: "top-right"
                    }
                );
                setChangePassModal(false);
            })
            .catch((error) => {
                toast.error(
                    (t) => (
                        <Notification toast={t}>
                            <p>
                                Wachtwoord wijzigen mislukt. <br />
                                <span className="font-bold">error: </span>
                                {error?.message}
                            </p>
                        </Notification>
                    ),
                    {
                        duration: 4000,
                        position: "top-right"
                    }
                );
            });
    }

    function error(message: JSX.Element) {
        toast.error((t) => <Notification toast={t}>{message}</Notification>, {
            duration: 4000,
            position: "top-right"
        });
    }

    const [calenderView, setCalenderView] = useState("calender" in localStorage && localStorage.calender === "true");

    function updateCalenderView() {
        localStorage.calender = !calenderView;
        setCalenderView(!calenderView);
    }

    const [cursusVerkoopShort, setCursusVerkoopShort] = useState<string>();

    useEffect(() => {
        axios
            .get(config.api_url + "/pages/?name=" + "Cursusinfo")
            .then((result) => {
                setCursusVerkoopShort(result.data.data[0].page);
            })
            .catch(() => {});
    }, []);

    return (
        <div data-aos-easing="ease-out-back" className="dark:bg-zinc-900">
            <Helmet>
                <title>Hermes Gent vzw</title>
                <meta name="description" content="Hermes Gent - De studentenvereniging van de Industrieel Ingenieurs aan de UGent" />
            </Helmet>

            <Navigation />

            <Modal condition={forgotPassModal} setCondition={setForgotPassModal} openButtonValue="" openButtonClasses="hidden" boxClasses="w-full px-2 md:w-11/12 lg:w-7/12 xl:w-5/12 md:mx-auto mt-8 lg:mt-24 mb-16">
                <div className="p-4 lg:p-8 text-zinc-800 dark:text-zinc-200">
                    <h2 className="text-center text-2xl font-bold pb-6">
                        WACHTWOORD VERGETEN<span className="text-blue-700 dark:text-blue-300">.</span>
                    </h2>
                    <p>Geef uw E-mailadres van uw account op en we sturen een mail om je wachtwoord te resetten:</p>
                    <input
                        onKeyDown={(e) => e.key === "Enter" && sendPassResetMail()}
                        onChange={(e) => setForgotPassMail(e.target.value)}
                        id="forgottenPassMail"
                        name="forgottenPassMail"
                        placeholder="E-mailadres"
                        type="email"
                        className="border border-zinc-300 focus:outline-none focus:ring-1 focus:ring-blue-600 dark:focus:ring-blue-400 w-full bg-transparent dark:border-zinc-700 rounded-md px-3 py-2 mb-4 autofill:bg-none"
                    />
                    <br />
                    <button onClick={() => sendPassResetMail()} className="bg-blue-700 dark:bg-blue-500 text-center font-bold py-2 w-full text-white rounded-md mt-3 focus:outline-none focus:ring-1 focus:ring-blue-600 dark:focus:ring-blue-400">
                        Verstuur mail
                    </button>
                </div>
            </Modal>
            <Modal condition={changePassModal} setCondition={setChangePassModal} openButtonValue="" openButtonClasses="hidden" boxClasses="w-full px-2 md:w-11/12 lg:w-7/12 xl:w-5/12 md:mx-auto mt-8 lg:mt-24 mb-16">
                <div className="p-4 lg:p-8 text-zinc-800 dark:text-zinc-200">
                    <h2 className="text-center text-2xl font-bold pb-6">
                        WACHTWOORD AANPASSEN<span className="text-blue-700 dark:text-blue-300">.</span>
                    </h2>
                    <label htmlFor="Wachtwoord">
                        Nieuw wachtwoord <span className="italic text-zinc-400 dark:text-zinc-500">(minimaal 8 characters)</span>
                    </label>
                    <div className="relative">
                        <input
                            onKeyDown={(e) => e.key === "Enter" && updatePass()}
                            onChange={(e) => setChangePass1(e.target.value)}
                            id="Wachtwoord"
                            name="Wachtwoord"
                            placeholder="Wachtwoord"
                            type={changePassView ? "text" : "password"}
                            className="border border-zinc-300 focus:outline-none focus:ring-1 focus:ring-blue-600 dark:focus:ring-blue-400 w-full bg-transparent dark:border-zinc-700 rounded-md px-3 py-2 mb-4 autofill:bg-none mt-2"
                        />
                        <button
                            onClick={(e) => {
                                e.preventDefault();
                                setChangePassView(!changePassView);
                            }}
                            className="absolute text-zinc-400 transition-colors hover:text-zinc-500 dark:text-zinc-600 dark:hover:text-zinc-500 top-4 right-2">
                            {changePassView ? (
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                                    />
                                </svg>
                            ) : (
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                </svg>
                            )}
                        </button>
                    </div>

                    <label htmlFor="Wachtwoord">Nieuw wachtwoord opnieuw</label>
                    <div className="relative">
                        <input
                            onKeyDown={(e) => e.key === "Enter" && updatePass()}
                            onChange={(e) => setChangePass2(e.target.value)}
                            id="Wachtwoord"
                            name="Wachtwoord"
                            placeholder="Wachtwoord opnieuw"
                            type={changePassView ? "text" : "password"}
                            className="border border-zinc-300 focus:outline-none focus:ring-1 focus:ring-blue-600 dark:focus:ring-blue-400 w-full bg-transparent dark:border-zinc-700 rounded-md px-3 py-2 mb-4 autofill:bg-none mt-2"
                        />
                        <button
                            onClick={(e) => {
                                e.preventDefault();
                                setChangePassView(!changePassView);
                            }}
                            className="absolute text-zinc-400 transition-colors hover:text-zinc-500 dark:text-zinc-600 dark:hover:text-zinc-500 top-4 right-2">
                            {changePassView ? (
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                                    />
                                </svg>
                            ) : (
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                </svg>
                            )}
                        </button>
                    </div>
                    <button onClick={() => updatePass()} className="bg-blue-700 dark:bg-blue-500 text-center font-bold py-2 w-full text-white rounded-md mt-3 focus:outline-none focus:ring-1 focus:ring-blue-600 dark:focus:ring-blue-400">
                        Pas wachtwoord aan
                    </button>
                </div>
            </Modal>

            <div className="w-full h-screen bg-blue-600 relative hidden md:block">
                <img className="w-full h-screen object-cover" src={config.base_url + "/images/cover.jpg"} alt="Hermes" />
                <div className="absolute top-0 left-0 w-full h-screen  flex-col justify-center items-center hidden">
                    <a className="px-8 py-4 border-2 border-white text-white font-bold uppercase rounded bg-black/60" href={config.base_url + "/registreer"}>
                        INLOGGEN
                    </a>
                    <hr className="border-2 border-white w-96 my-6 shadow" />
                    <a className="px-8 py-4 border-2 border-white text-white font-bold uppercase rounded bg-black/60" href={config.base_url + "/registreer"}>
                        Maak account aan
                    </a>
                </div>
                <Link to={"/#down"} className="absolute bottom-40 left-1/2 -translate-x-1/2 w-20 h-20 flex items-center justify-center text-white bg-black/60 border-4 border-white rounded-full transition-transform hover:scale-110">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M17 13l-5 5m0 0l-5-5m5 5V6" />
                    </svg>
                </Link>
            </div>

            <div id="down" className="bg-blue-100/10 dark:bg-zinc-900 scroll-m-24">
                {!!events && events.length ? (
                    <div className="lg:w-8/12 w-11/12 pt-20 pb-40 mx-auto grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-3">
                        <h1 data-aos="fade-up" data-aos-duration="800" className="col-span-full font-extrabold text-4xl text-blue-700 dark:text-blue-400 mb-5">
                            ACTIVITEITEN IN DE KIJKER
                        </h1>

                        {events?.map((event, index) => {
                            return (
                                <Card
                                    key={index}
                                    aos_type="fade-up"
                                    aos_duration="800"
                                    aos_delay={(index % 3) * 100}
                                    foto={event.image}
                                    titel={event.title}
                                    onderschrift={<DateFormat date={event.start} format={"$WD$ $d$ $MM$"} trySpecial={true} styles="text-zinc-600 dark:text-zinc-400 italic capitalize" />}
                                    text={event.short_desc}
                                    // @ts-ignore
                                    type={EVENT_TYPES[event.type].name}
                                    link={"/event/" + event.title.replace(/\s+/g, "-").toLowerCase() + "/" + event.id}
                                    knop={
                                        <>
                                            MEER INFO
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 inline-block ml-2 group-hover:text-blue-500" viewBox="0 0 20 20" fill="currentColor">
                                                <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                                            </svg>
                                        </>
                                    }
                                />
                            );
                        })}
                    </div>
                ) : (
                    spotlightLoading && (
                        <div className="pt-8 flex justify-center">
                            <Loading />
                        </div>
                    )
                )}
            </div>

            <div id="kalender" data-aos="fade-up" aos-data-duration="800" className="bg-blue-500 dark:bg-blue-900 scroll-mt-24">
                <div className={"w-11/12 pt-20 pb-40 mx-auto relative " + (!calenderView ? "lg:w-10/12" : "lg:w-8/12")}>
                    <button
                        className="absolute top-8 right-0 text-white text-xs xl:text-base font-bold border border-blue-400 dark:border-blue-600 rounded-md transition-transform hover:bg-blue-400 dark:hover:bg-blue-800 lg:hover:scale-110 cursor-pointer px-6 py-2"
                        onClick={() => updateCalenderView()}>
                        {calenderView ? "Week" : "Maand"}
                    </button>
                    {calenderView ? <Calendar events={allEvents} /> : <WeekKalender events={allEvents} />}
                    <div className="flex justify-center">
                        <a
                            href="https://calendar.google.com/calendar/ical/c_f8b0fd26a2e34b9bc3bc3b8e326c348dad029cc005873fba5b6805715933a171%40group.calendar.google.com/public/basic.ics"
                            target="_BLANK"
                            className="flex gap-3 text-white font-bold px-3 py-2 rounded-md border-2 border-white w-fit mt-8 transition-all hover:opacity-80 active:scale-105">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
                            </svg>
                            DOWNLOAD ICAL
                        </a>
                    </div>
                </div>
            </div>

            <div className="bg-white py-30 dark:bg-zinc-900">
                <div className="lg:w-8/12 w-11/12 pt-20 pb-40 mx-auto grid gap-8 md:grid-cols-2">
                    <div data-aos="fade-up" data-aos-duration="800" className="shadow p-12 rounded-lg dark:bg-black/40 border border-zinc-200 dark:border-zinc-800">
                        <h1 className="font-extrabold text-2xl md:text-3xl xl:text-4xl text-gray-700 dark:text-gray-300 mb-10 text-center">CURSUSVERKOOP</h1>
                        <div dangerouslySetInnerHTML={{ __html: cursusVerkoopShort || "" }} className={"prose prose-zinc dark:prose-invert max-w-full prose-a:no-underline leading-3 prose-a:text-blue-700 dark:prose-a:text-blue-400 prose-h2:text-3xl"}></div>
                    </div>
                    <div data-aos="fade-up" data-aos-duration="800" data-aos-delay="200" className="shadow p-12 rounded-lg flex flex-col dark:bg-black/40 border border-zinc-200 dark:border-zinc-800">
                        <h1 className="font-extrabold text-2xl md:text-3xl xl:text-4xl text-gray-700 dark:text-gray-300 mb-10 text-center">RECRUITMENT SITE</h1>
                        <div className="flex justify-center items-center flex-grow">
                            <a href="https://recruitment.hermesgent.be" className="max-w-[18em]">
                                <img src={config.base_url + "/images/hr.png"} alt="Hermes Recruitment" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;
