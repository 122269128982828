export const EVENT_TYPES = {
    spo: {
        name: "Sport",
        icon: "⚽️",
        background_color: "#000",
        text_color: "#fff",
        no_back: true
    },
    cul: {
        name: "Cultuur",
        icon: "🎭",
        background_color: "#000",
        text_color: "#fff",
        no_back: true
    },
    rec: {
        name: "Recruitment",
        icon: "👔",
        background_color: "#000",
        text_color: "#fff",
        no_back: true
    },
    clu: {
        name: "Kringavond",
        icon: "💃",
        background_color: "#000",
        text_color: "#fff",
        no_back: true
    },
    bic: {
        name: "Bickystandje",
        icon: "🍔",
        background_color: "#000",
        text_color: "#fff",
        no_back: true
    },
    can: {
        name: "Cantus",
        icon: "🍺",
        background_color: "#000",
        text_color: "#fff",
        no_back: true
    },
    fea: {
        name: "FEA",
        icon: "🏫",
        background_color: "#0c610c",
        text_color: "#fff",
        no_back: true
    },
    fri: {
        name: "Fris",
        icon: "FR",
        background_color: "#0c610c",
        text_color: "#fff",
        no_back: false
    },

    and: {
        name: "Andere",
        icon: "📅",
        background_color: "#000",
        text_color: "#fff",
        no_back: true
    }
};

export const TYPE_TO_OTHER: any = {
    kringavond: { short: "clu", long: "kringavonden" },
    sport: { short: "spo", long: "sport activiteiten" },
    cultuur: { short: "cul", long: "cultuur activiteiten" },
    cantus: { short: "can", long: "cantussen" },
    recruitment: { short: "rec", long: "recruitment events" },

    fea: { short: "fea", long: "FEA activiteiten" },
    fris: { short: "fri", long: "fris activiteiten" },
    andere: { short: "and", long: "andere activiteiten" }
};

export const PRAESIDIUM_FUNCTIES = {
    praeses: "Praeses",
    "co-voorzitter": "Co-Voorzitter",
    "vice-voorzitter": "Vice-Voorzitter",
    vice: "Vice-Praeses",
    penning: "Penning",
    schachtentemmer: "Schachtentemmer",
    recruitment: "Recruitment",
    sport: "Sport",
    secretaris: "Secretaris",
    pr: "PR",
    event: "Event",
    cursus: "Cursus",
    zedenmeester: "Zedenmeester",
    ict: "ICT",
    cantor: "Cantor",
    interne: "Interne",
    lustrum: "Lustrum"
};

export const GEBRUIKERS_ROLLEN = {
    nul: "Geen",
    sch: "Schacht",
    com: "Commi"
};

export const CURSUSVERKOOP_ITEM_TYPES = {
    boek: {
        enkelvoud: "Boek",
        meervoud: "Boeken"
    },
    cursus: {
        enkelvoud: "Cursus",
        meervoud: "Cursussen"
    },
    voorwerp: {
        enkelvoud: "Voorwerp",
        meervoud: "Voorwerpen"
    },
    pakket: {
        enkelvoud: "Pakket",
        meervoud: "Pakketten"
    },
    andere: {
        enkelvoud: "Andere",
        meervoud: "Andere"
    }
};
