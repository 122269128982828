import axios from "axios";
import { Link, useParams } from "react-router-dom";
import config from "../config";
import Logout from "./auth";
import toast from "react-hot-toast";
import Notification from "../components/Notification";
import { useEffect, useState } from "react";
import Modal from "../components/modal";
export default function Valideer() {
	let code = useParams().code;
	const [resendModal, setResendModal] = useState(false);
	const [mail, setMail] = useState("");
	useEffect(() => {
		let fd = new FormData();
		fd.append("register_token", code);
		
		Logout(false);
		//console.log(atob(code.split(".")[1]))
		axios
			.patch(config.api_url + "/auth/verificate/", fd)
			.then(() => {
				document.location.assign(config.base_url + "/gevalideerd");
			})
			.catch((err) => {
				console.log(err.response);
				if (err?.response?.data?.code === "user_not_found") {
					document.location.assign(config.base_url);
					return;
				}
				if (err?.response?.data?.code === "user_verified") {
					document.location.assign(config.base_url + "/reeds-gevalideerd");
					return;
				}
				toast.error(
					(t) => (
						<Notification toast={t}>
							<p>Valideren van account mislukt!</p>
						</Notification>
					),
					{
						duration: 4000,
						position: "top-right",
					}
				);
				try {
					let atobed = atob(code.split(".")[1]);
					let parsed = JSON.parse(atobed);
					let email = parsed.email;
					setMail(email);
					setResendModal(true);
					let fd = new FormData();
					fd.append("email", email);
					axios
						.patch(config.api_url + "/auth/verificate/resend/", fd)
						.then(() => {
							toast.success(
								(t) => (
									<Notification toast={t}>
										<p>Mail om uw account te verifiëren is opnieuw verzonden!</p>
									</Notification>
								),
								{
									duration: Infinity,
									position: "top-right",
								}
							);
						})
						.catch((error) => {
							//console.log(error?.response)
							if (error?.response?.data?.email[0] === "User is already verified") document.location.assign(config.base_url + "/reeds-gevalideerd");
							else
								toast.error(
									(t) => (
										<Notification toast={t}>
											<p>
												Mail om uw account te verifiëren verzonden mislukt! neem contact op met de ICT
												<br /> <span className="font-bold">error: </span>
												{error?.message}
											</p>
										</Notification>
									),
									{
										duration: Infinity,
										position: "top-right",
									}
								);
						});
				} catch {
					toast.error(
						(t) => (
							<Notification toast={t}>
								<p>Er is iets fout gelopen, neem contact op met de ICT's!</p>
							</Notification>
						),
						{
							duration: Infinity,
							position: "top-right",
						}
					);
				}
			});
	}, []);

	return (
		<>
			<div className="w-full min-h-screen bg-blue-600 flex flex-col items-center justify-center">
				<Modal condition={resendModal} setCondition={setResendModal} openButtonValue="LOGIN" openButtonClasses="hidden" boxClasses="w-full px-2 lg:w-7/12 xl:w-5/12 mx-auto mt-8 lg:mt-24 mb-16">
					<div className="pt-8">
						Het account <span className="font-bold">({mail})</span> kon niet worden geverifeerd omdat de link niet meer geldig is. Er wordt automatisch een nieuwe mail verstuurd naar dit E-mailadres om je account alsnog te verifiëren.
					</div>
				</Modal>
				<img className="w-10/12 max-w-xs" src={config.base_url + "/images/HermesWhite.svg"} alt="" />
				<Link to={config.base_url} className="text-white/70 transition-colors hover:text-white font-normal mt-4 text-xl italic">Terug naar home</Link>
			</div>
		</>
	);
}
