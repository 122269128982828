import { useState } from "react";
import CustomSelect from "../../../customSelect";
import MultiSelect from "../../../MultiSelect";
import { CURSUSVERKOOP_ITEM_TYPES } from "../../../Choices";
export default function ItemToevoegen() {
    const [naam, setNaam] = useState();
    const [prijs, setPrijs] = useState();
    const [ledenPrijs, setLedenPrijs] = useState();
    const [type, setType] = useState({ id: -1, value: "Kies een type..." });
    const [richtingen, setRichtingen] = useState([]);
    const types = [];

    let i = 0;
    for (const [key, value] of Object.entries(CURSUSVERKOOP_ITEM_TYPES)) {
        types.push({ id: i, name: key, value: value.enkelvoud });
        i++;
    }
    const richtingenOpties = [
        { id: 1, value: "1e Bachelor Industrieel Ingenieur" },
        { id: 2, value: "2e Bachelor Industrieel Ingenieur: Bouwkunde" },
        { id: 3, value: "2e Bachelor Industrieel Ingenieur: Elektromechanica" },
        { id: 4, value: "2e Bachelor Industrieel Ingenieur: Chemie" },
        { id: 5, value: "2e Bachelor Industrieel Ingenieur: Elektronica ICT" },
        { id: 6, value: "2e Bachelor Industrieel Ingenieur: Informatica" },
        { id: 7, value: "3e Bachelor Industrieel Ingenieur: Informatica" },
        { id: 8, value: "1e Master Industrieel Ingenieur: Informatica" }
    ];

    function removeRichting(id) {
        setRichtingen(richtingen.filter((richting) => richting.id !== id));
    }

    function itemToevoegen() {
        console.log("Item toevoegen");
    }

    return (
        <div className="text-zinc-600 dark:text-zinc-400 md:p-4">
            <h2 className="text-center text-2xl font-bold">Item Toevoegen</h2>
            <div className="mt-4 grid md:grid-cols-2 gap-4">
                <div>
                    <label htmlFor="naam" className="text-lg font-light">
                        Item naam
                    </label>
                    <input onChange={(e) => setNaam(e.target.value)} id="naam" type="text" placeholder="Item naam..." className="flex items-center w-full border border-zinc-300 focus:outline-none focus:ring-1 focus:ring-blue-600 bg-transparent dark:border-zinc-700 px-3 py-2 dark:text-zinc-400 rounded-md " />
                </div>
                <div>
                    <label className="text-lg font-light">Type</label>
                    <div className="">
                        <CustomSelect chosen={type} setChosen={setType} options={types} placeholder="Kies een type..." />
                    </div>
                </div>
                <div>
                    <label htmlFor="prijs" className="text-lg font-light">
                        Prijs
                    </label>
                    <div className="flex items-center">
                        <div className="py-2 border border-zinc-300 rounded-l-md w-10 flex items-center justify-center font-bold dark:border-zinc-700">€</div>
                        <input onChange={(e) => setPrijs(e.target.value)} min={0} step={0.01} id="prijs" type="number" placeholder="Prijs..." className="flex-grow border border-zinc-300 focus:outline-none focus:ring-1 focus:ring-blue-600 bg-transparent dark:border-zinc-700 px-3 py-2 dark:text-zinc-400 rounded-r-md" />
                    </div>
                </div>
                <div>
                    <label htmlFor="ledenprijs" className="text-lg font-light">
                        Ledenprijs
                    </label>
                    <div className="flex items-center ">
                        <div className="py-2 border border-zinc-300 rounded-l-md w-10 flex items-center justify-center font-bold dark:border-zinc-700">€</div>
                        <input onChange={(e) => setLedenPrijs(e.target.value)} min={0} step={0.01} id="ledenprijs" type="number" placeholder="Ledenprijs..." className="flex-grow border border-zinc-300 focus:outline-none focus:ring-1 focus:ring-blue-600 bg-transparent dark:border-zinc-700 px-3 py-2 dark:text-zinc-400 rounded-r-md" />
                    </div>
                </div>
                <div className="md:col-span-2">
                    <label className="text-lg font-light">Richtingen</label>
                    <div className="">
                        <MultiSelect selected={richtingen} onChange={setRichtingen} options={richtingenOpties} placeholder="Selecteer richtingen..." />
                        <div className="w-full h-32 bg-zinc-100 dark:bg-black flex flex-col overflow-y-scroll rounded-md mt-2">
                            {richtingen.map((richting, index) => {
                                return (
                                    <div className={"flex py-2 px-4 text-zinc-800 dark:text-zinc-200 items-center " + (index % 2 ? "bg-zinc-200 dark:bg-zinc-800" : "")} key={richting.id}>
                                        <div className="flex-grow">{richting.value}</div>
                                        <button onClick={() => removeRichting(richting.id)} className="text-zinc-400 dark:text-zinc-500 transition-colors hover:text-zinc-700 dark:hover:text-zinc-200">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                                <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                                            </svg>
                                        </button>
                                    </div>
                                );
                            })}

                            {richtingen.length < 1 && <div className="py-4 px-4">Geen richtingen geselecteerd voor dit item...</div>}
                        </div>
                    </div>
                </div>
                <div className="md:col-span-2 flex items-center gap-2">
                    <input autoComplete="off" className="form-check-input accent-blue-600 w-4 h-4" type="checkbox" value="" id="optional" />
                    <label className="form-check-label inline-block" htmlFor="optional">
                        Optioneel item
                    </label>
                </div>
            </div>
            <button onClick={() => itemToevoegen()} className="bg-blue-700 text-md px-6 py-2 text-white font-bold rounded-md mt-8 hover:bg-blue-200 hover:text-blue-800 transition-colors flex items-center">
                Toevoegen
            </button>
        </div>
    );
}
