import { useState } from "react";
import Notification from "../../Notification";
import toast from "react-hot-toast";
import { check_login } from "../../../auth/auth";
import { check_permission } from "../../../auth/auth";
import axios from "axios";
import config from "../../../config";
export default function VerkiezingsdagToevoegen(props) {
	const [title, setTitle] = useState("");
	const [date, setDate] = useState();
	const [claims] = useState(check_login(true));
	const [allowed] = useState(check_permission("ict", true));

	function voegToe() {
		let formData = new FormData();
		formData.append("name", title);
		formData.append("date", date);
		axios
			.post(config.api_url + "/electionmodule/electiondays/", formData)
			.then(() => {
				toast.success(
					(t) => (
						<Notification toast={t}>
							<p>
								Toevoegen van verkiezingsdag <span className="font-bold">{title}</span> succesvol!
							</p>
						</Notification>
					),
					{
						duration: 4000,
						position: "top-right",
					}
				);
				props.close();
				props.reload();
			})
			.catch((error) => {
				toast.error(
					(t) => (
						<Notification toast={t}>
							<p>
								Aanmaken van <span className="font-bold">{title}</span> mislukt!
								<span className="italic text-zinc-400"> ({error.message})</span>
							</p>
						</Notification>
					),
					{
						duration: 4000,
						position: "top-right",
					}
				);
			});
	}
	return (
		<>
			{claims && allowed && (
				<div className="mt-12 px-2 lg:px-8 mb-6">
					<h1 className="font-bold text-2xl uppercase text-zinc-800  dark:text-zinc-300">VERKIEZINGSDAG TOEVOEGEN</h1>
					<div className="mt-4 text-zinc-800 dark:text-zinc-200">
						<label htmlFor="name">Naam van verkiezingsdag</label>
						<input onKeyDown={(e) => e.key === "Enter" && voegToe()} onChange={(e) => setTitle(e.target.value)} id="name" name="name" placeholder="Naam van verkiezingsdag" type="text" className="border mt-2 border-zinc-300 focus:outline-none focus:ring-1 focus:ring-blue-600 dark:focus:ring-blue-400 bg-transparent dark:border-zinc-700 w-full rounded-md px-3 py-2 mb-4 autofill:bg-none" />
						<label htmlFor="date">Datum</label>
						<input onKeyDown={(e) => e.key === "Enter" && voegToe()} onChange={(e) => setDate(e.target.value)} id="date" name="date" placeholder="Datum van verkiezingsdag" type="date" className="border mt-2 border-zinc-300 focus:outline-none focus:ring-1 focus:ring-blue-600 dark:focus:ring-blue-400 bg-transparent dark:border-zinc-700 w-full rounded-md px-3 py-2 mb-4 autofill:bg-none" />
						<button onClick={(e) => voegToe(e)} className="bg-blue-700 group py-2 px-6 text-white font-bold rounded-md hover:bg-blue-200 hover:text-blue-800 transition-colors mt-4">
							VOEG TOE
						</button>
					</div>
				</div>
			)}
		</>
	);
}
