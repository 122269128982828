import axios from "axios";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { check_login, check_permission } from "../../auth/auth";

import CustomSelect from "../../components/customSelect";
import Loading from "../../components/Loading";
import Modal from "../../components/modal";

import GebruikerBewerken from "../../components/Paneel/Gebruikers/gebruikerBewerken";
import PaneelNavigatie from "../../components/Paneel/paneelNavigatie";
import QrCodeScanner from "../../components/Paneel/QrCodeScanner";

import config from "../../config";
import useDebounce from "../../hooks/useDebounce";

import { _ } from "../../modules/functions";
import { Gebruiker, qrError, qrResult } from "../../modules/types";
export default function Paneel_Gebruikers() {
    const [claims] = useState(check_login(true));
    const [allowed] = useState(check_permission("praesidium", true));
    const [loading, setLoading] = useState(true);
    const [gebruikers, setGebruikers] = useState<Gebruiker[]>([]);
    const [gefilterdeGebruikers, setGefilterdeGebruikers] = useState<Gebruiker[]>([]);

    const [gebruikerModal, setGebruikerModal] = useState<string[]>([]);

    const [naamFilter, setNaamFilter] = useState("");
    const [alphaFilter, setAlphaFilter] = useState(true);
    const [joinDateFilter, setJoinDateFilter] = useState(true);
    const [filter, setFilter] = useState<{ id: string; value: string }>({ id: "all", value: "Iedereen tonen" });
    const [useAlphaFilter, setUseAlphaFilter] = useState(true);
    const [qrModal, setQrModal] = useState(false);
    const filterOpties = [
        { id: "all", value: "Iedereen tonen" },
        { id: "nul", value: "Geen rol" },
        { id: "com", value: "Commi's" },
        { id: "sch", value: "Schachten" },
        { id: "stem", value: "Mag stemmen" },
        { id: "geen_stem", value: "Mag niet stemmen" },
        { id: "meer_dan_1_stem", value: ">1 Stem" }
    ];

    useEffect(() => {
        load();
    }, []);
    function load() {
        setLoading(true);
        axios.get(`${config.api_url}/users/`).then((response) => {
            let data: Gebruiker[] = response.data.data;
            setLoading(false);
            setGebruikers(data);
            setGefilterdeGebruikers(data);
        });
    }

    useDebounce(
        () => {
            if (gebruikers) {
                let filtered: Gebruiker[] = gebruikers;

                if (filter.id === "nul") filtered = filtered.filter((x) => x.rol === "nul");
                if (filter.id === "com") filtered = filtered.filter((x) => x.rol === "com");
                if (filter.id === "sch") filtered = filtered.filter((x) => x.rol === "sch");
                if (filter.id === "stem") filtered = filtered.filter((x) => x.can_vote);
                if (filter.id === "geen_stem") filtered = filtered.filter((x) => !x.can_vote);
                if (filter.id === "meer_dan_1_stem") filtered = filtered.filter((x) => x.votes > 1);

                filtered = filtered.filter((x) => _.STRING_CONTAINS(x.first_name + x.last_name + x.email, naamFilter));

                if (useAlphaFilter) {
                    if (alphaFilter === true) {
                        // Sort the array in alphabetical order based on the first_name attribute
                        filtered.sort((a, b) => a.first_name.localeCompare(b.first_name));
                    } else {
                        // Sort the array in reverse alphabetical order based on the first_name attribute
                        filtered.sort((a, b) => b.first_name.localeCompare(a.first_name));
                    }
                } else {
                    if (joinDateFilter) {
                        // Sort the array in ascending order based on the date_joined attribute
                        filtered.sort((a, b) => new Date(b.date_joined).getTime() - new Date(a.date_joined).getTime());
                    } else {
                        // Sort the array in descending order based on the date_joined attribute
                        filtered.sort((a, b) => new Date(a.date_joined).getTime() - new Date(b.date_joined).getTime());
                    }
                }

                setGefilterdeGebruikers(filtered);
                setLoading(false);
            }
        },
        500,
        [gebruikers, naamFilter, alphaFilter, joinDateFilter, filter]
    );

    useEffect(() => {
        setLoading(true);
    }, [gebruikers, naamFilter, alphaFilter, joinDateFilter, filter]);

    function qrScanned(result: qrResult, error: qrError) {
        if (result) {
            setQrModal(false);
            let gebruiker = gebruikers.find((x) => x.id === result.getText());
            setNaamFilter(gebruiker?.first_name + " " + gebruiker?.last_name);
        }
    }

    return (
        <>
            {claims && allowed && (
                <>
                    <div className="dark:bg-zinc-900">
                        <PaneelNavigatie />
                        <Helmet>
                            <title>Paneel - Gebruikers - Hermes Gent</title>
                            <meta name="description" content="Hermes Gent Gebruikers" />
                        </Helmet>
                        <div className="lg:w-8/12 w-11/12 pt-20 pb-40 mx-auto">
                            <div className="flex items-center gap-4">
                                <h1 className="font-bold text-4xl uppercase text-zinc-800  dark:text-zinc-300 flex-grow">Gebruikers {gefilterdeGebruikers.length > 0 && `- ${gefilterdeGebruikers.length}`}</h1>
                                <button onClick={() => load()} className={"text-zinc-600 dark:text-zinc-400"}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                                    </svg>
                                </button>
                            </div>
                            <div className="text-zinc-800 dark:text-zinc-300 mt-8 shadow p-4 lg:p-8 rounded-lg dark:bg-black/70">
                                <div className="flex flex-col gap-3 mb-4">
                                    <div className="flex gap-3 items-center">
                                        <input
                                            value={naamFilter}
                                            onChange={(e) => setNaamFilter(e.target.value)}
                                            type="text"
                                            id="naam"
                                            placeholder="Naam van gebruiker"
                                            className="w-full border border-zinc-300 h-11 focus:outline-none focus:ring-1 focus:ring-blue-600 bg-transparent dark:border-zinc-700 px-3 dark:text-zinc-400 rounded-md"
                                        />

                                        <Modal
                                            condition={qrModal}
                                            setCondition={setQrModal}
                                            boxClasses="w-full md:w-8/12 xl:w-5/12 md:mx-auto mt-12 lg:mt-36 mb-16"
                                            openButtonClasses="w-12 h-11 rounded border border-zinc-300 flex items-center justify-center text-zinc-600 dark:border-zinc-700 dark:text-zinc-400"
                                            openButtonValue={
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" className="w-6 h-6">
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M3.75 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 013.75 9.375v-4.5zM3.75 14.625c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5a1.125 1.125 0 01-1.125-1.125v-4.5zM13.5 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 0113.5 9.375v-4.5z"
                                                    />
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M6.75 6.75h.75v.75h-.75v-.75zM6.75 16.5h.75v.75h-.75v-.75zM16.5 6.75h.75v.75h-.75v-.75zM13.5 13.5h.75v.75h-.75v-.75zM13.5 19.5h.75v.75h-.75v-.75zM19.5 13.5h.75v.75h-.75v-.75zM19.5 19.5h.75v.75h-.75v-.75zM16.5 16.5h.75v.75h-.75v-.75z"
                                                    />
                                                </svg>
                                            }>
                                            <QrCodeScanner scanned={qrScanned} />
                                        </Modal>
                                    </div>

                                    <div className="flex flex-col sm:flex-row sm:items-center gap-3">
                                        <div className="w-full">
                                            {/*@ts-ignore*/}
                                            <CustomSelect chosen={filter} setChosen={setFilter} options={filterOpties} />
                                        </div>
                                        <div className="flex items-center gap-3">
                                            <button
                                                onClick={() => {
                                                    setAlphaFilter((old) => !old);
                                                    setUseAlphaFilter(true);
                                                }}
                                                className={`px-2 py-1 text-zinc-600 dark:text-zinc-400 border  border-zinc-300 dark:border-zinc-700 rounded flex items-center gap-2 ${useAlphaFilter && "ring-2 ring-blue-500"}`}>
                                                Az
                                                <svg className={`w-4 h-4 ${alphaFilter && "rotate-180"} transition-transform`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 19.5v-15m0 0l-6.75 6.75M12 4.5l6.75 6.75" />
                                                </svg>
                                            </button>

                                            <button
                                                onClick={() => {
                                                    setJoinDateFilter((old) => !old);
                                                    setUseAlphaFilter(false);
                                                }}
                                                className={`px-2 py-1 text-zinc-600 dark:text-zinc-400 border border-zinc-300 dark:border-zinc-700 rounded flex items-center gap-2 ${!useAlphaFilter && "ring-2 ring-blue-500"}`}>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                </svg>

                                                <svg className={`w-4 h-4 ${joinDateFilter && "rotate-180"} transition-transform`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 19.5v-15m0 0l-6.75 6.75M12 4.5l6.75 6.75" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                {!loading && (
                                    <>
                                        {gefilterdeGebruikers?.length === 0 && <div className="text-zinc-400 dark:text-zinc-600">Geen gebruikers die aan deze voorwaarden voldoen.</div>}
                                        <div className="px-1 md:px-2 py-1 h-96 overflow-y-scroll">
                                            {gefilterdeGebruikers?.map((gebruiker) => (
                                                <div key={gebruiker.id} className="mt-2 shadow py-4 px-4 lg:px-6 rounded-md dark:bg-zinc-900 flex gap-2 flex-col sm:flex-row lg:gap-2 items-center">
                                                    <div className="flex items-center gap-2 flex-grow">
                                                        <div className="w-7 h-7 rounded-md bg-blue-200 text-blue-700 font-bold dark:bg-blue-600 dark:text-zinc-200 flex items-center justify-center">{gebruiker.first_name.toUpperCase().slice(0, 1)}</div>
                                                        <div className="">{gebruiker.first_name + " " + gebruiker.last_name}</div>
                                                        {gebruiker.rol === "sch" && <div className="text-xs px-2 py-1 bg-orange-500 text-white rounded font-semibold">Schacht</div>}
                                                        {gebruiker.rol === "com" && <div className="text-xs px-2 py-1 bg-blue-500 text-white rounded font-semibold">Commi</div>}
                                                    </div>

                                                    <div className="flex flex-col sm:flex-row items-center gap-2 sm:gap-4">
                                                        <div className={`text-xs uppercase font-bold ${gebruiker.can_vote ? "text-emerald-500 dark:text-emerald-300" : "text-red-500 dark:text-red-300"}`}>
                                                            {gebruiker.votes === 0 ? "Geen Stemmen" : gebruiker.votes > 1 ? gebruiker.votes + " Stemmen" : "1 Stem"}
                                                        </div>

                                                        <Modal
                                                            condition={gebruikerModal}
                                                            setCondition={setGebruikerModal}
                                                            uuid={gebruiker.id}
                                                            boxClasses="w-full md:w-8/12 xl:w-5/12 md:mx-auto mt-12 lg:mt-36 mb-16"
                                                            openButtonClasses="px-4 py-2 text-sm bg-blue-700 text-white rounded-md font-bold"
                                                            openButtonValue={"OPEN"}>
                                                            <GebruikerBewerken updateGebruikers={setGebruikers} close={() => setGebruikerModal(gebruikerModal.filter((x) => x !== gebruiker.id))} reload={() => load()} gebruiker={gebruiker} />
                                                        </Modal>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </>
                                )}
                                {loading && (
                                    <div className="text-zinc-500 flex items-center mt-4 gap-2">
                                        <Loading size={5} />
                                        Gebruikers inladen...
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
}
