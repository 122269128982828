import React, { useEffect, useState } from "react";
import { Switch } from "@headlessui/react";
import { Gebruiker } from "../../../modules/types";
import { STYLE } from "../../../modules/styles";
import axios from "axios";
import config from "../../../config";
import toast from "react-hot-toast";

import Notification from "../../Notification";
import { GEBRUIKERS_ROLLEN } from "../../Choices";
import CustomSelect from "../../customSelect";
import DateFormat from "../../dateFormater";
interface Props {
    gebruiker: Gebruiker;
    close: () => void;
    reload: () => void;
    updateGebruikers: React.Dispatch<React.SetStateAction<Gebruiker[]>>;
}

export default function GebruikerBewerken({ gebruiker, close, reload, updateGebruikers }: Props) {
    const [canVote, setCanVote] = useState(gebruiker.can_vote);
    const [votes, setVotes] = useState(gebruiker.votes);
    const [rol, setRol] = useState({ id: gebruiker.rol, value: GEBRUIKERS_ROLLEN[gebruiker.rol] });
    const [rollen, setRollen] = useState<{ id: string; value: string }[]>([]);

    useEffect(() => {
        let data = [];
        for (const [key, value] of Object.entries(GEBRUIKERS_ROLLEN)) {
            data.push({ id: key, value: value });
        }
        setRollen(data);
        console.log(gebruiker);
    }, []);

    function bewerk() {
        let data = new FormData();
        data.append("can_vote", JSON.stringify(canVote));
        data.append("votes", JSON.stringify(votes));
        data.append("rol", rol.id);

        axios
            .patch(config.api_url + "/users/" + gebruiker.id, data)
            .then(() => {
                close();
                gebruiker.can_vote = canVote;
                gebruiker.votes = votes;
                gebruiker.rol = rol.id;
                updateGebruikers((old) =>
                    old?.map((x) => {
                        if (x.id === gebruiker.id) return gebruiker;
                        else return x;
                    })
                );
                toast.success(
                    (t) => (
                        <Notification toast={t}>
                            <div>Bewerken van {gebruiker.first_name} succesvol!</div>
                        </Notification>
                    ),
                    { position: "top-right" }
                );
            })
            .catch((error) => {
                console.log(error);
                toast.error(
                    (t) => (
                        <Notification toast={t}>
                            <div>
                                Bewerken van {gebruiker.first_name} mislukt: {error.message}!
                            </div>
                        </Notification>
                    ),
                    { position: "top-right" }
                );
            });
    }

    return (
        <div className="lg:p-4 my-4 text-zinc-600 dark:text-zinc-200">
            <h1 className="font-bold text-2xl uppercase text-zinc-700  dark:text-zinc-300 flex-grow">{gebruiker.first_name} bewerken</h1>
            <div className="text-zinc-400 italic text-sm dark:text-zinc-600">
                Account aangemaakt op: <DateFormat date={gebruiker.date_joined} trySpecial format="$d$/$m$/$y$" />
            </div>
            <div className="mt-4 flex flex-col gap-4">
                <div>
                    <h2 className="mb-2">Email</h2>
                    <input
                        value={gebruiker.email}
                        disabled={true}
                        type="text"
                        className="w-full border border-zinc-300 focus:outline-none focus:ring-1 focus:ring-blue-600 bg-transparent dark:border-zinc-700 px-3 py-2 dark:text-zinc-400 rounded-md disabled:bg-zinc-100 disabled:text-zinc-500 disabled:dark:bg-zinc-800"
                    />
                </div>
                <div>
                    <h2 className="mb-2">Gebruikersrol</h2>
                    {/* @ts-ignore */}
                    <CustomSelect options={rollen} chosen={rol} setChosen={setRol} />
                </div>
                <div>
                    <h2 className="mb-2">Stem module - Stemrecht / Aantal</h2>
                    <div className="flex items-center gap-4">
                        <Switch checked={canVote} onChange={setCanVote} className={`${canVote ? "bg-blue-600" : "bg-gray-200 dark:bg-zinc-700"} relative inline-flex items-center h-6 rounded-full w-12`}>
                            <span className={`${canVote ? "translate-x-6" : "translate-x-1"} transition-transform inline-block w-4 h-4 transform bg-white rounded-full`} />
                        </Switch>

                        <input
                            onKeyDown={(e) => e.key === "Enter" && bewerk()}
                            value={votes}
                            disabled={!canVote}
                            onChange={(e) => setVotes(parseInt(e.target.value))}
                            type="number"
                            id="votes"
                            placeholder="Naam van gebruiker"
                            className="w-full border border-zinc-300 focus:outline-none focus:ring-1 focus:ring-blue-600 bg-transparent dark:border-zinc-700 px-3 py-2 dark:text-zinc-400 rounded-md disabled:bg-zinc-100 disabled:text-zinc-500 disabled:dark:bg-zinc-800"
                        />
                    </div>
                </div>

                <button onClick={() => bewerk()} className={STYLE.DEFAULT_BUTTON + " mt-4 w-full"}>
                    Bewerken
                </button>
            </div>
        </div>
    );
}
