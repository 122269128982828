import Navigation from "../../components/navigation";

import { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import config from "../../config";
import axios from "axios";
import Card from "../../components/Card";
import DateFormat from "../../components/dateFormater";
import { Helmet } from "react-helmet";
import { EVENT_TYPES } from "../../components/Choices";
import { TYPE_TO_OTHER } from "../../components/Choices";
import { Menu } from "@headlessui/react";
import Calendar from "../../components/calendar";
import { eventContextData } from "../../context/eventContext";
function Events(props) {
    const eventContext = useContext(eventContextData);

    let now = new Date();
    let datum = useParams().datum;
    let type = useParams().type?.toLowerCase();
    let date = new Date();

    let params = { approved: true, sort: "start" };

    if (type && type !== "alle") params["type"] = TYPE_TO_OTHER[type].short;
    if (datum) {
        date = new Date(datum.split("-")[1] + "/" + datum.split("-")[0] + "/" + datum.split("-")[2]);

        let min = new Date(date);
        min.setHours(0);
        min.setMinutes(0);
        min.setSeconds(0);
        let minString = min.getFullYear() + "-" + (min.getMonth() + 1 < 10 ? "0" + (min.getMonth() + 1) : min.getMonth() + 1) + "-" + (min.getDate() < 10 ? "0" + min.getDate() : min.getDate()) + "T00:00:00";
        let max = new Date(min);
        max.setDate(min.getDate() + 1);
        let maxString = max.getFullYear() + "-" + (max.getMonth() + 1 < 10 ? "0" + (max.getMonth() + 1) : max.getMonth() + 1) + "-" + (max.getDate() < 10 ? "0" + max.getDate() : max.getDate()) + "T00:00:00";

        params["start_gte"] = minString;
        params["start_lte"] = maxString;
        params["start_lt"] = "";
        params["start_gt"] = "";
    }
    const [events, setEvents] = useState([]);
    const [allEvents, setAllEvents] = useState([]);
    const [afgelopenEvents, setAfgelopenEvents] = useState([]);
    useEffect(() => {
        Render();
    }, [datum, type, eventContext]);

    function Render() {
        if (!datum) {
            let ev = [];
            let aev = [];

            let data = eventContext.data;
            if (params.type) {
                data = data.filter((event) => {
                    return event.type === params.type;
                });
            }
            setAllEvents(data);
            for (let i = 0; i < data.length; i++) {
                let e = data[i];
                let e_end = new Date(e.end);
                if (e_end > now) {
                    ev.push(e);
                } else {
                    aev.push(e);
                }
            }
            ev.sort((a, b) => {
                return new Date(a.start) - new Date(b.start);
            });
            aev.sort((a, b) => {
                return new Date(b.end) - new Date(a.end);
            });
            setEvents(ev);
            //setAfgelopenEvents(aev);
        } else {
            let min = new Date(date);
            min.setHours(0);
            min.setMinutes(0);
            min.setSeconds(0);
            console.log(min);
            let minString = min.getFullYear() + "-" + (min.getMonth() + 1 < 10 ? "0" + (min.getMonth() + 1) : min.getMonth() + 1) + "-" + (min.getDate() < 10 ? "0" + min.getDate() : min.getDate()) + "T00:00:00";
            let max = new Date(min);
            max.setDate(min.getDate() + 1);
            let maxString = max.getFullYear() + "-" + (max.getMonth() + 1 < 10 ? "0" + (max.getMonth() + 1) : max.getMonth() + 1) + "-" + (max.getDate() < 10 ? "0" + max.getDate() : max.getDate()) + "T00:00:00";

            let data = eventContext.data.filter((event) => {
                return min <= new Date(event.start) && new Date(event.start) <= max;
            });
            setEvents(data);
        }
    }

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    return (
        <div className="dark:bg-zinc-900">
            <Navigation />
            <Helmet>
                <title>{type && type !== "alle" ? capitalizeFirstLetter(TYPE_TO_OTHER[type].long) : "Activiteiten"} - Hermes Gent</title>
                <meta name="description" content="Hermes Gent Cursusverkoop" />
            </Helmet>

            <div className="lg:w-8/12 w-11/12 pt-20 pb-40 mx-auto grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-3">
                {!datum ? (
                    <div className="col-span-full flex md:items-center flex-col md:flex-row">
                        <h1 data-aos="fade-up" data-aos-duration="800" className="font-extrabold flex-grow text-lg md:text-xl lg:text-3xl text-blue-700 dark:text-blue-400 mb-5">
                            AANKOMENDE {!!type && type !== "alle" ? TYPE_TO_OTHER[type].long?.toUpperCase() : "ACTIVITEITEN"}
                        </h1>
                        <Menu as="div" className="relative self-end">
                            {({ open }) => (
                                <>
                                    <Menu.Button
                                        className={`uppercase  font-semibold px-3 py-2 border-2 rounded-md dark:hover:text-blue-300 transition-colors hover:text-blue-600 hover:border-blue-700 dark:hover:border-blue-300 flex items-center ${
                                            open ? "border-blue-600 dark:border-blue-300 text-blue-600 dark:text-blue-300" : "border-zinc-500 dark:border-zinc-400 text-zinc-500 dark:text-zinc-300"
                                        }`}>
                                        {!!type && type !== "alle" ? TYPE_TO_OTHER[type].long.toUpperCase() : "ALLE ACTIVITEITEN"}
                                        <svg xmlns="http://www.w3.org/2000/svg" className={"h-5 w-5 transition-transform " + (open ? "rotate-180" : "")} viewBox="0 0 20 20" fill="currentColor">
                                            <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                                        </svg>
                                    </Menu.Button>
                                    <Menu.Items className="absolute w-64 top-12 z-10 right-0 p-4 text-left bg-white shadow-md border border-zinc-200 dark:border-zinc-800 dark:bg-zinc-800 text-zinc-500 dark:text-zinc-400 rounded-lg flex flex-col focus:outline-none">
                                        <div className="absolute w-2 h-2 bg-white dark:bg-zinc-800 dark:border-zinc-800 -top-1 right-5 -rotate-45 border-r border-t border-zinc-200 rounded-sm"></div>
                                        {type !== "alle" && (
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <Link to={"/events/alle"} className={`${active && "bg-zinc-100 dark:bg-zinc-900"} transition-colors flex items-center gap-3 p-3 rounded-md`}>
                                                        <div className="w-6 h-6 flex items-center justify-center ">🌎</div>
                                                        Alle activiteiten
                                                    </Link>
                                                )}
                                            </Menu.Item>
                                        )}
                                        {Object.keys(TYPE_TO_OTHER).map((x) => {
                                            if (x !== type)
                                                return (
                                                    <Menu.Item>
                                                        {({ active }) => (
                                                            <Link to={"/events/" + x} className={`${active && "bg-zinc-100 dark:bg-zinc-900"} transition-colors flex items-center gap-3 p-3 rounded-md`}>
                                                                <div
                                                                    style={{
                                                                        //@ts-ignore
                                                                        backgroundColor: !EVENT_TYPES[TYPE_TO_OTHER[x].short].no_back ? EVENT_TYPES[TYPE_TO_OTHER[x].short].background_color : "",
                                                                        //@ts-ignore
                                                                        color: !EVENT_TYPES[TYPE_TO_OTHER[x].short].no_back ? EVENT_TYPES[TYPE_TO_OTHER[x].short].text_color : ""
                                                                    }}
                                                                    className={
                                                                        //@ts-ignore
                                                                        "w-6 h-6 flex items-center justify-center " + (!EVENT_TYPES[TYPE_TO_OTHER[x].short].no_back ? "rounded text-xs font-bold p-1" : "text-lg")
                                                                    }>
                                                                    {
                                                                        //@ts-ignore
                                                                        EVENT_TYPES[TYPE_TO_OTHER[x].short].icon
                                                                    }
                                                                </div>
                                                                {capitalizeFirstLetter(TYPE_TO_OTHER[x].long)}
                                                            </Link>
                                                        )}
                                                    </Menu.Item>
                                                );
                                        })}
                                    </Menu.Items>
                                </>
                            )}
                        </Menu>
                    </div>
                ) : (
                    <h1 data-aos="fade-up" data-aos-duration="800" className="col-span-full font-extrabold flex text-lg md:text-xl lg:text-3xl text-blue-700 dark:text-blue-400 mb-5">
                        <div className="flex">
                            <div>{!!type && type !== "alle" ? TYPE_TO_OTHER[type]?.long?.toUpperCase() : "ALLE ACTIVITEITEN"} VAN</div> <DateFormat date={date} time={false} format={"$WD$ $d$ $MM$"} trySpecial={true} styles="text-blue-500 dark:text-blue-300 italic capitalize ml-2" />
                        </div>
                    </h1>
                )}

                {events.length > 0 ? (
                    <>
                        {events.map((event, index) => {
                            return (
                                <Card
                                    key={index}
                                    aos_type="fade-up"
                                    aos_duration="800"
                                    aos_delay={(index % 3) * 100}
                                    foto={event.image}
                                    titel={event.title}
                                    onderschrift={<DateFormat date={event.start} time={false} format={"$WD$ $d$ $MM$"} trySpecial={true} styles="text-zinc-600 dark:text-zinc-400 italic capitalize" />}
                                    text={event.short_desc}
                                    type={EVENT_TYPES[event.type].name}
                                    link={"/event/" + event.title.replace(/\s+/g, "-").toLowerCase() + "/" + event.id}
                                    knop={
                                        <>
                                            MEER INFO
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 inline-block ml-2" viewBox="0 0 20 20" fill="currentColor">
                                                <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                                            </svg>
                                        </>
                                    }
                                />
                            );
                        })}
                    </>
                ) : (
                    <>
                        <h2 data-aos="fade-up" data-aos-duration="800" className="dark:text-zinc-300 col-span-full">
                            Er zijn momenteel geen aangekondigde {type !== "alle" ? TYPE_TO_OTHER[type]?.long : "activiteiten"}
                            <br />
                            <div className="mt-8 col-span-full">
                                {!!type && (type !== "alle" || !!datum) && (
                                    <Link className="text-blue-700 uppercase font-bold italic" to="/events/alle">
                                        Toon alle activiteiten
                                    </Link>
                                )}
                            </div>
                        </h2>
                    </>
                )}
                {!datum && afgelopenEvents.length > 0 && (
                    <>
                        <div className="col-span-full mt-14">
                            <h1 data-aos="fade-up" data-aos-duration="800" className="font-extrabold flex-grow text-lg md:text-xl lg:text-3xl text-blue-700 dark:text-blue-400 mb-5">
                                AFGELOPEN {!!type && type !== "alle" ? TYPE_TO_OTHER[type].long?.toUpperCase() : "ACTIVITEITEN"}
                            </h1>
                        </div>
                        {afgelopenEvents.map((event, index) => {
                            return (
                                <Card
                                    key={index}
                                    aos_type="fade-up"
                                    aos_duration="800"
                                    aos_delay={(index % 3) * 100}
                                    foto={event.image}
                                    titel={event.title}
                                    onderschrift={<DateFormat date={event.start} time={false} format={"$WD$ $d$ $MM$"} trySpecial={true} styles="text-zinc-600 dark:text-zinc-400 italic capitalize" />}
                                    text={event.short_desc}
                                    type={EVENT_TYPES[event.type].name}
                                    link={"/event/" + event.title.replace(/\s+/g, "-").toLowerCase() + "/" + event.id}
                                    knop={
                                        <>
                                            MEER INFO
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 inline-block ml-2" viewBox="0 0 20 20" fill="currentColor">
                                                <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                                            </svg>
                                        </>
                                    }
                                />
                            );
                        })}
                    </>
                )}
            </div>

            {!datum && (
                <div id="kalender" data-aos="fade-up" aos-data-duration="800" className="bg-blue-500 dark:bg-blue-900 scroll-mt-24">
                    <div className="w-11/12 pt-20 pb-40 mx-auto relative lg:w-10/12">
                        <h1 className="font-extrabold flex-grow text-center text-lg md:text-xl lg:text-3xl text-white mb-12">KALENDER OVERZICHT</h1>
                        <Calendar events={allEvents} />
                    </div>
                </div>
            )}
        </div>
    );
}

export default Events;
