import Navigation from "../components/navigation";

import { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import { useState } from "react";
import axios from "axios";
import config from "../config";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

function Custom({ pageId, title, extraClasses }) {
    const [text, setText] = useState();
    let location = useLocation();
    useEffect(() => {
        Aos.init();
        axios
            .get(config.api_url + "/pages/?name=" + title)
            .then((result) => {
                setText(result.data.data[0].page);
            })
            .catch(() => {
                setText("");
            });
    }, [location]);

    return (
        <div className="dark:bg-zinc-900">
            <Navigation />
            <Helmet>
                <title>{title} - Hermes Gent</title>
                <meta name="description" content={"Hermes Gent - " + title} />
            </Helmet>

            <div className="lg:w-8/12 w-11/12 pt-20 pb-40 mx-auto">
                <div data-aos="fade-up" data-aos-duration="800" dangerouslySetInnerHTML={{ __html: text }} className={"prose prose-zinc dark:prose-invert max-w-full prose-a:no-underline prose-a:text-blue-700 dark:prose-a:text-blue-300 prose-h2:text-3xl " + extraClasses}></div>
            </div>
        </div>
    );
}

export default Custom;
