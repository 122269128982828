import { Helmet } from "react-helmet";
import Modal from "../../modal";
import config from "../../../config";
import { useEffect, useState } from "react";
import axios from "axios";
import { check_login } from "../../../auth/auth";
import { check_permission } from "../../../auth/auth";
import RondeToevoegen from "./RondeToevoegen";
import RondeBewerken from "./RondeBewerken";
import Results from "../../Verkiezingen/Results";
export default function Rondes(props) {
    const [rondeToevoegenModal, setRondeToevoegenModal] = useState(false);
    const [rondeBewerkModal, setRondeBewerkModal] = useState([]);
    const [resultsModal, setResultsModal] = useState([]);
    const [allowed] = useState(check_permission("praesidium", true));

    const [data, setData] = useState([]);

    useEffect(() => {
        if (!!check_login(false) && allowed) {
            load();
        }
    }, []);

    function load() {
        axios
            .get(config.api_url + "/electionmodule/rounds/?election=" + props.id + "&sort=-created")
            .then((response) => {
                setData(response.data.data);
            })
            .catch((err) => console.log(err));
    }

    return (
        <>
            {!!check_login(true) ? (
                <div className="dark:bg-zinc-900 py-8 md:p-8">
                    <Helmet>
                        <title>Stem Module - Hermes Gent</title>
                        <meta name="description" content="Hermes Gent - Stem Module" />
                    </Helmet>
                    <div className="flex items-center">
                        <div className="flex-grow">
                            <h1 className="font-bold text-2xl lg:text-4xl uppercase text-zinc-800  dark:text-zinc-300">{props.verkiezing}</h1>
                        </div>
                        <button onClick={() => load()} className="text-zinc-500 p-2 rounded-full flex items-center justify-center bg-zinc-200 dark:bg-black transition-colors hover:text-blue-500 ">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                            </svg>
                        </button>
                    </div>
                    <div>
                        <Modal
                            openButtonClasses="mt-4 py-2 px-4 bg-black text-white font-bold rounded-md transition-colors hover:bg-zinc-800 dark:bg-zinc-800 dark:hover:bg-black cursor-pointer"
                            openButtonValue={"VOEG RONDE TOE"}
                            condition={rondeToevoegenModal}
                            setCondition={setRondeToevoegenModal}
                            boxClasses="w-full md:w-7/12 xl:w-5/12 md:mx-auto mt-8 lg:mt-32 mb-16">
                            <RondeToevoegen verkiezing={props.id} close={() => setRondeToevoegenModal(false)} reload={() => load()} />
                        </Modal>
                    </div>

                    <div className="grid gap-5 mt-8">
                        {data.map((module) => {
                            return (
                                <>
                                    <div className="shadow-md relative border border-zinc-200 p-4 lg:p-8 rounded-lg dark:bg-black/40 dark:border-zinc-800 flex flex-col md:flex-row text-gray-700 dark:text-gray-300">
                                        <div className="flex-grow">
                                            <div className={"w-3 h-3 absolute right-4 top-4 rounded-full bg-red-400 " + (module?.visible && "bg-emerald-500")} title={module.visible ? "Zichtbaar" : "Niet zichtbaar"} />
                                            <h1 className="font-bold text-xl md:text-2xl xl:text-3xl flex items-center">{module.title}</h1>
                                            <span>
                                                <span className="font-bold">
                                                    {module?.voting_open && "(Ronde open)"} {module?.results_open && "(Resultaten open)"}
                                                </span>{" "}
                                                {module.votes_cast} van de {module.max_votes} hebben gestemd.
                                            </span>
                                        </div>
                                        <div className="flex justify-end gap-1 items-center">
                                            {module.results_open && (
                                                <Modal
                                                    uuid={module.id}
                                                    condition={resultsModal}
                                                    setCondition={setResultsModal}
                                                    boxClasses="w-full px-2 md:w-7/12 xl:w-5/12 md:mx-auto mt-8 lg:mt-32 mb-16"
                                                    openButtonClasses="py-2 px-4 bg-black text-white font-bold rounded-md  transition-colors hover:bg-zinc-800 dark:bg-zinc-800 dark:hover:bg-black cursor-pointer"
                                                    openButtonValue={
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                                        </svg>
                                                    }>
                                                    <Results id={module?.id} verkiezing={props.verkiezing} rondeNaam={module.title} close={() => setResultsModal(resultsModal.filter((x) => x !== module.id))} />
                                                </Modal>
                                            )}
                                            <Modal
                                                uuid={module.id}
                                                condition={rondeBewerkModal}
                                                setCondition={setRondeBewerkModal}
                                                openButtonClasses="py-2 px-4 bg-black text-white font-bold rounded-md  transition-colors hover:bg-zinc-800 dark:bg-zinc-800 dark:hover:bg-black cursor-pointer"
                                                openButtonValue={
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                                                    </svg>
                                                }
                                                boxClasses="w-full md:w-7/12 xl:w-5/12 md:mx-auto mt-8 lg:mt-32 mb-16">
                                                <RondeBewerken ronde={module} close={() => setRondeBewerkModal(rondeBewerkModal.filter((x) => x !== module.id))} reload={() => load()} />
                                            </Modal>
                                        </div>
                                    </div>
                                </>
                            );
                        })}
                    </div>
                </div>
            ) : (
                ""
            )}
        </>
    );
}
