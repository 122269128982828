import PaneelNavigatie from "../../components/Paneel/paneelNavigatie";

import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import { check_login } from "../../auth/auth";
import { check_permission } from "../../auth/auth";
import Modal from "../../components/modal";
import axios from "axios";
import config from "../../config";
import GeefAantalStemmen from "../../components/Paneel/Verkiezing/geefAantalStemmen";
import VerkiezingsdagToevoegen from "../../components/Paneel/Verkiezing/VerkiezingsdagToevoegen";
import VerkiezingsdagBewerken from "../../components/Paneel/Verkiezing/VerkiezingsdagBewerken";
import { Link } from "react-router-dom";
function Paneel_Stem_Module() {
    const [claims] = useState(check_login(true));
    const [allowed] = useState(check_permission("ict", true));
    const [geefStemmenModal, setGeefStemmenModal] = useState(false);
    const [verkiezingToevoegenModal, setVerkiezingToevoegenModal] = useState(false);
    const [editModal, setEditModal] = useState([]);
    const [data, setData] = useState([]);

    useEffect(() => {
        Render();
    }, []);

    function Render() {
        if (!!check_login(false) && allowed) {
            axios
                .get(config.api_url + "/electionmodule/electiondays/?sort=-date")
                .then((response) => {
                    setData(response.data.data);
                })
                .catch((err) => console.error(err));
        }
    }

    return (
        <>
            {claims && allowed && (
                <div className="dark:bg-zinc-900">
                    <PaneelNavigatie />
                    <Helmet>
                        <title>Paneel - Stem Module - Hermes Gent</title>
                    </Helmet>
                    <div className="lg:w-8/12 w-11/12 pt-10 pb-40 mx-auto ">
                        <div className="flex flex-col lg:flex-row items-center mb-8 border-b-2 border-zinc-200 dark:border-zinc-800 p-6 pl-0 pb-2">
                            <h1 className="font-bold text-2xl lg:text-4xl uppercase text-zinc-800  dark:text-zinc-300">VERKIEZINGSDAGEN</h1>

                            <div className="flex flex-col md:flex-row items-center">
                                <Modal
                                    condition={verkiezingToevoegenModal}
                                    setCondition={setVerkiezingToevoegenModal}
                                    boxClasses="w-full px-4  md:w-7/12 xl:w-5/12 md:mx-auto mt-12 lg:mt-36 mb-16"
                                    openButtonClasses="flex items-center h-14 px-6 transition-colors text-zinc-500 hover:text-zinc-800 dark:text-zinc-400 dark:hover:text-zinc-300"
                                    openButtonValue={
                                        <>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={3} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                            </svg>
                                            <span className="pl-2">TOEVOEGEN</span>
                                        </>
                                    }>
                                    <VerkiezingsdagToevoegen reload={() => Render()} close={() => setVerkiezingToevoegenModal(false)} />
                                </Modal>
                            </div>
                        </div>
                        <div className="mb-8 flex">
                            <Modal
                                condition={geefStemmenModal}
                                setCondition={setGeefStemmenModal}
                                boxClasses="w-full md:w-7/12 xl:w-5/12 md:mx-auto mt-12 lg:mt-36 mb-16"
                                openButtonValue={"GEEF AANTAL STEMMEN"}
                                openButtonClasses="py-2 px-4 bg-black text-white inline font-bold rounded-md transition-colors hover:bg-zinc-800 dark:bg-zinc-800 dark:hover:bg-black cursor-pointer">
                                <GeefAantalStemmen close={() => setGeefStemmenModal(false)} />
                            </Modal>
                        </div>
                        <div>
                            {data.map((module) => {
                                return (
                                    <div key={module.id} className="shadow-md border relative border-zinc-200 p-8 mt-4 rounded-lg dark:bg-black/40 dark:border-zinc-800 flex flex-col lg:flex-row items-center">
                                        <div className="flex-grow">
                                            <h1 className="font-bold text-xl md:text-2xl xl:text-3xl text-gray-700 dark:text-gray-300">{module.name}</h1>
                                            <div className={"w-3 h-3 absolute right-4 top-4 rounded-full " + (module?.visible ? "bg-emerald-500" : "bg-red-400")} title={module.visible ? "Zichtbaar" : "Niet zichtbaar"} />
                                        </div>
                                        <div className="flex items-center">
                                            <Modal
                                                uuid={module.id}
                                                condition={editModal}
                                                setCondition={setEditModal}
                                                boxClasses="w-full px-4  md:w-7/12 xl:w-5/12 md:mx-auto mt-12 lg:mt-36 mb-16"
                                                openButtonClasses="py-2 px-4 bg-black text-white font-bold rounded-md mx-1 transition-colors hover:bg-zinc-800 dark:bg-zinc-800 dark:hover:bg-black cursor-pointer"
                                                openButtonValue={
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                                                    </svg>
                                                }>
                                                <VerkiezingsdagBewerken verkiezingsdag={module} reload={() => Render()} close={() => setEditModal(editModal.filter((x) => x !== module.id))} />
                                            </Modal>
                                            <Link
                                                to={"/paneel/stem-module/" + module.name.replace(/\s+/g, "-").toLowerCase() + "/" + module.id + "/"}
                                                className="py-2 px-4 bg-black text-white font-bold rounded-md mx-1 transition-colors hover:bg-zinc-800 dark:bg-zinc-800 dark:hover:bg-black cursor-pointer">
                                                OPEN
                                            </Link>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default Paneel_Stem_Module;
