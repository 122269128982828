import React, { useEffect, useState } from "react";
import Navigation from "../components/navigation";
import { Helmet } from "react-helmet";
import Calendar from "../components/calendar";
import axios from "axios";
import config from "../config";
import Loading from "../components/Loading";
import { Link, useParams } from "react-router-dom";
import { EVENT_TYPES, TYPE_TO_OTHER } from "../components/Choices";
import { Menu } from "@headlessui/react";
import { _ } from "../modules/functions";
interface Activiteiten {
    loading: boolean;
    error: boolean;
    data: Event[];
}

export default function Kalender() {
    let type: string | undefined = useParams().type?.toLowerCase();
    const [activiteiten, setActiviteiten] = useState<Activiteiten>({
        loading: true,
        error: false,
        data: []
    });

    useEffect(() => {
        let params = {
            active: true,
            sort: "start",
            type: ""
        };
        if (type && type !== "alle") params["type"] = TYPE_TO_OTHER[type].short;
        axios
            .get(config.api_url + "/events/", { params: params })
            .then((response) => {
                let data = response.data.data;
                // let now = new Date();
                // let commingEvents = [];
                // for (let i = 0; i < data.length; i++) {
                //     let e = data[i];
                //     let e_end = new Date(e.end);
                //     if (e_end > now) {
                //         commingEvents.push(e);
                //     }
                // }
                setActiviteiten({
                    loading: false,
                    error: false,
                    data: data
                });
            })
            .catch(() => {
                setActiviteiten({
                    loading: false,
                    error: true,
                    data: []
                });
            });
    }, [type]);

    return (
        <div className="bg-blue-500 dark:bg-blue-900">
            <Navigation />
            <Helmet>
                <title>Kalender - Hermes Gent</title>
                <meta name="description" content={"Hermes Gent - Kalender"} />
            </Helmet>

            <div className="lg:w-8/12 w-11/12 pt-20 pb-40 mx-auto">
                {activiteiten.loading ? (
                    <div className="w-full flex justify-center">
                        <Loading size={10} />
                    </div>
                ) : (
                    <div>
                        <div className="w-full flex flex-col">
                            <Menu as="div" className="relative self-end">
                                {({ open }) => (
                                    <>
                                        <Menu.Button className={`uppercase transition-colors font-semibold px-3 py-2 border-2 rounded-md flex items-center ${open ? "border-blue-200 dark:border-blue-300 text-blue-100 dark:text-blue-300" : "border-blue-400 dark:border-blue-400 text-blue-200 dark:text-zinc-300 dark:hover:text-blue-300 hover:text-blue-300 hover:border-blue-400 dark:hover:border-blue-300"}`}>
                                            {!!type && type !== "alle" ? TYPE_TO_OTHER[type].long.toUpperCase() : "ALLE ACTIVITEITEN"}
                                            <svg xmlns="http://www.w3.org/2000/svg" className={"h-5 w-5 transition-transform " + (open ? "rotate-180" : "")} viewBox="0 0 20 20" fill="currentColor">
                                                <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                                            </svg>
                                        </Menu.Button>
                                        <Menu.Items className="absolute w-64 top-12 z-10 right-0 p-4 text-left bg-white shadow-md border border-zinc-200 dark:border-zinc-800 dark:bg-zinc-800 text-zinc-500 dark:text-zinc-400 rounded-lg flex flex-col focus:outline-none">
                                            <div className="absolute w-2 h-2 bg-white dark:bg-zinc-800 dark:border-zinc-800 -top-1 right-5 -rotate-45 border-r border-t border-zinc-200 rounded-sm"></div>
                                            {type !== "alle" && (
                                                <Menu.Item>
                                                    {({ active }) => (
                                                        <Link to={"/kalender/alle"} className={`${active && "bg-zinc-100 dark:bg-zinc-900"} transition-colors flex items-center gap-3 p-3 rounded-md`}>
                                                            <div className="w-6 h-6 flex items-center justify-center ">🌎</div>
                                                            Alle activiteiten
                                                        </Link>
                                                    )}
                                                </Menu.Item>
                                            )}
                                            {Object.keys(TYPE_TO_OTHER).map((x) => {
                                                if (x !== type)
                                                    return (
                                                        <Menu.Item>
                                                            {({ active }) => (
                                                                <Link to={"/kalender/" + x} className={`${active && "bg-zinc-100 dark:bg-zinc-900"} transition-colors flex items-center gap-3 p-3 rounded-md`}>
                                                                    <div
                                                                        style={{
                                                                            //@ts-ignore
                                                                            backgroundColor: !EVENT_TYPES[TYPE_TO_OTHER[x].short].no_back ? EVENT_TYPES[TYPE_TO_OTHER[x].short].background_color : "",
                                                                            //@ts-ignore
                                                                            color: !EVENT_TYPES[TYPE_TO_OTHER[x].short].no_back ? EVENT_TYPES[TYPE_TO_OTHER[x].short].text_color : ""
                                                                        }}
                                                                        className={
                                                                            //@ts-ignore
                                                                            "w-6 h-6 flex items-center justify-center " + (!EVENT_TYPES[TYPE_TO_OTHER[x].short].no_back ? "rounded text-xs font-bold p-1" : "text-lg")
                                                                        }
                                                                    >
                                                                        {
                                                                            //@ts-ignore
                                                                            EVENT_TYPES[TYPE_TO_OTHER[x].short].icon
                                                                        }
                                                                    </div>
                                                                    {_.CAPITALIZE_FIRST_LETTER(TYPE_TO_OTHER[x].long)}
                                                                </Link>
                                                            )}
                                                        </Menu.Item>
                                                    );
                                            })}
                                        </Menu.Items>
                                    </>
                                )}
                            </Menu>
                        </div>
                        <Calendar events={activiteiten.data} />
                    </div>
                )}
            </div>
        </div>
    );
}
