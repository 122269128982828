import React from "react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Navigation from "../../components/navigation";
import SearchableSelect from "../../components/searchableSelect";
import Modal from "../../components/modal";
import Login from "../../auth/login";
import Loading from "../../components/Loading";
import { check_login, check_permission } from "../../auth/auth";
import MultiSelect from "../../components/MultiSelect";
import Overzicht from "./overzicht";
import { Helmet } from "react-helmet";
import { CursusverkoopItem } from "../../modules/types";
import { CursusverkoopRichting } from "../../modules/types";
//import { CursusverkoopVakken } from "../../modules/types";
import { CURSUSVERKOOP_ITEM_TYPES } from "../../components/Choices";
import { PakketInhoud } from "./pakketInhoud";
import toast from "react-hot-toast";
import Notification from "../../components/Notification";
import axios from "axios";
import config from "../../config";
export type Winkelmand = {
    items: CursusverkoopItem[];
};

export default function CursusShop() {
    const [loading, setLoading] = useState(true);
    check_permission("praesidium", true);
    const [claims, setClaims] = useState(check_login(false));
    const [loginModal, setLoginModal] = useState(false);
    const [overzichtModal, setOverzichtModal] = useState(false);

    const [richting, setRichting] = useState<CursusverkoopRichting | null>(null);
    const [soortItem, setSoortItem] = useState([]);
    const [selectAll, setSelectAll] = useState(false);

    const [reedsLid, setReedsLid] = useState(false);
    const [wordLid, setWordLid] = useState(true);

    const [pakketModal, setPakketModal] = useState([]);

    useEffect(() => {
        setLoading(false);

        //todo: get all cursussen. with loading
        let claims: any = JSON.parse(check_login(false));
        //todo: check if user is already member
        axios.get(config.api_url + "/registrationmodule/registrations/?start_year=" + config.active_year + "&user=" + claims.user_id).then((response) => {
            if (response.data.data[0].member) {
                setReedsLid(true);
                setWordLid(false);
            }
        });
        //setReedsLid(true);
    }, []);

    function reload() {
        setLoading(true);
        setClaims(check_login(false));
        setLoading(false);
    }

    const [winkelMand, setWinkelMand] = useState<Winkelmand>({
        items: []
    });

    const richtingen = [
        { id: "1", value: "1e Bachelor Industrieel Ingenieur" },
        { id: "2", value: "2e Bachelor Industrieel Ingenieur: Informatica" }
    ];

    const EERSTE_BACH: CursusverkoopRichting = {
        id: "1",
        name: "1e Bachelor Industrieel Ingenieur",
        vakken: []
    };
    const TWEEDE_BACH_INFORMATICA: CursusverkoopRichting = {
        id: "2",
        name: "2e Bachelor Industrieel Ingenieur: Informatica",
        vakken: []
    };

    const types: Object[] = [];

    let i = 0;
    for (const [key, value] of Object.entries(CURSUSVERKOOP_ITEM_TYPES)) {
        types.push({ id: i, name: key, value: value.meervoud });
        i++;
    }

    const BIJ_WIJZE_VAN_SPREKEN: CursusverkoopItem = {
        id: "1",
        field_of_study: [EERSTE_BACH, TWEEDE_BACH_INFORMATICA],
        year: "2022",
        items: [],
        course: [],
        description: "",
        name: "Bij wijze van spreken",
        member_price: 37.55,
        price: 40.35,
        optional: true,
        kind: "boek"
    };
    const DUURZAME: CursusverkoopItem = {
        id: "2",
        field_of_study: [EERSTE_BACH],
        year: "2022",
        items: [],
        course: [],
        description: "",
        name: "Duurzame energietechnieken",
        member_price: 5.1,
        price: 9.0,
        optional: false,
        kind: "cursus"
    };
    const WISKUNDE: CursusverkoopItem = {
        id: "4",
        field_of_study: [EERSTE_BACH],
        year: "2022",
        items: [],
        course: [],
        description: "",
        name: "Wiskunde I",
        member_price: 17.1,
        price: 24.0,
        optional: false,
        kind: "cursus"
    };

    const LABOJAS: CursusverkoopItem = {
        id: "9",
        field_of_study: [EERSTE_BACH],
        year: "2022",
        items: [],
        course: [],
        description: "",
        name: "Labojas",
        member_price: 15,
        price: 20,
        optional: false,
        kind: "voorwerp"
    };

    const LABOBRIL: CursusverkoopItem = {
        id: "10",
        field_of_study: [EERSTE_BACH],
        year: "2022",
        items: [],
        course: [],
        description: "",
        name: "Labobril",
        member_price: 8,
        price: 12,
        optional: false,
        kind: "voorwerp"
    };

    const EERSTE_BACH_PAKKET = {
        id: "3",
        field_of_study: [EERSTE_BACH],
        year: "2022",
        items: [BIJ_WIJZE_VAN_SPREKEN, DUURZAME, WISKUNDE],
        course: [],
        description: "Dit pakket bevat alle benodigdheden voor de eerste bachelor Industrieel Ingenieur.",
        name: "1e Bachelor volledig pakket",
        member_price: BIJ_WIJZE_VAN_SPREKEN.member_price + DUURZAME.member_price + WISKUNDE.member_price,
        price: BIJ_WIJZE_VAN_SPREKEN.price + DUURZAME.price + WISKUNDE.price,
        optional: false,
        kind: "pakket"
    };

    const EERSTE_BACH_PAKKET_ZONDER_OPTIONEEL = {
        id: "5",
        field_of_study: [EERSTE_BACH],
        year: "2022",
        items: [DUURZAME, WISKUNDE],
        course: [],
        description: "Dit pakket bevat alle benodigdheden voor de eerste bachelor Industrieel Ingenieur, zonder optionele boeken / cursussen.",
        name: "1e Bachelor pakket zonder optionele boeken",
        member_price: DUURZAME.member_price + WISKUNDE.member_price,
        price: DUURZAME.price + WISKUNDE.price,
        optional: false,
        kind: "pakket"
    };

    const EERSTE_BACH_MATERIAAL_PAKKET = {
        id: "11",
        field_of_study: [EERSTE_BACH],
        year: "2022",
        items: [LABOJAS, LABOBRIL],
        course: [],
        description: "Dit pakket bevat het materiaal die nodig is in de eerste bachelor",
        name: "1e Bachelor materiaal pakket",
        member_price: LABOJAS.member_price + LABOBRIL.member_price,
        price: LABOJAS.price + LABOBRIL.price,
        optional: false,
        kind: "pakket"
    };

    const items: CursusverkoopItem[] = [BIJ_WIJZE_VAN_SPREKEN, DUURZAME, WISKUNDE, LABOJAS, LABOBRIL, EERSTE_BACH_MATERIAAL_PAKKET, EERSTE_BACH_PAKKET, EERSTE_BACH_PAKKET_ZONDER_OPTIONEEL];

    const [filteredItems, setFilteredItems] = useState(items);

    useEffect(() => {
        filter();
    }, [soortItem, richting]);

    function filter() {
        let filtered = items;

        if (!!richting) {
            filtered = filtered.filter((x) => {
                return x.field_of_study.some((y) => y.id === richting.id);
            });
        }

        let soortNamen: any = [];
        soortItem.forEach((soort: any) => soortNamen.push(soort.name));
        if (soortNamen.length >= 1) {
            filtered = filtered.filter((x) => {
                return soortNamen.includes(x.kind);
            });
        } else {
        }
        setFilteredItems(
            filtered.sort((a, b) => {
                return a.name.localeCompare(b.name);
            })
        );
    }

    function handleBasketChange(item: CursusverkoopItem) {
        let items = winkelMand.items;
        let removed = false;

        items.forEach((x) => {
            if (x.id === item.id) {
                items = items.filter((x) => x.id != item.id);
                removed = true;
            }
        });

        if (!removed) {
            if (item.items.length > 0) {
                for (let x of items) {
                    if (x.items.length > 0) {
                        // melding

                        for (let pakketItem of item.items) {
                            if (dubbelInMand(pakketItem)) {
                                toast.error(
                                    (t) => (
                                        <Notification toast={t}>
                                            <>
                                                U heeft reeds een pakket geselecteerd die overlappende items bevat. <span className="font-bold italic">({x.name})</span>
                                            </>
                                        </Notification>
                                    ),
                                    {
                                        duration: 4000,
                                        position: "top-right"
                                    }
                                );
                                return;
                            }
                        }
                    }
                }

                for (let pakketItem of item.items) {
                    items = items.filter((x) => x.id !== pakketItem.id);
                }
            }
            items = [...items, item];
        }
        setWinkelMand({
            items: items
        });
    }

    /*     function addOrRemoveAllFromBasket(state: boolean) {
        if (state) {
            let mandje: Winkelmand = {
                items: []
            };
            for (let item of filteredItems) {
                mandje.items.push(item);
            }
            setWinkelMand(mandje);
        } else {
            setWinkelMand({
                items: []
            });
        }
    } */

    function dubbelInMand(item: CursusverkoopItem) {
        for (let mandItem of winkelMand.items) {
            for (let pakketItem of mandItem.items) {
                if (item.id === pakketItem.id) {
                    return true;
                }
            }
        }

        return false;
    }

    function getPrice() {
        let ledenprijs = 0;
        let prijs = 0;

        for (let item of winkelMand.items) {
            ledenprijs += item.member_price;
            prijs += item.price;
        }

        return [prijs, ledenprijs];
    }

    // Change the "all selected" if all are selected or not.
    useEffect(() => {
        if (winkelMand.items.length < filteredItems.length) {
            setSelectAll(false);
        } else {
            setSelectAll(true);
        }
    }, [winkelMand, filteredItems]);

    return (
        <>
            <Navigation />
            <Helmet>
                <title>Cursusshop - Hermes Gent</title>
            </Helmet>
            <div>
                <div className="lg:w-8/12 w-11/12 pt-20 pb-40 mx-auto text-zinc-700 dark:text-zinc-300">
                    {loading && (
                        <div className="w-full flex items-center justify-center">
                            <Loading size={8} />
                        </div>
                    )}
                    {!claims && !loading && (
                        <div>
                            <div className="shadow p-8 rounded-lg dark:bg-black/20">
                                U moet ingelogd zijn om cursussen te kunnen bestellen.
                                <div className="flex flex-wrap items-center mt-4 gap-3">
                                    <Modal condition={loginModal} setCondition={setLoginModal} openButtonValue="LOGIN" openButtonClasses="bg-blue-700 text-md px-6 py-2 text-white font-bold rounded-md hover:bg-blue-200 hover:text-blue-800 transition-colors flex items-center" boxClasses="px-2 w-full md:w-10/12 lg:w-6/12 xl:w-4/12 md:mx-auto mt-12 lg:mt-36 mb-16">
                                        <Login reload={() => reload()} />
                                    </Modal>

                                    <Link to="/registreer" className="italic text-zinc-500 dark:text-zinc-400">
                                        Een account aanmaken
                                    </Link>
                                </div>
                            </div>
                        </div>
                    )}

                    {!!claims && !loading && (
                        <div>
                            <div className="flex flex-col md:flex-row md:items-center w-full">
                                <h2 className="text-2xl font-bold flex-grow">Bestel uw cursussen</h2>
                                <div>
                                    <button className="text-zinc-400 italic px-4 py-2 border-2 border-zinc-300 dark:border-zinc-700 rounded-lg">Mijn vorige bestellingen bekijken</button>
                                </div>
                            </div>
                            <div className="shadow p-4 md:p-8 rounded-lg mt-8 dark:bg-black/20">
                                <p className="mb-1">Selecteer uw richting</p>
                                <SearchableSelect chosen={richting} setChosen={setRichting} options={richtingen} placeholder="Selectuur uw richting, begin met typen..." />
                                <p className="mt-4 mb-1">Filter op het soort item</p>
                                <MultiSelect disabled={!richting} options={types} onChange={setSoortItem} selected={soortItem} label="" placeholder={!richting ? "Selecteer eerst uw richting" : "Filter op soort item"} />
                                {!reedsLid && !!richting && (
                                    <div className="mt-4 w-full flex items-center gap-3">
                                        <input checked={wordLid} onChange={() => setWordLid(!wordLid)} type="checkbox" className="w-4 h-4 min-w-[20px] accent-blue-600 dark:accent-blue-300" />
                                        <p className="flex-grow">
                                            Ik wil lid worden (€5) en een heel jaar door genieten van
                                            <Link className="text-blue-600 dark:text-blue-300" to="/leden-voordeel" target="_blank">
                                                {" "}
                                                de voordelen
                                            </Link>{" "}
                                            en korting ontvangen op mijn bestellingen.
                                        </p>
                                    </div>
                                )}

                                {reedsLid && (
                                    <div className="mt-4">
                                        U bent reeds lid en kan gebruik maken van{" "}
                                        <Link className="text-blue-600 dark:text-blue-300" to="/leden-voordeel" target="_blank">
                                            de voordelen
                                        </Link>
                                    </div>
                                )}

                                {!!richting && (
                                    <div className="p-4 shadow rounded mt-4">
                                        <table className="w-full">
                                            <thead className="font-semibold">
                                                <tr className="border-b-2 border-zinc-200 dark:border-zinc-700">
                                                    <td className="w-12 py-3">
                                                        <div className="flex items-center justify-center w-full">
                                                            <input
                                                                checked={selectAll}
                                                                onChange={() => {
                                                                    //setSelectAll(!selectAll);
                                                                    //addOrRemoveAllFromBasket(!selectAll);
                                                                }}
                                                                type="checkbox"
                                                                className="hidden w-4 h-4 accent-blue-600 dark:accent-blue-300"
                                                            />
                                                        </div>
                                                    </td>
                                                    <td className="max-w-full py-3 hidden md:table-cell">Naam</td>
                                                    <td className="md:hidden">Cursusinformatie</td>
                                                    <td className="w-32 min-w-32 text-right py-3 hidden md:table-cell">Prijs lid</td>
                                                    <td className="w-32 min-w-32 text-right py-3 hidden md:table-cell">Prijs niet-lid</td>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {filteredItems.map((item) => (
                                                    <tr key={item.id + "+" + item.name} className="border-b border-zinc-200 dark:border-zinc-700">
                                                        <td className="py-3">
                                                            <div className="flex items-center justify-center w-full">
                                                                <input disabled={dubbelInMand(item)} checked={winkelMand.items.filter((x) => x.id === item.id).length > 0 || dubbelInMand(item)} onChange={() => handleBasketChange(item)} type="checkbox" className="w-4 h-4 accent-blue-600 dark:accent-blue-300" />
                                                            </div>
                                                        </td>
                                                        <td className="hidden md:table-cell py-3">
                                                            <div className="flex items-center gap-1">
                                                                <div>
                                                                    {item.name} {item.optional && <span className="italic text-zinc-400 dark:text-zinc-500">(optioneel)</span>}
                                                                    {dubbelInMand(item) ? <span className="font-medium italic"> (reeds in pakket)</span> : <></>}
                                                                </div>
                                                                {item.kind === "pakket" && (
                                                                    <Modal
                                                                        condition={pakketModal}
                                                                        setCondition={setPakketModal}
                                                                        uuid={item.id}
                                                                        boxClasses="w-full md:w-7/12 xl:w-6/12 md:mx-auto mt-8 lg:mt-32 mb-16"
                                                                        openButtonClasses="text-zinc-400"
                                                                        openButtonValue={
                                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                                                                            </svg>
                                                                        }
                                                                    >
                                                                        <PakketInhoud item={item} reedsLid={reedsLid} wordLid={wordLid} />
                                                                    </Modal>
                                                                )}
                                                            </div>
                                                        </td>
                                                        <td className={`text-right hidden md:table-cell py-3 ${wordLid || reedsLid ? "text-zinc-800 dark:text-zinc-100" : "text-zinc-400 dark:text-zinc-500 italic"}`}>€ {item.member_price.toFixed(2)}</td>
                                                        <td className={`text-right hidden md:table-cell py-3 ${!wordLid && !reedsLid ? "text-zinc-800 dark:text-zinc-100" : "text-zinc-400 dark:text-zinc-500 italic"}`}>€ {item.price.toFixed(2)}</td>
                                                        <td className="md:hidden py-3">
                                                            {item.name} {item.optional && <span className="italic text-zinc-400 dark:text-zinc-500">(optioneel)</span>}
                                                            {dubbelInMand(item) ? <span className="font-medium italic"> (reeds in pakket)</span> : <></>}
                                                            <br />
                                                            <div className={`mt-2 ${wordLid || reedsLid ? "text-zinc-800" : "text-zinc-400 italic"}`}>Prijs lid: € {item.member_price.toFixed(2)}</div>
                                                            <div className={`${!wordLid && !reedsLid ? "text-zinc-800" : "text-zinc-400 italic"}`}>Prijs niet-lid: € {item.price.toFixed(2)}</div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        {filteredItems.length < 1 && <p className="mt-2">Geen items gevonden voor deze richting / categorie</p>}
                                        {((wordLid && !reedsLid) || winkelMand.items.length > 0) && (
                                            <div className="mt-4">
                                                <span className="font-bold">Totaal: </span>
                                                <span>€ {reedsLid ? Math.abs(getPrice()[1]).toFixed(2) : wordLid ? Math.abs(getPrice()[1] + 5).toFixed(2) : Math.abs(getPrice()[0]).toFixed(2)}</span> {!reedsLid && <span className={`italic text-zinc-400 dark:text-zinc-500 ${!wordLid && "line-through"}`}>{getPrice()[0] - getPrice()[1] > 5 ? "(€ " + (getPrice()[0] - getPrice()[1] - 5).toFixed(2) + " leden korting)" : ""}</span>}
                                                {!wordLid && !reedsLid && getPrice()[0] - getPrice()[1] > 5 && (
                                                    <button
                                                        onClick={() => {
                                                            setWordLid(true);
                                                        }}
                                                        className="text-blue-600 ml-2 italic"
                                                    >
                                                        toch lid worden & deze korting krijgen
                                                    </button>
                                                )}
                                                {reedsLid && <span className={`italic text-zinc-400 dark:text-zinc-500`}>{"(€ " + (getPrice()[0] - getPrice()[1]).toFixed(2) + " leden korting)"}</span>}
                                            </div>
                                        )}
                                    </div>
                                )}

                                {!!richting && ((wordLid && !reedsLid) || winkelMand.items.length > 0) && (
                                    <div>
                                        <Modal
                                            condition={overzichtModal}
                                            setCondition={setOverzichtModal}
                                            boxClasses="w-full md:w-8/12 xl:w-7/12 md:mx-auto mt-12 lg:mt-36 mb-16"
                                            openButtonClasses="border-2 border-blue-700 dark:border-blue-300 text-md px-5 py-2 text-blue-700 dark:text-blue-300 font-bold rounded-md mt-8 hover:bg-blue-700 hover:text-white dark:hover:bg-blue-300 dark:hover:text-blue-900 transition-colors flex items-center"
                                            openButtonValue={
                                                <div className="flex items-center gap-3">
                                                    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" />
                                                    </svg>

                                                    <div>OVERZICHT & BESTELLEN</div>
                                                </div>
                                            }
                                        >
                                            <Overzicht closeModal={() => setOverzichtModal(false)} setWinkelMand={setWinkelMand} winkelMand={winkelMand} reedsLid={reedsLid} wordLid={wordLid} />
                                        </Modal>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}
