import React from "react";
import MultiSelect from "../../../MultiSelect";
import { useState } from "react";
import SearchableSelect from "../../../searchableSelect";
import Modal from "../../../modal";
import ItemToevoegen from "./toevoegen";
import { CURSUSVERKOOP_ITEM_TYPES } from "../../../Choices";
export default function Items() {
    const types: Object[] = [];

    let i = 0;
    for (const [key, value] of Object.entries(CURSUSVERKOOP_ITEM_TYPES)) {
        types.push({ id: i, name: key, value: value.meervoud });
        i++;
    }

    const richtingen = [
        { id: 1, value: "1e Bachelor Industrieel Ingenieur" },
        { id: 2, value: "2e Bachelor Industrieel Ingenieur: Bouwkunde" },
        { id: 3, value: "2e Bachelor Industrieel Ingenieur: Elektromechanica" },
        { id: 4, value: "2e Bachelor Industrieel Ingenieur: Chemie" },
        { id: 5, value: "2e Bachelor Industrieel Ingenieur: Elektronica ICT" },
        { id: 6, value: "2e Bachelor Industrieel Ingenieur: Informatica" },
        { id: 7, value: "3e Bachelor Industrieel Ingenieur: Informatica" },
        { id: 8, value: "1e Master Industrieel Ingenieur: Informatica" }
    ];

    const [typeFilter, setTypeFilter] = useState([]);
    const [richtingFilter, setRichtingFilter] = useState();

    //modals
    const [addItemModal, setAddItemModal] = useState(false);

    return (
        <div className="text-zinc-800 dark:text-zinc-300 mt-8 shadow p-4 lg:p-8 rounded-lg dark:bg-black/70">
            <div className="flex items-center">
                <h2 className="text-2xl font-bold uppercase flex-grow">Cursussen, boeken...</h2>
                <Modal
                    openButtonValue={
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                        </svg>
                    }
                    openButtonClasses="w-8 h-8 flex items-center justify-center bg-blue-700 dark:bg-blue-500 transition-colors hover:bg-blue-500 dark:hover:bg-blue-700 text-white rounded-full font-bold"
                    condition={addItemModal}
                    setCondition={setAddItemModal}
                    boxClasses="px-2 w-full md:w-11/12 lg:w-10/12 xl:w-9/12 md:mx-auto mt-12 lg:mt-24 mb-16"
                >
                    <ItemToevoegen />
                </Modal>
            </div>
            <div className="flex flex-col lg:flex-row items-center gap-2 mt-4">
                <div className="w-full lg:w-3/12 order-2 lg:order-1">
                    <MultiSelect disabled={false} options={types} onChange={setTypeFilter} placeholder={"Filter op soort item"} selected={typeFilter} />
                </div>
                <div className="w-full lg:w-5/12 order-1 lg:order-2">
                    <input type="text" placeholder="Filter op naam..." className="lex items-center w-full border border-zinc-300 focus:outline-none focus:ring-1 focus:ring-blue-600 bg-transparent dark:border-zinc-700 px-3 py-2 dark:text-zinc-400 rounded-md" />
                </div>
                <div className="w-full lg:w-4/12 order-3 lg:order-3">
                    <SearchableSelect chosen={richtingFilter} setChosen={setRichtingFilter} options={richtingen} placeholder="Filter op richting, begin met typen..." />
                </div>
            </div>
            <div>
                <div className="w-full shadow rounded-md p-4 mt-4 dark:bg-zinc-900 flex items-center">
                    <div className="flex-grow flex gap-8 w-full items-center">
                        <div className="px-2 py-1 bg-zinc-200/80 text-zinc-900 dark:bg-zinc-700 dark:text-zinc-200 rounded text-xs h-fit">Cursus</div>
                        <div className="">Statistiek en wiskundige data-analyse</div>
                    </div>
                    <div className="flex items-center gap-2">
                        <button className="relative group px-2 py-1 border-2 border-zinc-200 rounded-md text-sm text-zinc-400 dark:border-zinc-700 dark:text-zinc-500">
                            Richtingen
                            <div className="absolute hidden cursor-default group-focus-within:flex z-20 w-96 h-48 bg-white border border-zinc-200 dark:border-zinc-700 dark:bg-black dark:text-zinc-300 shadow rounded-md origin-top-right top-7 right-0 text-left p-4 flex-col gap-4 overflow-y-scroll">
                                <div>2e bachelor Industrieel Ingenieur Informatica</div>
                                <div>2e bachelor Industrieel Ingenieur Chemie</div>
                                <div>2e bachelor Industrieel Ingenieur Elektromechanica</div>
                                <div>2e bachelor Industrieel Ingenieur Elektromechanica</div>
                                <div>2e bachelor Industrieel Ingenieur Elektromechanica</div>
                                <div>2e bachelor Industrieel Ingenieur Elektromechanica</div>
                            </div>
                        </button>
                        <button className="text-zinc-400 dark:text-zinc-600 transition-colors hover:text-zinc-700 dark:hover:text-zinc-300">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                            </svg>
                        </button>

                        <button className="text-zinc-400 dark:text-zinc-600 transition-colors hover:text-zinc-700 dark:hover:text-zinc-300">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
