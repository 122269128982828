import PaneelNavigatie from "../../components/Paneel/paneelNavigatie";

import { useEffect, useState } from "react";
import config from "../../config";
import axios from "axios";
import PageBewerken from "../../components/Paneel/Pages/PageBewerken";
import Modal from "../../components/modal";
import { Helmet } from "react-helmet";

import { check_login } from "../../auth/auth";
import { check_permission } from "../../auth/auth";
import Loading from "../../components/Loading";

function Paneel_Pages() {
    const [claims] = useState(check_login(true));
    const [allowed] = useState(check_permission("praesidium", true));

    const [pageModal, setPageModal] = useState([]);
    //let now = new Date();
    let [pages, setPages] = useState([]);
    let [loading, setLoading] = useState(true);

    useEffect(() => {
        Render();
    }, []);

    function Render() {
        axios
            .get(config.api_url + "/pages/?sort=title")
            .then((response) => {
                setLoading(false);
                let data = response.data.data;
                setPages(data);
            })
            .catch(() => setLoading(false));
    }

    return (
        <>
            {claims && allowed && (
                <div className="dark:bg-zinc-900">
                    <PaneelNavigatie />
                    <Helmet>
                        <title>Paneel - Pages - Hermes Gent</title>
                    </Helmet>
                    <div className="lg:w-8/12 w-11/12 pt-10 pb-40 mx-auto ">
                        {loading && (
                            <div className="flex items-center justify-center">
                                <Loading size={8} />
                            </div>
                        )}
                        {!loading && pages.length === 0 && <div className="text-zinc-700 dark:text-zinc-400">Geen pagina's gevonden</div>}
                        <div className="grid lg:grid-cols-2 xl:grid-cols-3 gap-4">
                            {pages.map((page) => {
                                return (
                                    <div className="mt-4 shadow-md w-full flex justify-center align-center rounded-lg border border-zinc-100 relative dark:border-zinc-800 dark:bg-black/40">
                                        <div className="text-4xl font-extrabold text-zinc-700 py-24 dark:text-zinc-400">{page.name}</div>
                                        <Modal
                                            condition={pageModal}
                                            setCondition={setPageModal}
                                            uuid={page.id}
                                            openButtonClasses="absolute right-4 bottom-4 text-zinc-500 p-2 bg-zinc-100 rounded-full border-2 border-zinc-200 dark:bg-zinc-900 dark:border-black/80 transition-all hover:border-zinc-100 dark:hover:border-zinc-900 "
                                            openButtonValue={
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                                                </svg>
                                            }>
                                            <PageBewerken close={() => setPageModal(pageModal.filter((x) => x !== page.id))} id={page.id} />
                                        </Modal>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default Paneel_Pages;
