import { Helmet } from "react-helmet";
import { useState, useEffect } from "react";
import config from "../../config";
//import toast from "react-hot-toast";
//import Notification from "../Notification";
import axios from "axios";
import { check_login } from "../../auth/auth";
export default function Results(props) {
	const [data, setData] = useState([]);
	const [votes, setVotes] = useState()
	useEffect(() => {
		if (!!check_login(false)) {
			axios
				.get(config.api_url + "/electionmodule/choices/?round=" + props.id)
				.then((response) => {
					setData(response.data.data.sort((x, y) => x.weight > y.weight));
					let votes = 0
					for(let vote of response.data.data){
						votes += vote?.votes;
					}
					setVotes(votes);
				})
				.catch((err) => console.error(err)
				);
		}
	}, []);

	return (
		<>
			{!!check_login(true) ? (
				<div className="dark:bg-zinc-900 py-8 md:p-8">
					<Helmet>
						<title>Stem Module - Hermes Gent</title>
						<meta name="description" content="Hermes Gent - Stem Module" />
					</Helmet>

					<h1 className="font-bold text-2xl lg:text-4xl uppercase text-zinc-800  dark:text-zinc-300">{props.rondeNaam}</h1>

					
					<div className="grid gap-5 mt-8 text-zinc-700 dark:text-zinc-200">
						{data.map((module) => {
							return (
								<div className={"shadow-md border border-zinc-200 p-4 rounded-lg dark:bg-black/40 dark:border-zinc-800 flex items-center flex-row"}>
									<h1 className="font-bold text-xl md:text-2xl xl:text-3xl text-gray-700 dark:text-gray-300 flex-grow">{module.name}</h1>
                                    <h2>{module.votes}/{module?.round?.max_votes} - <span className="font-bold">{Math.round(module.votes/module?.round?.max_votes * 1000) / 10}%</span></h2>
								</div>
							);
						})}
						{votes < data[0]?.round?.max_votes && <p className="italic text-zinc-700 dark:text-zinc-200">{data[0]?.round?.max_votes - votes} {data[0]?.round?.max_votes - votes === 1 ? " stem is " : " stemmen zijn "} niet uitgebracht</p>}
					</div>
				</div>
			) : (
				""
			)}
		</>
	);
}
