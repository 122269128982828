// @ts-nocheck

import { _ } from "../../../modules/functions";
import React from "react";
import { Helmet } from "react-helmet";
import { Page, Text, Document, StyleSheet, PDFDownloadLink } from "@react-pdf/renderer";

import ReactPDF from "@react-pdf/renderer";
import { FK_Student } from "./Home";
import Notification from "../../../components/Notification";
import axios from "axios";
import config from "../../../config";
import toast from "react-hot-toast";
// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: "row",
        backgroundColor: "#fff"
    },
    text: {
        position: "absolute",
        fontSize: "0.28cm",
        bottom: "1.08cm",
        left: "3.5cm",
        width: "4.3cm"
    }
});

interface DocumentProps {
    name: string;
}
// Create Document Component
const MyDocument = ({ name }: DocumentProps) => (
    <Document>
        <Page size={[54 * 2.8566666666666, 85 * 2.834646464646]} orientation="landscape" style={styles.page}>
            <Text style={styles.text}>{name}</Text>
        </Page>
    </Document>
);

interface props {
    reload: () => void;
    student: FK_Student;
}

export default function Paneel_FkStudent({ reload, student }: props) {
    function Lidkaart_Betaald() {
        let formdata = new FormData();
        formdata.append("userid", student.id);

        const promise = axios
            .post(config.api_url + "/lidkaarten/", formdata)
            .then(() => {})
            .catch((error) => {
                throw Error("Lidkaart betaald zetten van " + student.first_name + " mislukt: " + error.message);
            });

        toast.promise(
            promise,

            {
                loading: (t) => <Notification toast={t}>Bezig met bewerken...</Notification>,
                success: (data) => (t) =>
                    (
                        <Notification toast={t}>
                            Aanpassen van <b>{data}</b> succesvol!
                        </Notification>
                    ),
                error: (err) => (t) => <Notification toast={t}>{err.message}</Notification>
            },
            {
                duration: 4000,
                position: "top-right"
            }
        );
    }
    return (
        <>
            <Helmet>
                <title>Paneel - Student - Hermes Gent</title>
            </Helmet>
            <div className="w-full mt-16">
                <h1 className="font-bold mb-16 text-4xl text-zinc-800 dark:text-zinc-300 flex-grow">{student.first_name + " " + student.last_name}</h1>
                {student.card.status === "unpaid" && (
                    <PDFDownloadLink
                        onClick={() => Lidkaart_Betaald()}
                        className="text-center mt-3 flex items-center justify-center gap-2 w-full py-2 text-sm bg-blue-700 text-white rounded-md font-bold"
                        document={<MyDocument name={student.first_name + " " + student.last_name} />}
                        fileName={student.first_name + "_" + student.last_name}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5z" />
                        </svg>

                        <div>ALS BETAALD AANDUIDEN</div>
                    </PDFDownloadLink>
                )}

                {student.card.status !== "unpaid" && (
                    <PDFDownloadLink
                        className="text-center mt-3 flex items-center justify-center gap-2 w-full py-2 text-sm bg-blue-700 text-white rounded-md font-bold"
                        document={<MyDocument name={student.first_name + " " + student.last_name} />}
                        fileName={student.first_name + "_" + student.last_name}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6.72 13.829c-.24.03-.48.062-.72.096m.72-.096a42.415 42.415 0 0110.56 0m-10.56 0L6.34 18m10.94-4.171c.24.03.48.062.72.096m-.72-.096L17.66 18m0 0l.229 2.523a1.125 1.125 0 01-1.12 1.227H7.231c-.662 0-1.18-.568-1.12-1.227L6.34 18m11.318 0h1.091A2.25 2.25 0 0021 15.75V9.456c0-1.081-.768-2.015-1.837-2.175a48.055 48.055 0 00-1.913-.247M6.34 18H5.25A2.25 2.25 0 013 15.75V9.456c0-1.081.768-2.015 1.837-2.175a48.041 48.041 0 011.913-.247m10.5 0a48.536 48.536 0 00-10.5 0m10.5 0V3.375c0-.621-.504-1.125-1.125-1.125h-8.25c-.621 0-1.125.504-1.125 1.125v3.659M18 10.5h.008v.008H18V10.5zm-3 0h.008v.008H15V10.5z"
                            />
                        </svg>
                        <div>PRINT LIDKAART</div>
                    </PDFDownloadLink>
                )}
            </div>
        </>
    );
}
