import { useState } from "react";
import Notification from "../../Notification";
import toast from "react-hot-toast";
import { check_login } from "../../../auth/auth";
import { check_permission } from "../../../auth/auth";
import axios from "axios";
import config from "../../../config";
import { Switch } from "@headlessui/react";
export default function RondeToevoegen(props) {
	const [title, setTitle] = useState("");

	const [claims] = useState(check_login(true));
	const [allowed] = useState(check_permission("ict", true));
	const [open, setOpen] = useState(true);
	const [results, setResults] = useState(false);
	const [visible, setVisible] = useState(true);
	const [choices, setChoices] = useState([]);
	const [choiceName, setChoiceName] = useState("");
	function voegToe() {
		let formData = new FormData();
		formData.append("title", title);
		formData.append("election", props.verkiezing);
		formData.append("visible", visible);
		formData.append("voting_open", open);
		formData.append("results_open", results);
		for (let choice of choices) {
			formData.append("choices", choice);
		}
		if(title.length < 1){
			toast.error(
				(t) => (
					<Notification toast={t}>
						<p>
							Je ronde moet een titel hebben
						</p>
					</Notification>
				),
				{
					duration: 4000,
					position: "top-right",
				}
			);
			return
		}
		if(choices.length < 1){
			toast.error(
				(t) => (
					<Notification toast={t}>
						<p>
							Je moet minimaal 1 keuze mogelijkheid opgeven
						</p>
					</Notification>
				),
				{
					duration: 4000,
					position: "top-right",
				}
			);
			return
		}
		axios
			.post(config.api_url + "/electionmodule/rounds/", formData)
			.then(() => {
				toast.success(
					(t) => (
						<Notification toast={t}>
							<p>
								Toevoegen van ronde <span className="font-bold">{title}</span> succesvol!
							</p>
						</Notification>
					),
					{
						duration: 4000,
						position: "top-right",
					}
				);
				props.close();
				props.reload();
			})
			.catch((error) => {
				toast.error(
					(t) => (
						<Notification toast={t}>
							<p>
								Aanmaken van <span className="font-bold">{title}</span> mislukt!
								<span className="italic text-zinc-400"> ({error.message})</span>
							</p>
						</Notification>
					),
					{
						duration: 4000,
						position: "top-right",
					}
				);
			});
	}
	function addChoice() {
		if (!!choiceName) {
			setChoices([...choices, choiceName]);
			setChoiceName("");
		}else{
			toast.error(
				(t) => (
					<Notification toast={t}>
						<p>
							Oeps, je kan geen lege keuze toevoegen
						</p>
					</Notification>
				),
				{
					duration: 4000,
					position: "top-right",
				}
			);
		}
	}
	return (
		<>
			{claims && allowed && (
				<div className="mt-12 px-2 lg:px-8 mb-6">
					<h1 className="font-bold text-2xl uppercase text-zinc-800  dark:text-zinc-300">RONDE TOEVOEGEN</h1>
					<div className="mt-4 text-zinc-800 dark:text-zinc-200">
						<label htmlFor="name">Naam van ronde</label>
						<input onKeyDown={(e) => e.key === "Enter" && voegToe()} onChange={(e) => setTitle(e.target.value)} id="name" name="name" placeholder="Naam van ronde" type="text" className="border mt-2 border-zinc-300 focus:outline-none focus:ring-1 focus:ring-blue-600 dark:focus:ring-blue-400 bg-transparent dark:border-zinc-700 w-full rounded-md px-3 py-2 mb-4 autofill:bg-none" />

						<div className="mt-2 mb-4 flex flex-col lg:flex-row gap-4">
							<div className="flex items-center">
								<label className="text-lg font-light text-zinc-600 dark:text-zinc-500 mr-2">Visible: </label>

								<Switch checked={visible} onChange={setVisible} className={`${visible ? "bg-blue-600" : "bg-gray-200 dark:bg-zinc-700"} relative inline-flex items-center h-6 rounded-full w-11`}>
									<span className={`${visible ? "translate-x-6" : "translate-x-1"} transition-transform inline-block w-4 h-4 transform bg-white rounded-full`} />
								</Switch>
							</div>
							<div className="flex items-center">
								<label className="text-lg font-light text-zinc-600 dark:text-zinc-500 mr-2">Open: </label>

								<Switch checked={open} onChange={setOpen} className={`${open ? "bg-blue-600" : "bg-gray-200 dark:bg-zinc-700"} relative inline-flex items-center h-6 rounded-full w-11`}>
									<span className={`${open ? "translate-x-6" : "translate-x-1"} transition-transform inline-block w-4 h-4 transform bg-white rounded-full`} />
								</Switch>
							</div>
							<div className="flex items-center">
								<label className="text-lg font-light text-zinc-600 dark:text-zinc-500 mr-2">Resultaten open: </label>

								<Switch checked={results} onChange={setResults} className={`${results ? "bg-blue-600" : "bg-gray-200 dark:bg-zinc-700"} relative inline-flex items-center h-6 rounded-full w-11`}>
									<span className={`${results ? "translate-x-6" : "translate-x-1"} transition-transform inline-block w-4 h-4 transform bg-white rounded-full`} />
								</Switch>
							</div>
						</div>
						<h2 className="font-bold text-xl uppercase text-zinc-800  dark:text-zinc-300 mb-2">Keuzes:</h2>
						{choices.length === 0 && "Geen keuzes toegevoegd"}
						{choices.map((choice, key) => {
							return <div className="w-full my-1 border rounded border-zinc-200 dark:border-zinc-700 px-3 py-2">{choice}</div>;
						})}
						<div className="relative">
							<input value={choiceName} onKeyDown={(e) => e.key === "Enter" && addChoice()} onChange={(e) => setChoiceName(e.target.value)} id="name" name="name" placeholder="Keuze toevoegen" type="text" className="border mt-2 border-zinc-300 focus:outline-none focus:ring-1 focus:ring-blue-600 dark:focus:ring-blue-400 bg-transparent dark:border-zinc-700 w-full rounded-md px-3 py-2 mb-4 autofill:bg-none" />
							<button onClick={addChoice} className="absolute right-3 top-4 text-blue-700">
								<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
									<path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
								</svg>
							</button>
						</div>
						<button onClick={(e) => voegToe(e)} className="bg-blue-700 group py-2 px-6 text-white font-bold rounded-md hover:bg-blue-200 hover:text-blue-800 transition-colors mt-4">
							VOEG TOE
						</button>
					</div>
				</div>
			)}
		</>
	);
}
