import React from "react";
import toast from "react-hot-toast";
import Notification from "../../components/Notification";
import Loading from "../../components/Loading";
import { useState } from "react";
import { Winkelmand } from "./shop";
import { CursusverkoopItem } from "../../modules/types";
import Modal from "../../components/modal";
import { PakketInhoud } from "./pakketInhoud";
import { check_login } from "../../auth/auth";
import axios from "axios";
import config from "../../config";
interface Props {
    reedsLid: boolean;
    wordLid: boolean;
    winkelMand: Winkelmand;
    setWinkelMand: Function;
    closeModal: Function;
}

export default function Overzicht({ reedsLid, wordLid, winkelMand, setWinkelMand, closeModal }: Props) {
    const BezigMetBestellen = "BezigMetBestellen";
    const MoetNogBestellen = "MoetNogBestellen";
    const BestellingMislukt = "BestellingMislukt";
    const BestellingSuccesvol = "BestellingSuccesvol";

    const [bestellingStatus, setBestellingStatus] = useState(MoetNogBestellen);
    const [pakketModal, setPakketModal] = useState([]);

    const [claims, setClaims] = useState(check_login(false));

    function Bestel() {
        setBestellingStatus(BezigMetBestellen);
        // TODO: ALS ZE WILLEN LID WORDEN EEN API CALL NAAR REGISTRATIE
        toast.success((t) => <Notification toast={t}>Je bestelling is succesvol geplaatst</Notification>, {
            duration: 4000,
            position: "top-right"
        });

        if (!reedsLid && wordLid) {
            let claims: any = JSON.parse(check_login(false));

            let fd = new FormData();
            fd.append("user", claims.user_id);

            axios
                .post(config.api_url + "/registrationmodule/registrations/", fd)
                .then((response) => {})
                .catch((error) => {});
        }

        setTimeout(() => {
            setBestellingStatus(BestellingSuccesvol);
        }, 1000);
    }

    //console.log(winkelMand);

    function removeItem(removeItem: CursusverkoopItem) {
        let mand = { ...winkelMand };
        mand.items = winkelMand.items?.filter((item) => item.id !== removeItem.id);
        setWinkelMand(mand);
    }

    function getPrice() {
        let ledenprijs = 0;
        let prijs = 0;

        for (let item of winkelMand.items) {
            ledenprijs += item.member_price;
            prijs += item.price;
        }

        return [prijs, ledenprijs];
    }

    // TODO: CHECKEN ALS ER ITEMS DUBBEL IN WINKELMAND ZITTEN, EN DEZE AANDUIDEN.
    //? FUNCTIE SCHRIJVEN (REEDS IN WINKELMAND)
    return (
        <>
            <div className="my-8 w-11/12 mx-auto text-zinc-700 dark:text-zinc-300">
                <h2 className="text-2xl font-bold">Overzicht</h2>
                <div>
                    <table className="w-full">
                        <thead className="font-semibold">
                            <tr className="border-b-2 border-zinc-200 dark:border-zinc-700">
                                <td className="max-w-full py-3">Naam</td>
                                <td className="w-32 min-w-32 text-right py-3">Prijs</td>
                                <td className="w-16 min-w-16 text-right py-3"></td>
                            </tr>
                        </thead>

                        <tbody>
                            {!reedsLid && wordLid && (
                                <>
                                    <tr key={"Lidkaart"} className="border-b border-zinc-200 dark:border-zinc-700">
                                        <td className="py-3">Lidkaart</td>
                                        <td className={`text-right py-3`}>€ 5.00</td>
                                        <td></td>
                                    </tr>
                                </>
                            )}
                            {winkelMand.items.map((item) => (
                                <tr key={item.id + "+" + item.name} className="border-b border-zinc-200 dark:border-zinc-700">
                                    <td className="py-3">
                                        <div className="flex items-center gap-1">
                                            <div>
                                                {item.name} {item.optional && <span className="italic text-zinc-400 dark:text-zinc-500">(optioneel)</span>}
                                            </div>
                                            {item.kind === "pakket" && (
                                                <Modal
                                                    condition={pakketModal}
                                                    setCondition={setPakketModal}
                                                    uuid={item.id}
                                                    boxClasses="w-full md:w-7/12 xl:w-6/12 md:mx-auto mt-8 lg:mt-32 mb-16"
                                                    openButtonClasses="text-zinc-400"
                                                    openButtonValue={
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                                                        </svg>
                                                    }
                                                >
                                                    <PakketInhoud item={item} reedsLid={reedsLid} wordLid={wordLid} />
                                                </Modal>
                                            )}
                                        </div>
                                    </td>
                                    <td className={`text-right py-3`}>€ {reedsLid || wordLid ? item.member_price.toFixed(2) : item.price.toFixed(2)}</td>
                                    <td>
                                        <button onClick={() => removeItem(item)} className="flex items-center justify-center w-full transition-colors text-zinc-400 hover:text-zinc-800 dark:text-zinc-500 dark:hover:text-zinc-200">
                                            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                            </svg>
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className="mt-4">
                        <span className="font-bold">Totaal: </span>
                        <span>€ {reedsLid ? Math.abs(getPrice()[1]).toFixed(2) : wordLid ? Math.abs(getPrice()[1] + 5).toFixed(2) : Math.abs(getPrice()[0]).toFixed(2)}</span> <span className={`italic text-zinc-400 dark:text-zinc-500 ${!(wordLid || reedsLid) && "line-through"}`}>{getPrice()[0] - getPrice()[1] > 5 ? "(€ " + (getPrice()[0] - getPrice()[1] - 5).toFixed(2) + " leden korting)" : ""}</span>
                    </div>
                </div>
                {bestellingStatus === MoetNogBestellen && (
                    <div className="flex w-full items-center mt-8">
                        <div className="flex-grow">
                            <button onClick={() => Bestel()} className="border-2 border-blue-700 dark:border-blue-300 text-md px-5 py-2 text-blue-700 dark:text-blue-300 font-bold rounded-md hover:bg-blue-700 hover:text-white dark:hover:bg-blue-300 dark:hover:text-blue-900 transition-colors flex items-center gap-2">
                                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                                </svg>
                                BEVESTIG BESTELLING
                            </button>
                        </div>
                        <button onClick={() => closeModal()} className="transition-colors text-zinc-400 hover:text-zinc-800 dark:text-zinc-500 dark:hover:text-zinc-200">
                            Cancel
                        </button>
                    </div>
                )}
                {bestellingStatus === BezigMetBestellen && (
                    <div className="mt-8">
                        <Loading />
                    </div>
                )}
                {bestellingStatus === BestellingSuccesvol && (
                    <div className="mt-8 px-4 py-2 bg-emerald-300 dark:bg-emerald-700 text-emerald-900  dark:text-white rounded-md flex items-center gap-4 font-semibold">
                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                        </svg>
                        <span className="">Bestelling was succesvol!</span>
                    </div>
                )}
                {bestellingStatus === BestellingMislukt && (
                    <div className="mt-8 px-4 py-2 bg-red-300 dark:bg-red-700 text-red-900  dark:text-white rounded-md flex items-center gap-4 font-semibold">
                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        <span className="">Bestelling is mislukt, probeer opnieuw!</span>
                    </div>
                )}
            </div>
        </>
    );
}
