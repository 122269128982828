import React from "react";

function TimeFormat(props) {


	function formatTime() {
		let date = new Date(props.date);
		if (props.trySpecial) {
			if(date.getMinutes() === 0){
                return <span className={props.styles}>{date.getHours() + "u"}</span>;
            }
		}

		// $wd$ = weekday text short
		// $WD$ = Weekday text full
		// $d$ = DATE
		// $y$ = Year
		// $m$ = Month in numbers
		// $M$ = Month text but short
		// $MM$ = Month text full
		let format = props.format;
		format = format.replace("$H$", date.getHours());
		format = format.replace("$M$", date.getMinutes());
		return <span className={props.styles}>{format}</span>;
	}

	return (
		<>
			<div>{formatTime()}</div>
		</>
	);
}

export default TimeFormat;
