import React from "react";
import Navigation from "../components/navigation";
import "aos/dist/aos.css";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import Aos from "aos";
import "aos/dist/aos.css";
import { useParams } from "react-router-dom";
import { Sponsor as SponsorType } from "../modules/types";
function Sponsor() {
	useEffect(() => {
		Aos.init();
	});
	const [sponsor, setSponsor] = useState<SponsorType | null>(null);
	let id = useParams().id;
	useEffect(() => {
		axios
			.get("https://hrbackend.hermesgent.be/companies/" + id, {
				headers: {
					Authorization: "",
				},
			})
			.then((response) => {
				setSponsor(response.data);
			})
			.catch(() => {});
	}, [id]);

	return (
		<div className="dark:bg-white">
			<Navigation />
			<Helmet>
				<title>Sponsor - Hermes Gent</title>
				<meta name="description" content={"Hermes Gent - Sponsor - "} />
			</Helmet>
			<div className="lg:w-8/12 w-11/12 pt-20 pb-40 mx-auto">
				{!!sponsor ? (
					<div>
						<div className="w-full flex justify-center">
							<img alt={sponsor.company_name} className="w-full max-w-xs" src={sponsor?.company_logo} />
						</div>
						<div className="max-w-5xl mx-auto mt-12">
                            <a className="italic font-bold text-blue-600" href={"https://recruitment.hermesgent.be/student/bedrijven/" + sponsor?.company_name.replace(/\s+/g, "-").toLowerCase() + "/" + sponsor?.id}>Bekijk {sponsor.company_name} op Recruitment site</a>
							<div className="prose min-w-full mt-4" dangerouslySetInnerHTML={{ __html: sponsor?.company_long_desc }}></div>
						</div>
					</div>
				) : (
					<></>
				)}
			</div>
			
		</div>
	);
}

export default Sponsor;
