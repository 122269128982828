export const _ = {
    STRING_CONTAINS: (a: string, b: string) => {
        return a.toLowerCase().replaceAll(" ", "").includes(b.toLowerCase().replaceAll(" ", ""));
    },

    SHUFFEL_ARRAY: (array: Array<any>) => {
        return array.sort(() => 0.5 - Math.random());
    },
    CAPITALIZE_FIRST_LETTER: (word: string) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
    }
};
