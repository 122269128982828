import React from "react";
import {toast as toastPlugin} from "react-hot-toast"
type Props = {
	children: JSX.Element | string,
	toast: any,
}

export default function Notification({ children, toast}: Props) {
	return (
		<div className="flex">
			<div className="flex-grow">
				{children}
			</div>
			<button className="ml-2 text-zinc-400 transition-colors hover:text-black" onClick={() => {
				toast ? toastPlugin.dismiss(toast.id) : toastPlugin.dismiss();
			}
			}>
				<svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
					<path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
				</svg>
			</button>
		</div>
	);
}
