import React from "react";
import { QrReader } from "react-qr-reader";
import { qrError, qrResult } from "../../modules/types";

type Scanned = (result: qrResult, error: qrError) => void;
interface Props {
    scanned: Scanned;
}
export default function QrCodeScanner({ scanned }: Props) {
    return (
        <div>
            <QrReader constraints={{ facingMode: "environment" }} className="max-w-xl mx-auto w-full" onResult={(result, error) => scanned(result, error)} />
        </div>
    );
}
