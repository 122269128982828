import config from "../config";
export function check_login(redirect = false) {
    let claims = localStorage.getItem("claims");
    if (!claims && redirect) window.location.assign(config.base_url);
    return claims || "";
}

export function check_permission(permission, redirect = false) {
    if (check_login(redirect)) {
        let claims = JSON.parse(atob(localStorage.getItem("token").split(".")[1])); // ! prima
        let permissions = claims?.permissions;
        console.log(claims);
        if ((permissions === undefined || !permissions[permission]) && redirect) window.location.assign(config.base_url);
        else return permissions[permission];
    } else {
        return false;
    }
}

export function check_gedoopt(redirect = false) {
    if (check_login(redirect)) {
        let claims = JSON.parse(atob(localStorage.getItem("token").split(".")[1])); // ! prima
        let rol = claims?.rol;
        if ((rol === undefined || !(rol === "com" || rol === "sch")) && redirect) window.location.assign(config.base_url);
        else return rol === "com" || rol === "sch";
    } else {
        return false;
    }
}

export function check_ontgroend(redirect = false) {
    if (check_login(redirect)) {
        let claims = JSON.parse(atob(localStorage.getItem("token").split(".")[1])); // ! prima
        let rol = claims?.rol;
        if ((rol === undefined || !(rol === "com")) && redirect) window.location.assign(config.base_url);
        else return rol === "com";
    } else {
        return false;
    }
}

export default function Logout(redirect = true) {
    return new Promise(() => {
        if (localStorage.getItem("token") === null || localStorage.getItem("refresh") === null || localStorage.getItem("claims") === null) {
            throw new Error("loggedin");
        }

        new Promise((resolve) => {
            localStorage.removeItem("token");
            localStorage.removeItem("refresh");
            localStorage.removeItem("claims");
            resolve();
        })
            .then(() => {
                if (redirect) window.location.assign(config.base_url);
            })
            .catch(() => {
                if (redirect) window.location.assign(config.base_url);
            });
    }).catch(() => {
        if (redirect) window.location.assign(config.base_url);
    });
}
