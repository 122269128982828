import React, { useEffect, useState } from "react";
import Navigation from "../components/navigation";
import { Helmet } from "react-helmet";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link } from "react-router-dom";
import { Event } from "../modules/types";
import axios from "axios";
import config from "../config";
import Card from "../components/Card";
import DateFormat from "../components/dateFormater";

interface SportActiviteiten {
    loading: boolean;
    error: boolean;
    data: Event[];
}

export default function SkiPunten() {
    return (
        <div className="dark:bg-zinc-900">
            <Navigation />
            <Helmet>
                <title>Ski Punten - Hermes Gent</title>
                <meta name="description" content={"Hermes Gent - Sport"} />
            </Helmet>

            <div className="lg:w-8/12 w-11/12 pt-20 pb-40 mx-auto">
                <div data-aos="fade-up" data-aos-duration="800" className={"prose prose-zinc dark:prose-invert max-w-full prose-a:no-underline prose-a:text-blue-700 dark:prose-a:text-blue-300 prose-h2:text-3xl"}>
                    <h1>Ski Punten</h1>
                    <h2>Wedstrijd:</h2>
                    <p>Volwassenen omver skiën = 1 punt</p>
                    <p>Kind omver skiën = 5 punten</p>
                    <p>Zelf omver geskied worden = minpunten respectievelijk </p>
                    <p>Hond omver skiën = 20 punten</p>
                    <p>Rost persoon = score X2</p>
                    <p>Iemand in EHBO skiën = 25 punten</p>
                    <p>Iemand in helikopter skiën = 50 punten</p>
                    <p>Zelf in helikopter zitten = 1.000.000 punten</p>
                    <p>Wandelaars omver skiën = 30 punten</p>
                    <p>Moge de beste winnen!</p>
                    <p></p>
                </div>
            </div>
        </div>
    );
}
