import React from "react";

interface Props {
    date: string;
    trySpecial?: boolean;
    styles?: string;
    specialStyle?: string;
    format: string;
}

function DateFormat({ date, trySpecial, styles, specialStyle, format }: Props) {
    //<DateFormat date={} format={""} trySpecial={true} styles="" specialStyle="" weekdayStyle="" monthStyle="" dayStyle="" yearStyle="" dateDevider="-"/>
    const daysFull = ["zondag", "maandag", "dinsdag", "woensdag", "donderdag", "vrijdag", "zaterdag"];
    const daysShort = ["zo", "ma", "di", "woe", "do", "vr", "za"];

    const monthsFull = ["Januari", "Februari", "Maart", "April", "Mei", "Juni", "Juli", "Augustus", "September", "Oktober", "November", "December"];
    const monthsShort = ["Jan", "Feb", "Mrt", "Apr", "Mei", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dec"];

    const now = new Date();

    function formatDates() {
        let setDate = new Date(date);
        if (trySpecial) {
            if (setDate.getDate() === now.getDate() && setDate.getMonth() === now.getMonth() && setDate.getFullYear() === now.getFullYear()) {
                return <span className={styles + " " + specialStyle}>vandaag</span>;
            }
            if (setDate.getDate() === new Date(new Date().setDate(now.getDate() + 1)).getDate() && setDate.getMonth() === new Date(new Date().setDate(now.getDate() + 1)).getMonth() && setDate.getFullYear() === now.getFullYear()) {
                return <span className={styles + " " + specialStyle}>morgen</span>;
            }
            if (setDate.getDate() === new Date(new Date().setDate(now.getDate() + 2)).getDate() && setDate.getMonth() === new Date(new Date().setDate(now.getDate() + 2)).getMonth() && setDate.getFullYear() === now.getFullYear()) {
                return <span className={styles + " " + specialStyle}>overmorgen</span>;
            }
            if (setDate.getDate() === new Date(new Date().setDate(now.getDate() - 1)).getDate() && setDate.getMonth() === new Date(new Date().setDate(now.getDate() - 1)).getMonth() && setDate.getFullYear() === now.getFullYear()) {
                return <span className={styles + " " + specialStyle}>gisteren</span>;
            }
            if (setDate.getDate() === new Date(new Date().setDate(now.getDate() - 2)).getDate() && setDate.getMonth() === new Date(new Date().setDate(now.getDate() - 2)).getMonth() && setDate.getFullYear() === now.getFullYear()) {
                return <span className={styles + " " + specialStyle}>eergisteren</span>;
            }
        }

        // $wd$ = weekday text short
        // $WD$ = Weekday text full
        // $d$ = DATE
        // $y$ = Year
        // $m$ = Month in numbers
        // $M$ = Month text but short
        // $MM$ = Month text full

        format = format.replace("$wd$", daysShort[setDate.getDay()]);
        format = format.replace("$WD$", daysFull[setDate.getDay()]);

        format = format.replace("$d$", setDate.getDate() + "");
        format = format.replace("$y$", setDate.getFullYear() + "");

        format = format.replace("$m$", setDate.getMonth() + 1 + "");
        format = format.replace("$M$", monthsShort[setDate.getMonth()]);
        format = format.replace("$MM$", monthsFull[setDate.getMonth()]);

        return <span className={styles}>{format}</span>;
    }

    return <>{formatDates()}</>;
}

export default DateFormat;
