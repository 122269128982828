import PaneelNavigatie from "../../../components/Paneel/paneelNavigatie";
import Items from "../../../components/Paneel/Cursusverkoop/Items/items";
import Richtingen from "../../../components/Paneel/Cursusverkoop/Richtingen/richtingen";
import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
export default function Paneel_Cursusverkoop_Beheer() {
	const type = useParams().type;
	const [smallMenu, setSmallMenu] = useState(type ? type : "items");

	let navigate = useNavigate();
	return (
		<>
			<PaneelNavigatie />
			<Helmet>
                <title>Paneel - Shop Beheer - Hermes Gent</title>
            </Helmet>
			<div className="lg:w-8/12 w-11/12 pt-20 pb-40 mx-auto">
				{/* SMALL MENU */}
				<div className="flex flex-col md:flex-row items-center">
					<button className="mr-4 text-zinc-500 hover:text-zinc-900 transition-colors dark:text-zinc-300 dark:hover:text-zinc-600" onClick={() => navigate(-1)}>
						<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
							<path strokeLinecap="round" strokeLinejoin="round" d="M11 17l-5-5m0 0l5-5m-5 5h12" />
						</svg>
					</button>
					<h1 className="font-bold text-4xl uppercase text-zinc-800  dark:text-zinc-300">Cursusverkoop beheer</h1>

					<button onClick={() => setSmallMenu("items")} className={"flex gap-2 items-center md:border-r-2 md:border-zinc-200 h-14 px-6 transition-colors dark:border-zinc-800 " + (smallMenu === "items" ? "text-blue-700 dark:text-blue-300" : "text-zinc-500 hover:text-zinc-800 dark:text-zinc-400 dark:hover:text-zinc-300")}>
						<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
							<path strokeLinecap="round" strokeLinejoin="round" d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253" />
						</svg>
						Items
					</button>
					<button onClick={() => setSmallMenu("richtingen")} className={"flex gap-2 items-center md:border-r-2 md:border-zinc-200 h-14 px-6 transition-colors dark:border-zinc-800 " + (smallMenu === "richtingen" ? "text-blue-700 dark:text-blue-300" : "text-zinc-500 hover:text-zinc-800 dark:text-zinc-400 dark:hover:text-zinc-300")}>
						<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
							<path strokeLinecap="round" strokeLinejoin="round" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
						</svg>
						Richtingen
					</button>
					<button onClick={() => setSmallMenu("vakken")} className={"flex gap-2 items-center h-14 px-6 transition-colors  dark:border-zinc-800 " + (smallMenu === "vakken" ? "text-blue-700 dark:text-blue-300" : "text-zinc-500 hover:text-zinc-800 dark:text-zinc-400 dark:hover:text-zinc-300")}>
					<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M4.26 10.147a60.436 60.436 0 00-.491 6.347A48.627 48.627 0 0112 20.904a48.627 48.627 0 018.232-4.41 60.46 60.46 0 00-.491-6.347m-15.482 0a50.57 50.57 0 00-2.658-.813A59.905 59.905 0 0112 3.493a59.902 59.902 0 0110.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.697 50.697 0 0112 13.489a50.702 50.702 0 017.74-3.342M6.75 15a.75.75 0 100-1.5.75.75 0 000 1.5zm0 0v-3.675A55.378 55.378 0 0112 8.443m-7.007 11.55A5.981 5.981 0 006.75 15.75v-1.5" />
</svg>

						Vakken
					</button>

				</div>
				<div>
					<div className={smallMenu === "items" ? "block" : "hidden"}>
						<Items />
					</div>
					<div className={smallMenu === "richtingen" ? "block" : "hidden"}>
						<Richtingen />
					</div>
				</div>
			</div>
		</>
	);
}
