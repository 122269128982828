import Navigation from "../../components/navigation";

import { Helmet } from "react-helmet";
import config from "../../config";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { check_login } from "../../auth/auth";
import axios from "axios";
import toast from "react-hot-toast";
import Notification from "../../components/Notification";
import Loading from "../../components/Loading";
import { STYLE } from "../../modules/styles";
export default function Verkiezing() {
	//const [verkiezingModal, setVerkiezingModal] = useState([]);
	const [data, setData] = useState([]);
	const id = useParams()?.id;
	const verkiezing = useParams()?.naam;
	const [loading, setLoading] = useState();
	let navigate = useNavigate();

	useEffect(() => {
		if (!!check_login(false)) {
			load();
		}
	}, []);

	function load() {
		setLoading(true);
		axios
			.get(config.api_url + "/electionmodule/elections/?election_day=" + id + "&sort=-created")
			.then((response) => {
				setData(response.data.data);
				setLoading(false);
			})
			.catch(() => {
				setLoading(false);
				toast.error((t) => <Notification toast={t}>Kon verkiezingen niet inladen</Notification>, {
					duration: 4000,
					position: "top-right",
				});
			});
	}

	return (
		<>
			{!!check_login(true) ? (
				<div className="dark:bg-zinc-900 text-zinc-800  dark:text-zinc-300">
					<Navigation />
					<Helmet>
						<title>Stem Module - Hermes Gent</title>
						<meta name="description" content="Hermes Gent - Stem Module" />
					</Helmet>
					<div className="lg:w-8/12 w-11/12 pt-20 pb-40 mx-auto">
						<div className="flex items-center">
							<div className="flex-grow flex gap-4">
								<button className="text-zinc-500 hover:text-zinc-900 transition-colors dark:text-zinc-300 dark:hover:text-zinc-600" onClick={() => navigate(-1)}>
									<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
										<path strokeLinecap="round" strokeLinejoin="round" d="M11 17l-5-5m0 0l5-5m-5 5h12" />
									</svg>
								</button>
								<h1 className="font-bold text-2xl lg:text-4xl uppercase">{data[0]?.election_day?.name ? data[0]?.election_day?.name : verkiezing.replace("-", " ")}</h1>
							</div>
							<button onClick={() => load()} className="text-zinc-500 p-2 rounded-full flex items-center justify-center bg-zinc-200 dark:bg-black transition-colors hover:text-blue-500 ">
								<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
									<path strokeLinecap="round" strokeLinejoin="round" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
								</svg>
							</button>
						</div>
						{loading && (
							<div className="flex items-center justify-center mb-8">
								<Loading size={8} />
							</div>
						)}
						{data.length === 0 && !loading && <p>Er zijn momenteel geen verkiezingsitems</p>}
						<div className="grid gap-5 mt-8">
							{data.map((module) => {
								return (
									<>
										{module?.visible ? (
											<div key={module?.id} className="shadow-md border border-zinc-200 p-4 lg:p-8 rounded-lg dark:bg-black/40 dark:border-zinc-800 flex flex-col md:flex-row">
												<h1 className="font-bold text-xl md:text-2xl xl:text-3xl text-gray-700 dark:text-gray-300 flex-grow">{module.title}</h1>
												<div className="flex justify-end">
													<Link className={STYLE.DEFAULT_BUTTON + " w-fit"} to={"/stem-module/rondes/" + module.title.replace(/\s+/g, "-").toLowerCase() + "/" + module.id}>
														Open rondes
													</Link>
												</div>
											</div>
										) : (
											""
										)}
									</>
								);
							})}
						</div>
					</div>
				</div>
			) : (
				""
			)}
		</>
	);
}
