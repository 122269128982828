import { useState, useEffect, useRef } from "react";
import CustomSelect from "../../customSelect";
import config from "../../../config";
import axios from "axios";
import { Switch } from "@headlessui/react";
import { Editor } from "@tinymce/tinymce-react";
import { EVENT_TYPES } from "../../Choices";

import { check_login } from "../../../auth/auth";
import { check_permission } from "../../../auth/auth";
import { STYLE } from "../../../modules/styles";

import Notification from "../../Notification";
import toast from "react-hot-toast";
import Loading from "../../Loading";

function EventBewerken(props) {
    const [claims] = useState(check_login(true));
    const [allowed] = useState(check_permission("praesidium", true));

    const [loading, setLoading] = useState(true);

    const now = new Date();
    //const [notification, setNotification] = useState({ show: false, msg: "" });

    function dateConverter(date) {
        return date.getFullYear() + "-" + (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1) + "-" + (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + "T" + (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":" + (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes());
    }
    const nowString = dateConverter(now);

    const [types, setTypes] = useState([]);

    useEffect(() => {
        let data = [];
        for (const [key, value] of Object.entries(EVENT_TYPES)) {
            data.push({ id: key, value: value.name });
        }
        setTypes(data);
        axios
            .get(config.api_url + "/events/" + props.eventID)
            .then((response) => {
                let data = response.data.data;
                setTitle(data.title);
                setType({ id: data.type, value: EVENT_TYPES[data.type].name });
                setShort_desc(data.short_desc);
                setImage(data.image);
                setSpotlight(data.spotlight);
                setStart(dateConverter(new Date(data.start)));
                setEnd(dateConverter(new Date(data.end)));
                setLong_desc(data.long_desc);
                setLocation(data.location);
                setFb(data.facebook_url);
                //setLong(data.long);
                //setLat(data.lat);
                setApproved(data.approved);
                setLoading(false);
            })
            .catch(() => {
                error("Data kon niet worden opgehaald");
                props.reRender();
                props.close();
            });
    }, []);

    const [title, setTitle] = useState("");
    const [type, setType] = useState({ id: -1, value: "Kies een event type..." });
    const [short_desc, setShort_desc] = useState();
    const [start, setStart] = useState();
    const [end, setEnd] = useState();
    const [image, setImage] = useState();
    const [spotlight, setSpotlight] = useState(false);
    const [long_desc, setLong_desc] = useState();
    const [location, setLocation] = useState();
    const [fb, setFb] = useState();
    //const [long, setLong] = useState();
    //const [lat, setLat] = useState();
    const [approved, setApproved] = useState();
    const editorRef = useRef(null);

    function error(message) {
        toast.error((t) => <Notification>{message}</Notification>, {
            duration: 4000,
            position: "top-right"
        });
    }

    function bewerkEvent() {
        let fd = new FormData();
        if (!title) {
            error("Titel moet ingevuld zijn");
            return;
        }
        fd.append("title", title);
        if (type.id === -1) {
            error("Je moet een type selecteren");
            return;
        }
        fd.append("type", type.id);
        if (!location) {
            error("Locatie moet ingevuld zijn");
            return;
        }
        fd.append("location", location);
        if (fb) {
            let facebook = fb;
            if (!!facebook && !facebook.includes("http://") && !facebook.includes("https://")) facebook = "https://" + facebook;
            fd.append("facebook_url", facebook);
        } else {
            fd.append("facebook_url", "");
        }

        if (!start) {
            error("Start datum & tijd moet ingevuld zijn");
            return;
        }
        fd.append("start", start);

        if (!end) {
            error("Eind datum & tijd moet ingevuld zijn");
            return;
        }
        fd.append("end", end);

        if (!short_desc) {
            error("Korte beschrijving moet ingevuld zijn");
            return;
        }
        fd.append("short_desc", short_desc);
        if (!editorRef.current.getContent()) {
            error("Lange beschrijving moet ingevuld zijn");
            return;
        }
        fd.append("long_desc", editorRef.current.getContent());

        fd.append("spotlight", spotlight);
        //fd.append("long", long);
        //fd.append("lat", lat);
        fd.append("approved", approved);

        //console.log(editorRef.current.getContent())

        if (image instanceof File) {
            fd.append("image", image);
        }
        const promise = axios
            .patch(config.api_url + "/events/" + props.eventID, fd, {
                headers: {
                    "content-type": "multipart/form-data"
                }
            })
            .then(() => {
                props.reRender();
                props.close();
                return title;
            })
            .catch((error) => {
                throw Error("Bewerken van " + title + " mislukt: " + error.message);
            });

        toast.promise(
            promise,

            {
                loading: (t) => <Notification toast={t}>Bezig met bewerken...</Notification>,
                success: (data) => (t) =>
                    (
                        <Notification toast={t}>
                            Aanpassen van <b>{data}</b> succesvol!
                        </Notification>
                    ),
                error: (err) => (t) => <Notification toast={t}>{err.message}</Notification>
            },
            {
                duration: 4000,
                position: "top-right"
            }
        );
    }

    function verwijderEvent() {
        if (window.confirm("Bevestig het verwijderen van " + title + ".")) {
            axios
                .delete(config.api_url + "/events/" + props.eventID)
                .then(() => {
                    toast.success(
                        (t) => (
                            <Notification toast={t}>
                                <p>
                                    Verwijderen van <span className="font-bold">{title}</span> succesvol!
                                </p>
                            </Notification>
                        ),
                        {
                            duration: 4000,
                            position: "top-right"
                        }
                    );
                    props.reRender();
                    props.close();
                })
                .catch((error) => {
                    toast.error(
                        (t) => (
                            <Notification toast={t}>
                                <p>
                                    Verwijderen van <span className="font-bold">{title}</span> mislukt!
                                    <span className="italic text-zinc-400"> ({error.message})</span>
                                </p>
                            </Notification>
                        ),
                        {
                            duration: 4000,
                            position: "top-right"
                        }
                    );
                });
        } else {
        }
    }

    return (
        <>
            {!!claims && allowed && (
                <div className="my-8 mx-auto w-full lg:w-11/12 relative">
                    {loading && (
                        <div className="flex items-center justify-center">
                            <Loading size={8} />
                        </div>
                    )}
                    {!loading && (
                        <>
                            <h2 className="mb-4 font-bold text-zinc-600 text-2xl text-center">Event: {title} - bewerken</h2>

                            <div className="xl:flex xl:gap-8">
                                <div className="w-full">
                                    <label htmlFor="naam" className="text-lg font-light text-zinc-600 dark:text-zinc-400">
                                        Event titel
                                    </label>
                                    <input id="naam" value={title} onChange={(e) => setTitle(e.target.value)} type="text" className="border border-zinc-300 focus:outline-none focus:ring-1 focus:ring-blue-600 bg-transparent dark:border-zinc-700 w-full rounded-md px-3 py-2 dark:text-zinc-400 mb-4" placeholder="Vb: Shotjesclubavond" />

                                    <div className="mb-4">
                                        <label htmlFor="Event Type" className="text-lg font-light text-zinc-600 dark:text-zinc-400">
                                            Event type
                                        </label>
                                        <CustomSelect options={types} chosen={type} setChosen={setType} />
                                    </div>
                                    <label htmlFor="start" className="text-lg font-light text-zinc-600 dark:text-zinc-400 flex items-center">
                                        Event start datum & tijd
                                        <button onClick={() => setStart(nowString)} className="bg-blue-600 px-3 py-1 text-xs text-white ml-3 rounded-full font-bold">
                                            nu
                                        </button>
                                    </label>
                                    <input value={start} onChange={(e) => setStart(e.target.value)} type="datetime-local" id="start" className="border cursor-pointer h-11 border-zinc-300 focus:outline-none focus:ring-1 focus:ring-blue-600 bg-transparent dark:border-zinc-700 w-full rounded-md px-3 py-2 dark:text-zinc-400 mt-1 mb-4" />
                                </div>
                                <div className="w-full">
                                    <label htmlFor="fb" className="text-lg font-light text-zinc-600 dark:text-zinc-400">
                                        Facebook event link
                                    </label>
                                    <input id="fb" value={fb} onChange={(e) => setFb(e.target.value)} type="text" className="border border-zinc-300 focus:outline-none focus:ring-1 focus:ring-blue-600 bg-transparent dark:border-zinc-700 w-full rounded-md px-3 py-2 dark:text-zinc-400 mb-4" placeholder="Link naar facebook event" />
                                    <label htmlFor="locatie" className="text-lg font-light text-zinc-600 dark:text-zinc-400">
                                        Locatie
                                    </label>
                                    <input id="locatie" value={location} onChange={(e) => setLocation(e.target.value)} type="text" className="border border-zinc-300 focus:outline-none focus:ring-1 focus:ring-blue-600 bg-transparent dark:border-zinc-700 w-full rounded-md px-3 py-2 dark:text-zinc-400 mb-4" placeholder="Vb: Delirium" />

                                    <label htmlFor="end" className="text-lg font-light text-zinc-600 dark:text-zinc-400 flex items-center">
                                        Event eind datum & tijd
                                        <button onClick={() => setEnd(nowString)} className="bg-blue-600 px-3 py-1 text-xs text-white ml-3 rounded-full font-bold">
                                            nu
                                        </button>
                                        {!!start ? (
                                            <button onClick={() => setEnd(start)} className="bg-blue-600 px-3 py-1 text-xs text-white ml-1 rounded-full font-bold">
                                                zelfde
                                            </button>
                                        ) : (
                                            ""
                                        )}
                                    </label>
                                    <input value={end} onChange={(e) => setEnd(e.target.value)} type="datetime-local" id="end" className="border cursor-pointer h-11 border-zinc-300 focus:outline-none focus:ring-1 focus:ring-blue-600 bg-transparent dark:border-zinc-700 w-full rounded-md px-3 py-2 dark:text-zinc-400 mt-1 mb-4" />
                                </div>
                                {/*<label htmlFor="long" className="text-lg font-light text-zinc-600 dark:text-zinc-400">
									Long
								</label>
								<input id="long" value={long} onChange={(e) => setLong(e.target.value)} type="number" className="border border-zinc-300 focus:outline-none focus:ring-1 focus:ring-blue-600 bg-transparent dark:border-zinc-700 w-full rounded-md px-3 py-2 dark:text-zinc-400 mb-4" placeholder="Vb: 51.039618" />

								<label htmlFor="lat" className="text-lg font-light text-zinc-600 dark:text-zinc-400">
									Lat
								</label>
								<input id="lat" value={lat} onChange={(e) => setLat(e.target.value)} type="number" className="border border-zinc-300 focus:outline-none focus:ring-1 focus:ring-blue-600 bg-transparent dark:border-zinc-700 w-full rounded-md px-3 py-2 dark:text-zinc-400 mb-4" placeholder="Vb: 3.725755" />
								*/}
                            </div>

                            <label htmlFor="short_desc" className="text-lg font-light text-zinc-600 dark:text-zinc-400">
                                Korte beschrijving (max 180 characters)
                            </label>
                            <textarea rows="5" value={short_desc} onChange={(e) => setShort_desc(e.target.value)} maxLength="180" id="short_desc" className="border resize-none border-zinc-300 focus:outline-none focus:ring-1 focus:ring-blue-600 bg-transparent dark:border-zinc-700 w-full rounded-md px-3 py-2 dark:text-zinc-400 mt-1 mb-4" placeholder="korte beschrijving over het evenement"></textarea>

                            <label htmlFor="long_desc" className="text-lg font-light text-zinc-600 dark:text-zinc-400">
                                Lange beschrijving
                            </label>

                            <Editor
                                id="long_desc"
                                onInit={(evt, editor) => (editorRef.current = editor)}
                                initialValue={long_desc}
                                init={{
                                    height: 500,
                                    menubar: true,
                                    plugins: ["advlist autolink lists link image charmap print preview anchor", "searchreplace visualblocks code fullscreen", "insertdatetime media table paste code help wordcount"],
                                    toolbar: "undo redo | formatselect | " + "bold italic underline strikethrough | backcolor forecolor | alignleft aligncenter " + "alignright alignjustify | bullist numlist outdent indent | " + "removeformat | link image media | help",
                                    content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                                    toolbar_mode: "floating"
                                }}
                            />

                            <div className="mt-4">
                                <label htmlFor="image" className="text-lg font-light text-zinc-600 dark:text-zinc-500">
                                    Foto
                                </label>
                                <label className="flex flex-row w-52 justify-center items-center px-3 py-3 bg-white dark:bg-zinc-800 dark:text-zinc-300 rounded-lg tracking-wide transition-colors uppercase border border-zinc-200 dark:border-zinc-700 hover:ring-1 hover:ring-blue-700 cursor-pointer hover:text-blue-700 dark:hover:ring-blue-400 dark:hover:text-blue-400">
                                    <svg className="w-6 h-6" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                                    </svg>
                                    <span className="ml-2 text-base leading-normal">Foto uploaden</span>
                                    <input
                                        id="image"
                                        type="file"
                                        className="hidden"
                                        onChange={(event) => {
                                            setImage(event.target.files[0]);
                                        }}
                                    />
                                </label>
                            </div>

                            {image ? <img alt={title} src={image instanceof File ? URL.createObjectURL(image) : image} className="w-28 h-16 object-cover rounded-xl absolute bottom-0 right-0" /> : ""}

                            <div className="mt-6 flex items-center">
                                <label className="text-lg font-light text-zinc-600 dark:text-zinc-500 mr-2">In de kijker: </label>

                                <Switch checked={spotlight} onChange={setSpotlight} className={`${spotlight ? "bg-blue-600" : "bg-gray-200 dark:bg-zinc-700"} relative inline-flex items-center h-6 rounded-full w-11`}>
                                    <span className={`${spotlight ? "translate-x-6" : "translate-x-1"} transition-transform inline-block w-4 h-4 transform bg-white rounded-full`} />
                                </Switch>
                            </div>

                            <div className="mt-6 flex items-center">
                                <label className="text-lg font-light text-zinc-600 dark:text-zinc-500 mr-2">Aanvaard: </label>

                                <Switch checked={approved} onChange={setApproved} className={`${approved ? "bg-blue-600" : "bg-gray-200 dark:bg-zinc-700"} relative inline-flex items-center h-6 rounded-full w-11`}>
                                    <span className={`${approved ? "translate-x-6" : "translate-x-1"} transition-transform inline-block w-4 h-4 transform bg-white rounded-full`} />
                                </Switch>
                            </div>

                            <button onClick={() => bewerkEvent()} className={STYLE.DEFAULT_BUTTON + " mt-8"}>
                                Bewerken
                            </button>

                            <button onClick={() => verwijderEvent()} className="absolute -bottom-3 -right-3 text-red-400 p-2 rounded-full bg-white border">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                </svg>
                            </button>
                        </>
                    )}
                </div>
            )}
        </>
    );
}

export default EventBewerken;
