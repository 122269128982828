import Navigation from "../../components/navigation";

import { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import config from "../../config";
import axios from "axios";
import DateFormat from "../../components/dateFormater";
import TimeFormat from "../../components/timeFormater";
import { Helmet } from "react-helmet";
import { EVENT_TYPES } from "../../components/Choices";
import { eventContextData } from "../../context/eventContext";
import Loading from "../../components/Loading";
function Event() {
    let now = new Date();
    let id = useParams().id;
    const [event, setEvent] = useState([]);
    const [loading, setLoading] = useState(true);
    const eventContext = useContext(eventContextData);
    useEffect(() => {
        setEvent(getEvent());
        setLoading(eventContext.loading);
    }, [eventContext]);

    function getEvent() {
        return eventContext.data.find((event) => {
            return event.id === id;
        });
    }

    function Render() {
        axios
            .get(config.api_url + "/events/" + id)
            .then((response) => {
                setEvent(response.data.data);
                setLoading(false);
            })
            .catch(() => {});
    }

    useEffect(() => {});

    return (
        <div className="dark:bg-white">
            <Navigation />
            <Helmet>
                <title>Event - Hermes Gent</title>
                <meta name="description" content="Hermes Gent Cursusverkoop" />
            </Helmet>

            {!loading ? (
                <div className="lg:w-8/12 w-11/12 pt-5 lg:pt-20 pb-40 mx-auto grid grid-cols-1 gap-8 lg:grid-cols-12">
                    {event?.title ? (
                        <>
                            <Helmet>
                                <title>{event.title} - Hermes Gent</title>
                                <meta name="description" content="Hermes Gent Cursusverkoop" />
                            </Helmet>
                            <div className="div lg:col-span-4">
                                <img src={event.image} alt={event.title} className="rounded-xl w-full max-w-sm" />
                            </div>
                            <div className="div lg:col-span-8">
                                <div className="flex items-center">
                                    <h1 className="text-3xl font-bold lg:border-l-4 lg:border-blue-600 lg:pl-4 text-zinc-800">{event.title}</h1>
                                    {now > new Date(event.end) ? <span className="bg-red-400 text-white px-2 py-1 text-xs rounded-md ml-3 font-bold">Afgelopen</span> : ""}
                                    {new Date(event.start) < now && now < new Date(event.end) ? <span className="bg-blue-600 text-white px-2 py-1 text-xs rounded-md ml-3 font-bold">Bezig</span> : ""}
                                </div>
                                <div className="lg:text-left lg:border-l-4 lg:border-yellow-400 lg:pl-4 my-8 text-zinc-700 w-full max-w-3xl">
                                    <div className="prose min-w-full" dangerouslySetInnerHTML={{ __html: event.long_desc }}></div>
                                </div>
                                <hr className="mt-4 lg:hidden dark:border-zinc-700" />
                                <div className="lg:border-l-4 lg:border-blue-600 lg:pl-4 mt-8">
                                    <h2 className="text-xl font-bold text-zinc-800 ">Praktische info:</h2>
                                    <div className="grid grid-cols-4 lg:grid-cols-6 pt-2">
                                        <p className="text-zinc-800 mb-2">Start: </p>
                                        <div className="col-span-3 lg:col-span-5 flex text-zinc-600 mb-2 ">
                                            <DateFormat date={event.start} format={"$WD$ $d$ $MM$"} trySpecial={true} styles="capitalize" />
                                            <span className="px-1">om</span>
                                            <TimeFormat date={event.start} format={"$H$u$M$"} trySpecial={true} styles="" />
                                        </div>
                                        <p className="text-zinc-800  mb-2">Eind: </p>
                                        <div className="col-span-3 lg:col-span-5 flex text-zinc-600 mb-2">
                                            <DateFormat date={event.end} format={"$WD$ $d$ $MM$"} trySpecial={true} styles="capitalize" />
                                            <span className="px-1">om</span>
                                            <TimeFormat date={event.end} format={"$H$u$M$"} trySpecial={true} styles="" />
                                        </div>
                                        <p className="text-zinc-800  mb-2">Locatie: </p>
                                        <p className="col-span-3 lg:col-span-5 text-zinc-600  mb-2">{event.location ? event.location : "TBA"}</p>

                                        <p className="text-zinc-800 ">Type: </p>
                                        <p className="col-span-3 lg:col-span-5 text-zinc-600  capitalize">{EVENT_TYPES[event?.type].name}</p>

                                        {event.facebook_url && (
                                            <>
                                                <p className="text-zinc-800 mt-2">Facebook event: </p>
                                                <a target="_blank" href={event.facebook_url} className="col-span-3 lg:col-span-5 capitalize text-blue-600 mt-2">
                                                    klik hier
                                                </a>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="col-span-full text-center">
                                <h2 className="text-2xl font-bold text-zinc-700 ">Het event dat u probeerde te zoeken bestaat niet (meer).</h2>
                                <Link to={"/events"} className="text-xl text-blue-700 dark:text-blue-300">
                                    Andere eventementen bekijken
                                </Link>
                            </div>
                        </>
                    )}
                </div>
            ) : (
                <div className="pt-8 w-full flex justify-center">
                    <Loading />
                </div>
            )}
        </div>
    );
}

export default Event;
