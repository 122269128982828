import { QrReader } from "react-qr-reader";
import { Helmet } from "react-helmet";
import { useState } from "react";
import { useEffect } from "react";
import toast from "react-hot-toast";
import Notification from "../../Notification";
import { Switch } from "@headlessui/react";
import axios from "axios";
import config from "../../../config";
import { check_permission } from "../../../auth/auth";
import { check_login } from "../../../auth/auth";
function GeefAantalStemmen(props) {
	const [claims] = useState(check_login(true));
	const [allowed] = useState(check_permission("ict", true));
	const [data, setData] = useState(null);
	const [votes, setVotes] = useState(1);
	const [canVote, setCanVote] = useState(true);

	function scanned(result, error) {
		if (!!result) {
			setData(result?.text);
		}

		if (!!error) {
			//console.info(error);
		}
	}

	function giveVotes() {
		let formData = new FormData();
		formData.append("can_vote", canVote);
		formData.append("votes", votes);
		axios
			.patch(config.api_url + "/electionmodule/users/" + data, formData)
			.then(() => {
				toast.success(
					(t) => (
						<Notification toast={t}>
							<p>Succesvol stemmen gegeven</p>
						</Notification>
					),
					{
						duration: 4000,
						position: "top-right",
					}
				);
				setData(null)
			})
			.catch((error) => {
				setData(null)
				toast.error(
					(t) => (
						<Notification toast={t}>
							<p>
								Stemmen geven mislukt!
								<span className="italic text-zinc-400"> ({error.message})</span>
							</p>
						</Notification>
					),
					{
						duration: 4000,
						position: "top-right",
					}
				);
			});
	}

	return (
		<>
			{!!claims && allowed && (
				<div className="dark:bg-zinc-900">
					<Helmet>
						<title>Geef Stemrecht - Paneel - Hermes Gent</title>
						<meta name="description" content={"Hermes Gent - Geef stemrecht"} />
					</Helmet>

					{!!data ? (
						<div className="mt-8 mb-4 px-2 lg:px-8 text-zinc-600 dark:text-zinc-400">
							<h2 className="text-zinc-700 font-bold text-2xl dark:text-zinc-300">Stemmen geven</h2>
							<label htmlFor="stemmen">Aantal stemmen</label>
							<input onKeyDown={(e) => e.key === "Enter" && giveVotes()} onChange={(e) => setVotes(e.target.value)} id="stemmen" value={votes} max={2} min={1} name="stemmen" placeholder="Aantal stemmen" type="number" className="border mt-2 border-zinc-300 focus:outline-none focus:ring-1 focus:ring-blue-600 dark:focus:ring-blue-400 bg-transparent dark:border-zinc-700 w-full rounded-md px-3 py-2 mb-4 autofill:bg-none" />

							<div className="mt-2 mb-4 flex items-center">
								<label className="text-lg font-light text-zinc-600 dark:text-zinc-500 mr-2">Stemrecht: </label>

								<Switch checked={canVote} onChange={setCanVote} className={`${canVote ? "bg-blue-600" : "bg-gray-200 dark:bg-zinc-700"} relative inline-flex items-center h-6 rounded-full w-11`}>
									<span className={`${canVote ? "translate-x-6" : "translate-x-1"} transition-transform inline-block w-4 h-4 transform bg-white rounded-full`} />
								</Switch>
							</div>
							<button onClick={(e) => giveVotes(e)} className="bg-blue-700 group py-2 px-6 text-white font-bold rounded-md hover:bg-blue-200 hover:text-blue-800 transition-colors mt-4">
								GEEF STEMMEN
							</button>
						</div>
					) : (
						<div className="relative flex items-center justify-center flex-col mt-8">
							<h2 className="text-zinc-700 font-bold text-2xl dark:text-zinc-300">Scan QR code voor stemmen te geven</h2>
							<QrReader constraints={{ facingMode: "environment" }} className="max-w-xl mx-auto w-full" onResult={(result, error) => scanned(result, error)} style={{ width: "100%" }} />
							<div className="absolute w-64 h-64 border-4 border-blue-500 rounded-xl hidden"></div>
						</div>
					)}
				</div>
			)}
		</>
	);
}

export default GeefAantalStemmen;
