import { useEffect } from "react";
import { useLocation } from "react-router";

const ScrollToTop = (props) => {
	const location = useLocation();

	useEffect(() => {
		if (!!location.hash) {
            try{
                document.querySelector(location.hash).scrollIntoView({ behavior: "smooth" });
            }catch{
                setTimeout(() => {
                    document.querySelector(location.hash).scrollIntoView({ behavior: "smooth" });
                }, 1000)
            }
		} else {
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: 'smooth'
			  });
		}
	}, [location]);

	return <>{props.children}</>;
};

export default ScrollToTop;
