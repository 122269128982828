import axios from "axios";
import React, { createContext, useEffect, useState } from "react";
import config from "../config";
import { Event } from "../modules/types";

export const eventContextData = createContext<{
    loading: boolean;
    data: Event[];
    reload: () => void;
}>({
    loading: true,
    data: [],
    reload: () => {}
});

export default function EventContextProvider({ children }: { children: React.ReactNode }) {
    const [activiteiten, setActiviteiten] = useState<Event[]>([]);
    const [loading, setLoading] = useState(true);
    let now = new Date();
    useEffect(() => {
        Load();
    }, []);
    function Load() {
        axios.get(config.api_url + "/events/?approved=true&sort=start").then((response) => {
            let data: Event[] = response.data.data;
            let pushData: Event[] = [];
            for (let i = 0; i < data.length; i++) {
                let thisEvent = data[i];
                if (new Date(thisEvent.end) > now) {
                    pushData.push(thisEvent);
                }
            }
            setActiviteiten(pushData);
            setLoading(false);
        });
    }
    return <eventContextData.Provider value={{ loading: loading, data: activiteiten, reload: () => Load() }}>{children}</eventContextData.Provider>;
}
