import React from "react";
import { CursusverkoopItem } from "../../modules/types";

interface PakketProps {
    reedsLid: boolean;
    wordLid: boolean;
    item: CursusverkoopItem;
}

export const PakketInhoud = ({ item, reedsLid, wordLid }: PakketProps) => {
    return (
        <>
            <>
                <div className="my-8 w-11/12 mx-auto text-zinc-700 dark:text-zinc-300">
                    <h2 className="text-2xl font-bold">{item.name}</h2>
                    <p>{item.description}</p>
                    <div>
                        <table className="w-full">
                            <thead className="font-semibold">
                                <tr className="border-b-2 border-zinc-200 dark:border-zinc-700">
                                    <td className="max-w-full py-3">Naam</td>
                                    <td className="w-32 min-w-32 text-right py-3">Prijs</td>
                                    <td className="w-16 min-w-16 text-right py-3"></td>
                                </tr>
                            </thead>

                            <tbody>
                                {item.items.map((item) => (
                                    <tr key={item.id + "+" + item.name} className="border-b border-zinc-200 dark:border-zinc-700">
                                        <td className="py-3">
                                            {item.name} {item.optional && <span className="italic text-zinc-400 dark:text-zinc-500">(optioneel)</span>}
                                        </td>
                                        <td className={`text-right py-3`}>€ {reedsLid || wordLid ? item.member_price.toFixed(2) : item.price.toFixed(2)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </>
        </>
    );
};
