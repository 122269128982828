import { useState } from "react";
import Notification from "../../Notification";
import toast from "react-hot-toast";
import { check_login } from "../../../auth/auth";
import { check_permission } from "../../../auth/auth";
import axios from "axios";
import config from "../../../config";
import { Switch } from "@headlessui/react";
import { useEffect } from "react";

export default function RondeBewerken(props) {
	const [title, setTitle] = useState("");

	const [claims] = useState(check_login(true));
	const [allowed] = useState(check_permission("ict", true));
	const [open, setOpen] = useState(true);
	const [results, setResults] = useState(false);

	const [visible, setVisible] = useState(true);

	useEffect(() => {
		setTitle(props.ronde.title);
		setVisible(props.ronde.visible);
		setOpen(props.ronde.voting_open);
		setResults(props.ronde.results_open);
	}, []);

	function Bewerk() {
		let formData = new FormData();
		formData.append("title", title);
		formData.append("visible", visible);
		formData.append("voting_open", open);
		formData.append("results_open", results);

		axios
			.patch(config.api_url + "/electionmodule/rounds/" + props.ronde.id, formData)
			.then(() => {
				toast.success(
					(t) => (
						<Notification toast={t}>
							<p>
								Bewerken van ronde <span className="font-bold">{title}</span> succesvol!
							</p>
						</Notification>
					),
					{
						duration: 4000,
						position: "top-right",
					}
				);
				props.close();
				props.reload();
			})
			.catch((error) => {
				toast.error(
					(t) => (
						<Notification toast={t}>
							<p>
								Bewerken van <span className="font-bold">{title}</span> mislukt!
								<span className="italic text-zinc-400"> ({error.message})</span>
							</p>
						</Notification>
					),
					{
						duration: 4000,
						position: "top-right",
					}
				);
			});
	}

	return (
		<>
			{claims && allowed && (
				<div className="mt-12 px-2 lg:px-8 mb-6">
					<h1 className="font-bold text-2xl uppercase text-zinc-800  dark:text-zinc-300">RONDE BEWERKEN</h1>
					<div className="mt-4 text-zinc-800 dark:text-zinc-200">
						<label htmlFor="name">Naam van ronde</label>
						<input value={title} onKeyDown={(e) => e.key === "Enter" && Bewerk()} onChange={(e) => setTitle(e.target.value)} id="name" name="name" placeholder="Naam van ronde" type="text" className="border mt-2 border-zinc-300 focus:outline-none focus:ring-1 focus:ring-blue-600 dark:focus:ring-blue-400 bg-transparent dark:border-zinc-700 w-full rounded-md px-3 py-2 mb-4 autofill:bg-none" />

						<div className="mt-2 mb-4 flex flex-col lg:flex-row gap-4">
							<div className="flex items-center">
								<label className="text-lg font-light text-zinc-600 dark:text-zinc-500 mr-2">Visible: </label>

								<Switch checked={visible} onChange={setVisible} className={`${visible ? "bg-blue-600" : "bg-gray-200 dark:bg-zinc-700"} relative inline-flex items-center h-6 rounded-full w-11`}>
									<span className={`${visible ? "translate-x-6" : "translate-x-1"} transition-transform inline-block w-4 h-4 transform bg-white rounded-full`} />
								</Switch>
							</div>
							<div className="flex items-center">
								<label className="text-lg font-light text-zinc-600 dark:text-zinc-500 mr-2">Open: </label>

								<Switch checked={open} onChange={setOpen} className={`${open ? "bg-blue-600" : "bg-gray-200 dark:bg-zinc-700"} relative inline-flex items-center h-6 rounded-full w-11`}>
									<span className={`${open ? "translate-x-6" : "translate-x-1"} transition-transform inline-block w-4 h-4 transform bg-white rounded-full`} />
								</Switch>
							</div>
							<div className="flex items-center">
								<label className="text-lg font-light text-zinc-600 dark:text-zinc-500 mr-2">Resultaten open: </label>

								<Switch checked={results} onChange={setResults} className={`${results ? "bg-blue-600" : "bg-gray-200 dark:bg-zinc-700"} relative inline-flex items-center h-6 rounded-full w-11`}>
									<span className={`${results ? "translate-x-6" : "translate-x-1"} transition-transform inline-block w-4 h-4 transform bg-white rounded-full`} />
								</Switch>
							</div>
						</div>

						<button onClick={(e) => Bewerk(e)} className="bg-blue-700 group py-2 px-6 text-white font-bold rounded-md hover:bg-blue-200 hover:text-blue-800 transition-colors mt-4">
							BEWERK
						</button>
					</div>
				</div>
			)}
		</>
	);
}
