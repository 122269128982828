import { RESULT_STATE } from "../modules/enums";
import { useEffect, useState } from "react";

export function useFetch<T>(url: string) {
    const [state, setState] = useState(RESULT_STATE.LOADING);
    const [data, setData] = useState<T | null>(null);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(url);

                if (!response.ok) {
                    throw new Error("Network response was not ok.");
                }

                const jsonData: T = await response.json();
                setData(jsonData);
                setState(RESULT_STATE.SUCCESS);
            } catch (error) {
                if (error instanceof Error) setError(error.message || "An error occurred while fetching the data.");
                setState(RESULT_STATE.ERROR);
            }
        };

        fetchData();
    }, [url]);

    return { state, data, error };
}
