import PropTypes from "prop-types";
import { useState, useRef, useEffect, forwardRef } from "react";
import { Listbox, Transition } from "@headlessui/react";

function MultiSelect({disabled, options, label, selected, onChange, placeholder }) {
	const node = useRef();
	const [isOpen, setIsOpen] = useState(false);

	useEffect(() => {
		document.addEventListener("mousedown", handleClick);

		return () => {
			document.removeEventListener("mousedown", handleClick);
		};
	}, []);

	function isSelected(value) {
		return !!selected.find((el) => el.id === value.id);
	}

	function handleSelect(value) {
		if (!isSelected(value)) {
			const selectedUpdated = [...selected, options.find((el) => el === value)];
			onChange(selectedUpdated);
		} else {
			handleDeselect(value);
		}
		setIsOpen(true);
	}

	function handleDeselect(value) {
		const selectedUpdated = selected.filter((el) => el.id !== value.id);
		onChange(selectedUpdated);
		setIsOpen(true);
	}

	const handleClick = (e) => {
		if (node.current?.contains(e.target)) {
			return;
		}
		setIsOpen(false);
	};

	const Btn = forwardRef((props, ref) => {
		return (
			<button disabled={disabled} className={"relative disabled:cursor-not-allowed dark:disabled:bg-zinc-900 disabled:bg-zinc-100 flex items-center w-full border border-zinc-300 focus:outline-none focus:ring-1 focus:ring-blue-600 bg-transparent dark:border-zinc-700 px-3 py-2 dark:text-zinc-400 " + (isOpen ? "rounded-t-md" : "rounded-md")} onClick={() => setIsOpen(!isOpen)} ref={ref}>
				<span className="block truncate">{selected.length < 1 ? <span className="text-zinc-400 dark:text-gray-400">{placeholder}</span> : selected.map((item) => <div key={"lable-" + item.id + "-" + item.value} className="inline-block px-2 py-1 bg-zinc-200/80 text-zinc-900 dark:bg-zinc-700 dark:text-zinc-200 mr-2 rounded text-xs">{item.value}</div>)}</span>
				<span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none text-zinc-400 dark:text-zinc-500">
					<svg xmlns="http://www.w3.org/2000/svg" className={"h-5 w-5 ml-1 transition-transform " + (props.open ? "rotate-180" : "")} viewBox="0 0 20 20" fill="currentColor">
						<path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
					</svg>
				</span>
			</button>
		);
	});

	return (
		<div className="w-full mx-auto" ref={node}>
			<Listbox as="div" className="" value={selected} onChange={(value) => handleSelect(value)} open={isOpen}>
				{() => (
					<>
						<Listbox.Label className="block text-sm leading-5 font-medium text-grey-700">{label}</Listbox.Label>
						<div className="relative">
							<span className="inline-block w-full rounded-md shadow-sm">
								<Listbox.Button open={isOpen} as={Btn} />
							</span>

							<Transition unmount={false} show={isOpen} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0" className="z-10 absolute w-full rounded-md bg-white shadow-lg">
								<div className="w-full relative">
									<Listbox.Options static className="bg-white dark:bg-zinc-800 dark:text-zinc-300 shadow-xl border border-zinc-300 dark:border-zinc-700 rounded-b-xl absolute w-full max-h-60 overflow-y-scroll focus:outline-none z-20">
										{options.map((option, index) => {
											const selected = isSelected(option);
											return (
												<Listbox.Option key={option.id + ":" + option.value} value={option} className={"cursor-pointer transition-colors " + (index % 2 ? "bg-black/5 dark:bg-black/10" : "")}>
													{({ active }) => (
														<div className={`${active ? "text-white bg-blue-600" : "text-grey-900"} ${selected ? "bg-blue-100 text-zinc-800 font-bold" : ""} cursor-pointer select-none relative p-3 pl-7`}>
															<span className={`block truncate`}>{option.value}</span>
															{selected && (
																<span className={`${active ? "text-white" : "text-blue-600"} absolute inset-y-0 left-0 flex items-center pl-1.5`}>
																	<svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
																		<path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
																	</svg>
																</span>
															)}
														</div>
													)}
												</Listbox.Option>
											);
										})}
									</Listbox.Options>
								</div>
							</Transition>
						</div>
					</>
				)}
			</Listbox>
		</div>
	);
}

MultiSelect.propTypes = {
	options: PropTypes.array.isRequired,
	selected: PropTypes.array,
	onChange: PropTypes.func.isRequired,
	label: PropTypes.string,
};
export default MultiSelect;
