import Navigation from "../../components/navigation";

import { Helmet } from "react-helmet";
import config from "../../config";
import axios from "axios";
import { useEffect, useState } from "react";
import { check_login, check_ontgroend } from "../../auth/auth";
import { Link } from "react-router-dom";
import Loading from "../../components/Loading";
export default function Verkiezingen() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!!check_login(false) && check_ontgroend(true)) {
            axios
                .get(config.api_url + "/electionmodule/electiondays/?sort=-date&visible=true")
                .then((response) => {
                    setData(response.data.data);
                    setLoading(false);
                })
                .catch((err) => {
                    setLoading(false);
                });
        }
    }, []);
    return (
        <>
            {!!check_login(true) && (
                <div className="dark:bg-zinc-900">
                    <Navigation />
                    <Helmet>
                        <title>Stem Module - Hermes Gent</title>
                        <meta name="description" content="Hermes Gent - Stem Module" />
                    </Helmet>
                    <div className="lg:w-8/12 w-11/12 pt-20 pb-40 mx-auto">
                        <h1 className="font-bold text-4xl uppercase text-zinc-800  dark:text-zinc-300">Stem module</h1>
                        <div className="grid gap-5 mt-8">
                            {loading && <Loading />}
                            {data.map((module) => {
                                return (
                                    <Link key={module.id} to={"/stem-module/" + module.name.replace(/\s+/g, "-").toLowerCase() + "/" + module.id + "/"} className="shadow-md border border-zinc-200 p-8 rounded-lg dark:bg-black/40 dark:border-zinc-800 transition-transform hover:scale-110">
                                        <h1 className="font-bold text-xl md:text-2xl xl:text-3xl text-gray-700 dark:text-gray-300">{module.name}</h1>
                                    </Link>
                                );
                            })}
                            {data.length === 0 && <div className="text-zinc-400 dark:text-zinc-600">Geen verkiezingen gevonden</div>}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
