import axios from "axios";
import { useState, useEffect, useRef } from "react";
import config from "../../../config";
import { Editor } from "@tinymce/tinymce-react";

import { check_login } from "../../../auth/auth";
import { check_permission } from "../../../auth/auth";

import toast from "react-hot-toast";
import Notification from "../../Notification";
import Loading from "../../Loading";
import { STYLE } from "../../../modules/styles"

function PageBewerken(props) {
	const [claims] = useState(check_login(true));
	const [allowed] = useState(check_permission("praesidium", true));

	const [page, setPage] = useState(null);
	const editorRef = useRef(null);
	const [text, setText] = useState();

	useEffect(() => {
		axios.get(config.api_url + "/pages/" + props.id).then((result) => {
			//console.log(result.data.data);
			setPage(result.data.data);
			setText(result.data.data.page);
		});
	}, []);
	//console.log(props);
	function bewerkPage() {
		let fd = new FormData();
		fd.append("page", editorRef.current.getContent());
		const bewerkPromise = axios
			.patch(config.api_url + "/pages/" + props.id, fd)
			.then(() => {
				props.close();
				return page?.name;
			})
			.catch((error) => {

				throw Error(
					"Aanpassen van " + page?.name + " mislukt: " + error.message
				);
			});

		toast.promise(
			bewerkPromise,

			{
				loading: (t) => <Notification toast={t}>Bezig met aanpassen...</Notification>,
				success: (data) => (t) =>
					(
						<Notification toast={t}>
							Aanpassen van <b>{data}</b> succesvol!
						</Notification>
					),
				error: (err) => (t) => <Notification toast={t}>{err.message}</Notification>,
			},
			{
				duration: 4000,
				position: "top-right",
			}
		);
	}

	return (
		<>
			{!!claims && allowed && (
				<div className="mt-8">
					{!page && <Loading size={8}/>}
					<h1 className="text-3xl font-extrabold text-zinc-700 dark:text-zinc-300 mb-4">{page?.name}</h1>

					<Editor
						id="text"
						onInit={(evt, editor) => (editorRef.current = editor)}
						initialValue={text}
						init={{
							height: 800,
							menubar: true,
							plugins: ["advlist autolink lists link image charmap print preview anchor", "searchreplace visualblocks code fullscreen", "insertdatetime media table paste code help wordcount"],
							toolbar: "undo redo | formatselect | " + "bold italic underline strikethrough | backcolor forecolor | alignleft aligncenter " + "alignright alignjustify | bullist numlist outdent indent | " + "removeformat | link image media | help",
							content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
							toolbar_mode: "floating",
						}}
					/>
					<button onClick={() => bewerkPage()} className={STYLE.DEFAULT_BUTTON + " mt-8"}>
						Bewerken
					</button>
				</div>
			)}
		</>
	);
}
export default PageBewerken;
