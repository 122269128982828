import { useEffect, useState } from "react";
import Notification from "../../Notification";
import toast from "react-hot-toast";
import { check_login } from "../../../auth/auth";
import { check_permission } from "../../../auth/auth";
import axios from "axios";
import config from "../../../config";
import { Switch } from "@headlessui/react";
export default function VerkiezingsdagBewerken(props) {
    const [title, setTitle] = useState(props.verkiezingsdag.name);
    const [date, setDate] = useState(props.verkiezingsdag.date);
    const [visible, setVisible] = useState(props.verkiezingsdag.visible);
    const [claims] = useState(check_login(true));
    const [allowed] = useState(check_permission("ict", true));

    function Bewerk() {
        //console.log(title);
        let formData = new FormData();
        formData.append("name", title);
        formData.append("date", date);
        formData.append("visible", visible);
        axios
            .patch(config.api_url + "/electionmodule/electiondays/" + props.verkiezingsdag.id, formData)
            .then(() => {
                toast.success(
                    (t) => (
                        <Notification toast={t}>
                            <p>
                                Bewerken van verkiezingsdag <span className="font-bold">{title}</span> succesvol!
                            </p>
                        </Notification>
                    ),
                    {
                        duration: 4000,
                        position: "top-right"
                    }
                );
                props.close();
                props.reload();
            })
            .catch((error) => {
                toast.error(
                    (t) => (
                        <Notification toast={t}>
                            <p>
                                Bewerken van <span className="font-bold">{title}</span> mislukt!
                                <span className="italic text-zinc-400"> ({error.message})</span>
                            </p>
                        </Notification>
                    ),
                    {
                        duration: 4000,
                        position: "top-right"
                    }
                );
            });
    }
    return (
        <>
            {claims && allowed && (
                <div className="mt-12 px-2 lg:px-8 mb-6">
                    <h1 className="font-bold text-2xl uppercase text-zinc-800  dark:text-zinc-300">VERKIEZINGSDAG BEWERKEN</h1>
                    <div className="mt-4 text-zinc-800 dark:text-zinc-200">
                        <label htmlFor="name">Naam van verkiezingsdag</label>
                        <input
                            value={title}
                            onKeyDown={(e) => e.key === "Enter" && Bewerk()}
                            onChange={(e) => setTitle(e.target.value)}
                            id="name"
                            name="name"
                            placeholder="Naam van verkiezingsdag"
                            type="text"
                            className="border mt-2 border-zinc-300 focus:outline-none focus:ring-1 focus:ring-blue-600 dark:focus:ring-blue-400 bg-transparent dark:border-zinc-700 w-full rounded-md px-3 py-2 mb-4 autofill:bg-none"
                        />
                        <label htmlFor="date">Datum</label>
                        <input
                            value={date}
                            onKeyDown={(e) => e.key === "Enter" && Bewerk()}
                            onChange={(e) => setDate(e.target.value)}
                            id="date"
                            name="date"
                            placeholder="Datum van verkiezingsdag"
                            type="date"
                            className="border mt-2 border-zinc-300 focus:outline-none focus:ring-1 focus:ring-blue-600 dark:focus:ring-blue-400 bg-transparent dark:border-zinc-700 w-full rounded-md px-3 py-2 mb-4 autofill:bg-none"
                        />
                        <div className="flex items-center gap-4">
                            <label>Zichtbaar</label>
                            <Switch checked={visible} onChange={setVisible} className={`${visible ? "bg-blue-600" : "bg-gray-200 dark:bg-zinc-700"} relative inline-flex items-center h-6 rounded-full w-11`}>
                                <span className={`${visible ? "translate-x-6" : "translate-x-1"} transition-transform inline-block w-4 h-4 transform bg-white rounded-full`} />
                            </Switch>
                        </div>
                        <button onClick={(e) => Bewerk(e)} className="bg-blue-700 group py-2 px-6 text-white font-bold rounded-md hover:bg-blue-200 hover:text-blue-800 transition-colors mt-4">
                            BEWERK
                        </button>
                    </div>
                </div>
            )}
        </>
    );
}
