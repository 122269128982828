import axios from "axios";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import Loading from "../../../components/Loading";
import Notification from "../../../components/Notification";
import PaneelNavigatie from "../../../components/Paneel/paneelNavigatie";
import config from "../../../config";

const LID_STATUSSEN = {
    lid: "LID",
    wil_lid_worden: "WIL_LID_WORDEN",
    nvt: "NIET_VAN_TOEPASSING"
};

function Paneel_Bestellingen() {
    let id = useParams().id;
    const [lidStatus, setLidStatus] = useState(LID_STATUSSEN.nvt);
    const [registrationId, setRegistrationId] = useState(null);
    const [lidWordenLoading, setLidWordenLoading] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        axios
            .get(config.api_url + "/registrationmodule/registrations/?start_year=" + config.active_year + "&user=" + id)
            .then((response) => {
                if (response.data.data[0].member) {
                    setLidStatus(LID_STATUSSEN.lid);
                } else {
                    setLidStatus(LID_STATUSSEN.wil_lid_worden);
                }
                setRegistrationId(response.data.data[0].id);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    }, []);

    function patchLid(reg_id: string) {
        let fd = new FormData();
        fd.append("member", "true");

        axios
            .patch(config.api_url + "/registrationmodule/registrations/" + reg_id, fd)
            .then((response) => {
                setLidStatus(LID_STATUSSEN.lid);
                setLidWordenLoading(false);
                toast.success((t) => <Notification toast={t}>Lid maken succesvol!</Notification>, { position: "top-right", duration: 4000 });
            })
            .catch((error) => {
                setLidWordenLoading(false);
                toast.error((t) => <Notification toast={t}>Lid maken mislukt!</Notification>, { position: "top-right", duration: 4000 });
            });
    }

    function maakLid() {
        setLidWordenLoading(true);
        if (registrationId) {
            patchLid(registrationId);
        } else {
            let fd = new FormData();
            console.log("id: ", id);
            if (id) fd.append("user", id);
            else {
                toast.error((t) => <Notification toast={t}>Lid maken mislukt!</Notification>, { position: "top-right", duration: 4000 });
                return;
            }
            axios
                .post(config.api_url + "/registrationmodule/registrations/", fd)
                .then((response) => {
                    setRegistrationId(response.data.data.id);
                    patchLid(response.data.data.id);
                })
                .catch((error) => {
                    setLidWordenLoading(false);
                    toast.error((t) => <Notification toast={t}>Lid maken mislukt!</Notification>, { position: "top-right", duration: 4000 });
                });
        }
    }

    function lidmaatschapOpzeggen() {
        setLidWordenLoading(true);
        if (registrationId) {
            axios
                .delete(config.api_url + "/registrationmodule/registrations/" + registrationId)
                .then((response) => {
                    setLidStatus(LID_STATUSSEN.nvt);
                    setRegistrationId(null);
                    setLidWordenLoading(false);
                    toast.success((t) => <Notification toast={t}>Lidmaatschap succesvol opgezegd!</Notification>, { position: "top-right", duration: 4000 });
                })
                .catch((error) => {
                    setLidWordenLoading(false);
                    toast.error((t) => <Notification toast={t}>Oeps, er ging iets mis!</Notification>, { position: "top-right", duration: 4000 });
                });
        }
    }

    return (
        <>
            <PaneelNavigatie />
            <Helmet>
                <title>Paneel - Bestellingen - Hermes Gent</title>
            </Helmet>
            <div className="lg:w-8/12 w-11/12 pt-20 pb-40 mx-auto">
                {!loading && (
                    <>
                        <div className="flex items-center gap-3">
                            <div className="font-bold text-2xl uppercase text-zinc-800  dark:text-zinc-300 flex-grow">Bestellingen van Rein Vanbelleghem</div>
                            {(lidStatus === LID_STATUSSEN.wil_lid_worden || lidStatus === LID_STATUSSEN.nvt) && (
                                <button disabled={lidWordenLoading} onClick={() => maakLid()} className="px-3 py-2 text-sm font-bold rounded-md border-2 border-blue-600 text-blue-600 flex items-center justify-center">
                                    {lidWordenLoading ? <Loading size={5} /> : "MAAK LID"}
                                </button>
                            )}

                            {lidStatus === LID_STATUSSEN.lid && (
                                <button disabled={lidWordenLoading} onClick={() => lidmaatschapOpzeggen()} className="px-3 py-2 text-sm font-bold rounded-md border-2 border-red-600 text-red-600 flex items-center justify-center">
                                    {lidWordenLoading ? <Loading size={5} colors={"fill-red-600 dark:fill-red-400"} /> : "LIDMAATSCHAP OPZEGGEN"}
                                </button>
                            )}
                        </div>
                        <div>{lidStatus === LID_STATUSSEN.wil_lid_worden && <div className="text-blue-600">... wil lid worden van Hermes</div>}</div>
                        <div className="px-8 py-6 mt-4 bg-gradient-to-br from-blue-400 to-purple-400 text-white rounded-lg font-bold flex items-center gap-4">
                            <div className="flex-grow text-2xl">Totaal: €132,64</div>
                            <button className="px-3 py-2 bg-white/30 text-sm font-bold rounded-md transition-colors hover:bg-white/40">BETAALD</button>
                            <button className="px-3 py-2 bg-white/30 text-sm font-bold rounded-md transition-colors hover:bg-white/40">AFGEHAALD</button>
                        </div>

                        <div className="mt-8 bg-white dark:bg-black p-6 rounded-lg shadow-md border border-zinc-200 dark:border-zinc-700">
                            <table className="w-full text-zinc-800 dark:text-zinc-300">
                                <thead className="font-semibold">
                                    <tr className="border-b border-zinc-200 dark:border-zinc-700">
                                        <td className="w-12 py-3">
                                            <div className="flex items-center justify-center w-full">
                                                <input type="checkbox" className="w-4 h-4 accent-blue-600 dark:accent-blue-300" />
                                            </div>
                                        </td>
                                        <td className="py-3 w-32 min-w-32">Prijs lid</td>
                                        <td className="py-3 w-32 min-w-32">Prijs niet-lid</td>
                                        <td className="py-3">Naam</td>
                                        <td className="py-3 w-32 min-w-32">Status</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="border-b border-zinc-200 dark:border-zinc-700">
                                        <td className="py-3">
                                            <div className="flex items-center justify-center w-full">
                                                <input type="checkbox" className="w-4 h-4 accent-blue-600 dark:accent-blue-300" />
                                            </div>
                                        </td>
                                        <td className="py-3 w-32 min-w-32">€ 32,40</td>
                                        <td className="py-3 w-32 min-w-32">€ 40,30</td>
                                        <td className="py-3">Duurzame energie technieken</td>
                                        <td className="py-3 w-32 min-w-32">
                                            <div className="flex gap-2">
                                                <div className="text-zinc-400">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M14.25 7.756a4.5 4.5 0 100 8.488M7.5 10.5h5.25m-5.25 3h5.25M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                    </svg>
                                                </div>
                                                <div className="text-zinc-400">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M9 3.75H6.912a2.25 2.25 0 00-2.15 1.588L2.35 13.177a2.25 2.25 0 00-.1.661V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18v-4.162c0-.224-.034-.447-.1-.661L19.24 5.338a2.25 2.25 0 00-2.15-1.588H15M2.25 13.5h3.86a2.25 2.25 0 012.012 1.244l.256.512a2.25 2.25 0 002.013 1.244h3.218a2.25 2.25 0 002.013-1.244l.256-.512a2.25 2.25 0 012.013-1.244h3.859M12 3v8.25m0 0l-3-3m3 3l3-3" />
                                                    </svg>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </>
                )}

                {loading && (
                    <div className="w-full flex items-center justify-center">
                        <Loading size={8} />
                    </div>
                )}
            </div>
        </>
    );
}

export default Paneel_Bestellingen;
